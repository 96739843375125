///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { validateVATEu } from '../services/ValidationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../context/AuthContext';
import { cities, counties } from '../databases/RoCities';
import { createNotification } from '../services/NotificationService';

const Register = () => {
  const navigate = useNavigate();
  const { signup, currentUser, checkIfCompany } = useAuth()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [citySearch, setCitySearch] = useState('')
  const [countySearch, setCountySearch] = useState('')
  const [ip, setIP] = useState('');
  const [tert, setTert] = useState(false)
  const [triedComp, setTriedComp] = useState({comp: '', mail: ''})

  useEffect(() => {
    currentUser && navigate('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  useEffect(() => {
    const fetchGeo = async () => {
      const geoRes = await 
        fetch('https://geolocation-db.com/json/')
        .then(res => res.json())
        .then(message => { return message });
  
      if(geoRes !== null) {
        setIP(geoRes.IPv4)
        if(geoRes.city !== '') {
          const citiesUpper = cities.map(city => city !== '' && city.toUpperCase())
          const toInclude = !geoRes.city ? '' : geoRes.city.toUpperCase()
          citiesUpper.includes(toInclude) && setCitySearch(geoRes.city)
        }
      }
    }
    fetchGeo()
  }, [])

  const validateCif = async (cui, name) => {
    if(cui === '' || cui === null) {
      setErrors(prev => [...prev, 'Introduceți un CUI valid.'])
      return false
    }

    const country = cui.substring(0, 2).toUpperCase()
    const vatCode = cui.substring(2, cui.length)

    if(country.length < 2 || vatCode.length < 5) {
      setErrors(prev => [...prev, 'Introduceți un CUI valid.'])
      return false
    }


    if (validateVATEu(cui)) {
      return true;
    }
    else {
      setErrors(prev => [...prev, 'CUI invalid.'])
      return false;
    }
  }

  const checkPassword = (pass) => {
    //includes
    if(pass.length < 8) {
      setErrors(prev => [...prev, `Parola trebuie să aibă cel puțin 8 caractere.`])
      return false
    }
    // eslint-disable-next-line
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if(!specialChars.test(pass)) {
      setErrors(prev => [...prev, `Parola trebuie să conțină cel puțin un caracter special.`])
      return false
    }

    const hasNr = /\d/;
    if(!hasNr.test(pass)) {
      setErrors(prev => [...prev, `Parola trebuie să conțină cel puțin un număr.`])
      return false
    }

    return true;
  }

  const checkRegistrationValues = async (val) => {
    let hasErrors = false;
    const {
      password,
      passwordCheck,
      CUI,
      compName
    } = val

    for (const [key, value] of Object.entries(val)) {
      if(key.length > 2 && key !== 'promoCode') {
        if(value.value.length < 1) {
          setErrors(prev => [...prev, `Introduceți ${value.title}.`])
          hasErrors = true;
        }
      }
    }

    if(password.value !== passwordCheck.value) {
      setErrors(prev => [...prev, `Parolele nu se potrivesc.`])
      hasErrors = true;
    }

    if(!checkPassword(password.value)) return false;

    if(hasErrors) return false;

    return await validateCif(CUI.value, compName.value);
  }

  const checkCompWithDb = async (comp) => {
    const notific = (mail) => {
      createNotification('warning', 
      `Firma ${comp} este deja înregistrată. Adresa de email asociată firmei este ${hideEmail(mail)}. Dacă totuși firma vă aparține vă rugăm să ne contactați.`, 
      'Firma există deja', 12000)
    }

    if(triedComp.comp === comp) {
      notific(triedComp.mail)
      return []
    }
    await new Promise(r => setTimeout(r, 250));
    const res = await checkIfCompany(comp)
    if(res.length > 0) {
      notific(res[0].email)
      setTriedComp({comp:comp, mail: res[0].email })
    }
    return res
  }

  const hideEmail = (email) => {
    let hiddenEmail = "";
    for (let i = 0; i < email.length; i++) {
      if (i > 1 && i< email.indexOf("@") ) {
        hiddenEmail += "*";
      } else {
        hiddenEmail += email[i];
      }
    }
    return hiddenEmail
  }

  const validateRegistration = async (e) => {
    setLoading(true)
    setErrors([])
    e.preventDefault();

    const formValues = e.target.elements;

    const check = await checkRegistrationValues(formValues);

    if(check) {
      const {
        name,
        surename,
        phone,
        email,
        password,
        CUI,
        compName,
        oras,
        judet,
        address,
        regCom,
        // promocode
      } = formValues

      // const promoCode = promocode ? promocode.value : false
      const promoCode = false

      const isCompAlready = await checkCompWithDb(CUI.value)

      if(isCompAlready.length > 0) {
        setLoading(false)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return
      }

      await signup(
        `${name.value} ${surename.value}`,
        email.value,
        phone.value,
        password.value,
        CUI.value,
        compName.value,
        oras.value,
        judet.value,
        address.value,
        regCom.value,
        tert,
        promoCode,
        ip.toString()
      )
    }
    else window.scrollTo({ top: 0, behavior: 'smooth' });
    
    setLoading(false)
  }

  const searchCity = () => {
    if(citySearch === '') return cities.sort()
    else return cities.filter(city => city.toUpperCase().includes(citySearch.toUpperCase())).sort()
  }

  const searchCounty = () => {
    if(countySearch === '') return counties.sort()
    else return counties.filter(county => county.toUpperCase().includes(countySearch.toUpperCase())).sort()
  }

  return (
    <div className='login-wrapper no-bottom'>
        <div className='login-section'>
            <h1>Creare cont</h1>
            <div>
                <div className='errors'>
                  {errors.map((err, index) => {
                    return (
                      <div key={`${index}-${err}`}>{err}</div>
                    )
                  })}
                </div>
                <form className='login-form' onSubmit={(e) => validateRegistration(e)}>
                    <p>Nume</p>
                    <input className='input-text' type='text' name='name' id='name' title='Nume' />
                    <p>Prenume</p>
                    <input className='input-text' type='text' name='surename' id='surename' title='Prenume' />
                    <p>Telefon</p>
                    <input className='input-text' 
                    type='tel' pattern="[0-9]{10,12}" id='phone' title='Telefon' name='phone'/>
                    <p>Email</p>
                    <input className='input-text' type='email' name='email' title='Email' id='email' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
                    <p>Parolă</p>
                    <input className='input-text' name='password' id='password' title='Parolă' type='password'/>
                    <p>Repetă parola</p>
                    <input className='input-text' name='passwordCheck' title='Repetă parola' id='passwordCheck' type='password'/>
                    <p>CUI firmă</p>
                    <input className='input-text' placeholder='RO1234567890' title='CUI' name='CUI' id='CUI' type='text'/>
                    <p>Reg Com</p>
                    <input className='input-text' title='Reg Com' name='regCom' id='regCom' type='text'/>
                    <p>Nume firmă</p>
                    <input className='input-text' placeholder='Firma Mea' title='Nume firmă' name='compName' id='compName' type='text'/>

                    <p>Județ</p>
                    <input className='input-text dropdown-search' value={countySearch} title='Județ' 
                    name='judet' id='judet' type='text' onChange={e => setCountySearch(e.target.value)}/>
                    <div className='dropdown-wrapper'>
                      {searchCounty().map((county, index) => 
                        <p key={`${index}-${county}`} onClick={() => setCountySearch(county)}>{county}</p>
                      )}
                    </div>

                    <p>Oraș</p>
                    <input className='input-text dropdown-search' value={citySearch} title='Oraș' 
                    name='oras' id='oras' type='text' onChange={e => setCitySearch(e.target.value)}/>
                    <div className='dropdown-wrapper'>
                      {searchCity().map((city, index) => 
                        <p key={`${index}-${city}`} onClick={() => setCitySearch(city)}>{city}</p>
                      )}
                    </div>

                    <p>Adresă</p>
                    <input className='input-text' title='Adresă' name='address' id='address' type='text'/>

                    {/* <p>Cod promoțional</p>
                    <input className='input-text' title='Cod promoțional' name='promoCode' id='promoCode' type='text'/> */}

                    <div className='checkbox-wrapper'>
                      <input className='checkbox' value={tert} onChange={e => setTert(e.target.checked)}
                      type="checkbox" id="tert" name="tert" title='Declarant terț'/>
                      <label htmlFor="tert">Declarant terț
                      </label>
                    </div>

                    <div className='checkbox-wrapper'>
                      <input className='checkbox'
                      type="checkbox" id="agreePol" name="agreePol" title='De acord cu politica de confidențialitate' required/>
                      <label htmlFor="agreePol">Sunt de acord cu 
                        <Link to={'/politica-de-confidentialitate'} className='link'>Politica de confidențialitate.</Link>
                      </label>
                    </div>
                    <div className='checkbox-wrapper'>
                      <input className='checkbox'
                      type="checkbox" id="agreeTerm" name="agreeTerm" title='De acord cu termenii și condițiile' required/>
                      <label htmlFor="agreeTerm">Sunt de acord cu 
                        <Link to={'/termeni-si-conditii'} className='link'>Termenii și condițiile.</Link>
                      </label>
                    </div>
                    {loading ? <FontAwesomeIcon className='spinner' icon={faSpinner}/> : 
                    <input className='input-submit' type="submit" value='Creare cont' />}
                    <div>Ai deja cont?
                        <b><Link to={'/sign-in'} className='link'>Autentificare</Link></b>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Register