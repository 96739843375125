///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../context/AuthContext';
import { createNotification } from '../services/NotificationService';

const SignIn = () => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [inputEmail, setInputEmail] = useState('')

    const { login, resetPassword, currentUser } = useAuth()

    useEffect(() => {
      currentUser && navigate('/')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser])
    

    const validateSubmit = async (e) => {
        setLoading(true)
        setErrors([])
        e.preventDefault();
        let hasErrors = false;

        const {
            email,
            password
        } = e.target.elements

        for (const [key, value] of Object.entries(e.target.elements)) {
            if(key.length > 2) {
                if(value.value.length < 1) {
                    setErrors(prev => [...prev, `Introduceți ${value.title}.`])
                    hasErrors = true;
                }
            }
        }

        if(password.value.length < 8) {
            hasErrors = true
            setErrors(prev => [...prev, `Introduceți o parolă validă.`])
        }

        if(hasErrors){ 
            setLoading(false)
            return false;
        }

        await login(email.value, password.value)
        setLoading(false)
        return true;
    }

    const validateEmail = (mail) => {
        return mail.toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }

    const forgotPass = async () => {
        if(inputEmail === '' || !validateEmail(inputEmail)) {
            createNotification('warning', 'Introduceți o adresă de email validă')
            return false;
        }
        setLoading(true)
        await resetPassword(inputEmail)
        setLoading(false)
    }

  return (
    <div className='login-wrapper no-bottom'>
        <div className='login-section'>
            <h1>Intră în cont</h1>
            <div>
                <div className='errors'>
                  {errors.map((err, index) => {
                    return (
                      <div key={`${index}-${err}`}>{err}</div>
                    )
                  })}
                </div>
                <form className='login-form' onSubmit={(e) => validateSubmit(e)}>
                    <p>Email</p>
                    <input className='input-text' value={inputEmail} 
                    onChange={e => setInputEmail(e.target.value)} type='email' 
                    title='Email' id='email' name='email' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
                    <p>Parolă</p>
                    <input className='input-text' title='Parolă' type='password' id='password' name='password'/>
                    {loading ? <FontAwesomeIcon className='spinner' icon={faSpinner}/> : 
                    <>
                    <small className='link' onClick={() => forgotPass()}>Ai uitat parola?</small>
                    <input className='input-submit' type="submit" value='Autentificare' />
                    </>}
                    <div>Nu ai cont?
                        <b><Link to={'/register'} className='link'>Creare cont</Link></b>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default SignIn