///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React,{ useState, useEffect, useRef } from 'react'
import Popup from '../../Popup';
import { useAuth } from '../../../context/AuthContext';
import { formatNr } from '../../../services/FormatService';
import { InvoiceToPrint } from '../../../services/PdfService';
import { createNotification } from '../../../services/NotificationService';
import { getDateFromTimestamp, toFormatDate, toFormatTime } from '../../../services/DateService';
import { getAllSubscriptionNames, 
    getCifCheck, 
    getCompanyLimit, 
    getLayoutsLimit, 
    getValStatCalc, 
    subscriptionLines, 
    subscriptionPrices, 
    subscriptionTime } from '../../../services/SubscriptionsService';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faEye, faInfo, faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useReactToPrint } from 'react-to-print';

const AdminPanel = () => {
    const [openPopup, setOpenPopup] = useState(false)
    const [popupData, setPopupData] = useState({})

    const {
        currentUser,
        userData,
        createNewSubscription,
        toggleLayoutState,
        toggleInvoiceState,
        displayInvoices,
        fetchInvoicesByDates,
        loadinvoiceWithSeriesNr,
        loadingAction,
        cancelLastSubscription,
        fetchUnrepliedContacts,
        contactEmails,
        replyToContactMessage,
        loadUsersAPI,
        userAccounts,
        adminImpersonate,
        // addNewAdmin
    } = useAuth()

    const { data:uData } = userData

    const getPriceWithTax = (val) => {
        return ((19/100) * (+val) + (+val)).toFixed(2)
    }

    // popup JSX contents
    const [subAddState, setSubAddState] = useState({
        id: '',
        type: 0,
        duration: 12,
        cifCheck: true,
        compAvailable: 1,
        layoutsAvailable: 1,
        valStatCalc: true,
        valid: false,
        price: 0,
        lines: 100,
        sendInvoice: true,
        sendEmail: true,
        paid: false
    })

    const handleNewSubSubmit = async (e) => {
        createNotification('info', 'Acțiunea se încarcă, așteptați.')
        e.preventDefault();
        const today = new Date();
        const withInvoice = true;
        const sendEmail = subAddState.sendEmail;
        const isPaid = subAddState.paid;
        const subData = {
            uid: subAddState.id,
            name: subAddState.type,
            date: today.toString(),
            timeStamp: today,
            duration: parseInt(subAddState.duration),
            lines: new Array(parseInt(subAddState.duration)).fill(subAddState.lines),
            linesStart: subAddState.lines,
            cifCheck: subAddState.cifCheck,
            compAvailable: subAddState.compAvailable,
            valStatCalc: subAddState.valStatCalc,
            layoutsAvailable: subAddState.layoutsAvailable,
            manualDisable: false,
            valid: subAddState.valid,
            price: subAddState.price
        }
        await createNewSubscription(subData, subAddState.id, withInvoice, sendEmail, isPaid)
        setOpenPopup(false)
    }
    const subAdd = (
        <div className='login-wrapper popup'>
            <form className='login-form popup' onSubmit={(e) => handleNewSubSubmit(e)}>
                <p>ID utilizator</p>
                <input 
                value={subAddState.id} 
                onChange={(e) => setSubAddState(prev => ({...prev, id: e.target.value}))}
                className='input-text' type='text' id='id' required/>

                <p>Tip</p>
                <select 
                value={subAddState.type} 
                onChange={(e) => setSubAddState(prev => ({...prev, 
                    type: +e.target.value,
                    duration: subscriptionTime(e.target.value),
                    cifCheck: getCifCheck(e.target.value),
                    compAvailable: getCompanyLimit(e.target.value),
                    layoutsAvailable: getLayoutsLimit(e.target.value),
                    valStatCalc: getValStatCalc(e.target.value),
                    valid: false,
                    price: subscriptionPrices(e.target.value),
                    lines: subscriptionLines(e.target.value)
                }))}
                className='input-text' id='type' 
                required>
                    {getAllSubscriptionNames().map((name, i) => {
                        return(
                        <option key={`${i} - ${name}`} value={i}>{name}</option>
                        )
                    })}
                </select>

                <p>Durată luni</p>
                {
                subAddState.type >= getAllSubscriptionNames().length - 1 ?
                <input 
                value={subAddState.duration}
                onChange={(e) => setSubAddState(prev => ({...prev, duration: e.target.value}))}
                className='input-text' type='number' id='duration' min="1" required/>
                :
                <div>{subscriptionTime(subAddState.type)}</div>
                }

                <p>Validare VAT</p>
                {
                subAddState.type >= getAllSubscriptionNames().length - 1 ?
                <select 
                value={subAddState.cifCheck} 
                onChange={(e) => setSubAddState(prev => ({...prev, cifCheck: e.target.value}))}
                className='input-text' id='cifCheck' 
                required>
                    <option value={true}>DA</option>
                    <option value={false}>NU</option>
                </select>
                :
                <div>{getCifCheck(subAddState.type) ? 'DA' : 'NU'}</div>
                }

                <p>Firme</p>
                {
                subAddState.type >= getAllSubscriptionNames().length - 1 ?
                <input 
                value={subAddState.compAvailable}
                onChange={(e) => setSubAddState(prev => ({...prev, compAvailable: e.target.value}))}
                className='input-text' type='number' id='compAvailable' min="1" required/>
                :
                <div>{getCompanyLimit(subAddState.type)}</div>
                }

                <p>Machete</p>
                {
                subAddState.type >= getAllSubscriptionNames().length - 1 ?
                <input 
                value={subAddState.layoutsAvailable}
                onChange={(e) => setSubAddState(prev => ({...prev, layoutsAvailable: e.target.value}))}
                className='input-text' type='number' id='layoutsAvailable' min="1" required/>
                :
                <div>{getCompanyLimit(subAddState.type)}</div>
                }

                <p>Calcul Valoare Statistică</p>
                {
                subAddState.type >= getAllSubscriptionNames().length - 1 ?
                <select 
                value={subAddState.valStatCalc} 
                onChange={(e) => setSubAddState(prev => ({...prev, valStatCalc: e.target.value}))}
                className='input-text' id='valStatCalc' 
                required>
                    <option value={true}>DA</option>
                    <option value={false}>NU</option>
                </select>
                :
                <div>{getValStatCalc(subAddState.type) ? 'DA' : 'NU'}</div>
                }

                <p>Linii /lună</p>
                {
                subAddState.type >= getAllSubscriptionNames().length - 1 ?
                <input 
                value={subAddState.lines}
                onChange={(e) => setSubAddState(prev => ({...prev, lines: e.target.value}))}
                className='input-text' type='number' id='lines' min="1" required/>
                :
                <div>{subscriptionLines(subAddState.type)}</div>
                }

                <p>Valid</p>
                <select 
                value={subAddState.valid} 
                onChange={(e) => setSubAddState(prev => ({...prev, valid: e.target.value}))}
                className='input-text' id='valid' 
                required>
                    <option value={true}>DA</option>
                    <option value={false}>NU</option>
                </select>

                <p>Preț /lună cu TVA (€)</p>
                {
                subAddState.type >= getAllSubscriptionNames().length - 1 ?
                <>
                <input 
                value={subAddState.price}
                onChange={(e) => setSubAddState(prev => ({...prev, price: e.target.value}))}
                className='input-text' type='number' id='price' min="0" step='0.01' required/>
                <div>Total: {getPriceWithTax(parseFloat(subAddState.price)*subAddState.duration)}
                €</div>
                </>
                :
                <>
                <div>{getPriceWithTax(subscriptionPrices(subAddState.type))}€</div>
                <div>Total: { (+getPriceWithTax(subscriptionPrices(subAddState.type)) * subAddState.duration).toFixed(2) } €</div>
                </>
                }
                <div className='checkbox-wrapper' style={{marginTop:'2em'}}>
                    <input className='checkbox' value={subAddState.sendInvoice} defaultChecked={subAddState.sendInvoice}
                    onChange={(e) => setSubAddState(prev => ({...prev, sendInvoice: e.target.checked}))}
                    type="checkbox" id="sendInvoice" name="sendInvoice" title='factura'/>
                    <label htmlFor="sendInvoice">Generează factură
                    </label>
                </div>
                {
                subAddState.sendInvoice &&
                <>
                <div className='checkbox-wrapper' style={{marginTop:'2em'}}>
                    <input className='checkbox' value={subAddState.sendEmail} defaultChecked={subAddState.sendEmail}
                    onChange={(e) => setSubAddState(prev => ({...prev, sendEmail: e.target.checked}))}
                    type="checkbox" id="sendMail" name="sendMail" title='mail'/>
                    <label htmlFor="sendMail">Trimite email utilizatorului
                    </label>
                </div>
                <div className='checkbox-wrapper' style={{marginTop:'2em'}}>
                    <input className='checkbox' value={subAddState.paid} defaultChecked={subAddState.paid}
                    onChange={(e) => setSubAddState(prev => ({...prev, paid: e.target.checked}))}
                    type="checkbox" id="isPaid" name="isPaid" title='platita'/>
                    <label htmlFor="isPaid">Factura este plătită
                    </label>
                </div>
                </>
                }
                <input style={{marginTop:'4em'}} className='input-submit' type="submit" value='Adaugă' />
            </form>
        </div>
    )

    const [subStareState, setSubStareState] = useState({
        id: '',
        type: 1,
        updateInvoice: true,
        sendMail: true
    })

    const handleSubStareAction = async (mode, value) => {
        if (subStareState.id === '') {
            createNotification('error', 'ID invalid')
            return
        }
        createNotification('info', 'Acțiunea se încarcă, așteptați.')
        await toggleLayoutState(subStareState, mode, value)
        setOpenPopup(false)
    }
    const subStare = (
        <div className='login-wrapper popup'>
            <div className='login-form popup'>
                <p>Căutare după</p>
                <select 
                value={subStareState.type} 
                onChange={(e) => setSubStareState(prev => ({...prev, type: e.target.value}))}
                className='input-text' id='type' 
                required>
                    <option value={1}>ID utilizator</option>
                    <option value={0}>ID abonament</option>
                </select>

                {
                parseInt(subStareState.type) === 0 ?
                <>
                    <p>ID abonament</p>
                    <input 
                    value={subStareState.id} 
                    onChange={(e) => setSubStareState(prev => ({...prev, id: e.target.value}))}
                    className='input-text' type='text' id='id' required/>
                </>
                    :
                <>
                    <p>ID utilizator (se va selecta ultimul abonament creat)</p>
                    <input 
                    value={subStareState.id} 
                    onChange={(e) => setSubStareState(prev => ({...prev, id: e.target.value}))}
                    className='input-text' type='text' id='id' required/>
                </>
                }
                <div className='checkbox-wrapper' style={{marginTop:'2em'}}>
                    <input className='checkbox' value={subStareState.updateInvoice} defaultChecked={subStareState.updateInvoice}
                    onChange={(e) => setSubStareState(prev => ({...prev, updateInvoice: e.target.checked}))}
                    type="checkbox" id="updateInvoice" name="updateInvoice" title='factura'/>
                    <label htmlFor="updateInvoice">Setează factura ca plătită (VALIDARE) sau anulată (DEZACTIVARE)
                    </label>
                </div>
                <div className='checkbox-wrapper' style={{marginBlock:'2em'}}>
                    <input className='checkbox' value={subStareState.sendMail} defaultChecked={subStareState.sendMail}
                    onChange={(e) => setSubStareState(prev => ({...prev, sendMail: e.target.checked}))}
                    type="checkbox" id="sendMail" name="sendMail" title='mail'/>
                    <label htmlFor="sendMail">Trimite email utilizatorului
                    </label>
                </div>
                <div className='admin-action-section'>
                    <div className='button-secondary selected-btn' 
                        onClick={() => handleSubStareAction(0, true)}>Validează</div>
                    <div className='button-secondary'
                        onClick={() => handleSubStareAction(0, false)}>Invalidează</div>
                    {
                    parseInt(uData.accessLevel) > 2 &&
                    <>
                    <div className='button-secondary selected-btn'
                        onClick={() => handleSubStareAction(1, false)}>Activează</div>
                    <div className='button-secondary'
                        onClick={() => handleSubStareAction(1, true)}>Dezactivează</div>
                    </>
                    }
                </div>
            </div>
        </div>
    )

    // Cancel subscription only if not paid
    const [cancelSubState, setCancelSubState] = useState({
        id: '',
        sendMail: true
    })

    const handleCancelSubAction = async () => {
        if (cancelSubState.id === '') {
            createNotification('error', 'ID invalid')
            return
        }
        createNotification('info', 'Acțiunea se încarcă, așteptați.')
        await cancelLastSubscription(cancelSubState)
        setOpenPopup(false)
    }
    const cancelSub = (
        <div className='login-wrapper popup'>
            <div className='login-form popup'>
                <p>Căutare după ID utilizator</p>
                <input 
                value={cancelSubState.id} 
                onChange={(e) => setCancelSubState(prev => ({...prev, id: e.target.value}))}
                className='input-text' type='text' id='id' required/>

                <div className='checkbox-wrapper' style={{marginBlock:'2em'}}>
                    <input className='checkbox' value={cancelSubState.sendMail} defaultChecked={cancelSubState.sendMail}
                    onChange={(e) => setCancelSubState(prev => ({...prev, sendMail: e.target.checked}))}
                    type="checkbox" id="sendMail" name="sendMail" title='mail'/>
                    <label htmlFor="sendMail">Trimite email utilizatorului
                    </label>
                </div>
                <p>Ultimul abonament poate fi anulat doar dacă nu a fost marcat ca și plătit.</p>
                <p>Dacă există un abonament anterior încă valid, acesta va fi reactivat.</p>
                <div className='admin-action-section'>
                    <div className='button-secondary danger' 
                        onClick={() => handleCancelSubAction()}>ANULEAZĂ</div>
                </div>
            </div>
        </div>
    )

    // State of invoices
    const [subInvStareState, setSubInvStareState] = useState({
        id: '',
        sendMail: true,
        type: 1
    })

    const handleinvStareAction = async (action) => {
        if (subInvStareState.id === '') {
            createNotification('error', 'ID invalid')
            return
        }
        createNotification('info', 'Acțiunea se încarcă, așteptați.')
        await toggleInvoiceState(action, subInvStareState)
        setOpenPopup(false)
    }
    const invStare = (
        <div className='login-wrapper popup'>
            <div className='login-form popup'>
                <p>Căutare după</p>
                <select 
                value={subInvStareState.type} 
                onChange={(e) => setSubInvStareState(prev => ({...prev, type: e.target.value}))}
                className='input-text' id='type' 
                required>
                    <option value={0}>ID factură</option>
                    <option value={1}>Serie și număr</option>
                </select>
                {
                parseInt(subInvStareState.type) === 0 ?
                <>
                    <p>ID factură</p>
                    <input 
                    value={subInvStareState.id} 
                    onChange={(e) => setSubInvStareState(prev => ({...prev, id: e.target.value}))}
                    className='input-text' type='text' id='id' required/>
                </>
                    :
                <>
                    <p>Serie și număr (ex: AB1234)</p>
                    <input 
                    value={subInvStareState.id} 
                    onChange={(e) => setSubInvStareState(prev => ({...prev, id: e.target.value}))}
                    className='input-text' type='text' id='id' required/>
                </>
                }

                <div className='checkbox-wrapper' style={{marginBlock:'2em'}}>
                    <input className='checkbox' value={subInvStareState.sendMail} defaultChecked={subInvStareState.sendMail}
                    onChange={(e) => setSubInvStareState(prev => ({...prev, sendMail: e.target.checked}))}
                    type="checkbox" id="sendMail" name="sendMail" title='mail'/>
                    <label htmlFor="sendMail">Trimite email utilizatorului
                    </label>
                </div>

                <p>Marchează ca</p>
                <div className='admin-action-section'>
                    <div className='button-secondary selected-btn' 
                        onClick={() => handleinvStareAction('1')}>Achitată</div>
                    <div className='button-secondary'
                        onClick={() => handleinvStareAction('2')}>Anulată</div>
                </div>
            </div>
        </div>
    )

    // State of current invoice
    const [currentInvoice, setCurrentInvoice] = useState({})

    // tax function
    const getPriceTotalTva = (val, onlyTva = false) => {
        const TVA = 19/100
        const afterTax = onlyTva ? ((+val) * (+TVA)) : (((+val) * (+TVA)) + (+val))
        return afterTax.toFixed(2)
    }

    // tax function
    const getTotalFromInvoices = (withTva = false, invoicesGiven = filteredInvoices) => {
        const TVA = 19/100
        const total = invoicesGiven.reduce((sum, curr) => (+sum) + (+curr.price), 0)
        const afterTax = withTva ? (((+total) * (+TVA)) + (+total)) : +total
        return afterTax
    }

    // get invoice status string by status value
    const getStatusByType = (type) => {
        switch(type) {
            case '0': // proforma
                return 'PROFORMĂ'
            case '1': // achitata
                return 'ACHITATĂ'
            case '2': // anulata
                return 'ANULATĂ'
            default: // unknown
                return ''
        }
    }

    const invInfo = Object.keys(currentInvoice).length === 0 ? '' : (
        <div className='login-wrapper popup'>
            <div className='login-form popup'>
                <p>ID</p>
                <div>{ currentInvoice.id }</div>

                <p>Serie</p>
                <div>{ currentInvoice.series }</div>

                <p>Număr</p>
                <div>{ currentInvoice.number }</div>

                <p>Dată</p>
                <div>{ toFormatDate(getDateFromTimestamp(currentInvoice.timestamp)) }</div>

                <p>Stare</p>
                <div>{ getStatusByType(currentInvoice.status) }</div>

                <p>Preț</p>
                <div>Cu TVA: { getPriceTotalTva(currentInvoice.price) } Lei • Fără TVA: { currentInvoice.price } Lei • TVA: { getPriceTotalTva(currentInvoice.price, true) } Lei</div>

                <p>Produs</p>
                <div>{ currentInvoice.subscription.name }</div>

                <p>ID Produs</p>
                <div>{ currentInvoice.subscription.id }</div>

                <p>Email Client</p>
                <div>{ currentInvoice.client.email }</div>

                <p>ID Client</p>
                <div>{ currentInvoice.uid }</div>
            </div>
        </div>
    )

    const [currentContactMsg, setCurrentContactMsg] = useState({});

    const contactReply = (
        <div className='login-wrapper popup'>
            <div className='login-form popup'>
                <small>{toFormatDate(getDateFromTimestamp(currentContactMsg.timeStamp))} - {toFormatTime(getDateFromTimestamp(currentContactMsg.timeStamp))}</small>
                <p>Nume</p>
                <span>{currentContactMsg.name}</span>
                
                <p>Email</p>
                <span>{currentContactMsg.fromMail}</span>

                <p>Firma</p>
                <span>{currentContactMsg.company}</span>

                <p>Locație</p>
                <span>{currentContactMsg.location}</span>

                <p>Mesaj</p>
                <span style={{whiteSpace:"pre-line"}}>{currentContactMsg.message}</span>

                <p>Mesajul tău</p>
                <textarea className="input-text" type="text" name="replyMessage" id="replyMessage" rows="5" 
                    value={currentContactMsg.replyMsg} onChange={(e) => setCurrentContactMsg(prev => ({...prev, replyMsg: e.target.value}))} />

                <div className='button-secondary selected-btn'
                    onClick={() => {
                        replyToContactMessage(currentContactMsg);
                        setOpenPopup(false);
                    }}>Răspunde</div>
            </div>
        </div>
    )

    const handleOpenCurrentMessage = (msg) => {
        setCurrentContactMsg(msg);
        handleOpenPopup(5);
    }

    const popupContent = [
        subAdd,
        subStare,
        invStare,
        invInfo,
        cancelSub,
        contactReply
    ]

    const popupTitles = [
        'Adaugă abonament',
        'Stare abonament',
        'Stare factură',
        'Informații factură',
        'Anulează abonament',
        'Răspunde la mesaj'
    ]

    const handleOpenPopup = (content) => {
        const dataToAdd = {
            type: 3,
            content,
            title: popupTitles[content]
        }
        setPopupData(dataToAdd)
        setOpenPopup(true)
    }

    const emptyFilters = {
        series: '',
        number: '',
        id: '',
        status: '',
        type: '',
        uid: '',
        client: {
            email: ''
        },
        subscription: {
            id: '',
            name: ''
        }
    }

    const [getInvoiceDate, setGetInvoiceDate] = useState({
        min: '',
        max: ''
    })
    const [invoiceTextSearch, setInvoiceTextSearch] = useState('')
    const [filteredInvoices, setFilteredInvoices] = useState([])
    const [showFilters, setShowFilters] = useState(false)
    const [filters, setFilters] = useState(emptyFilters)

    // Get all invoices into the filter state when fetched
    useEffect(() => {
      setFilteredInvoices(displayInvoices)
    }, [displayInvoices])
    

    const loadInvoicesFromDates = async () => {
        if (getInvoiceDate.min === '' || getInvoiceDate.max === '') return createNotification('error', 'Selectați date valide')
        const dates = { from: new Date(getInvoiceDate.min), to:  new Date(getInvoiceDate.max)}
        if (dates.from > dates.to) return createNotification('error', 'Data de început nu poate fi mai mare decât cea de final')
        // setFilteredInvoices(displayInvoices)
        await fetchInvoicesByDates(dates)
    }

    const filterArray = (filtersGiven, arr) => {
        // function to get string 
        const getValue = (value) => (typeof value === 'string' ? value.toUpperCase() : value.toString());
        const filterKeys = Object.keys(filtersGiven);
        // function to filter by keys
        const filterByKeys = (res, keys = filterKeys, filterObj = filtersGiven) => {
            return keys.every( key => {
                if(!filterObj[key]) return arr;
                let isEq = getValue(filterObj[key]).toString().includes(getValue(res[key]))
                if (isEq && getValue(filterObj[key]) === '[object Object]') {
                    // recursion to filter by nested object keys
                    isEq = filterByKeys(res[key], Object.keys(filterObj[key]), filterObj[key])
                }
                return isEq
            })
        }
        // filter array
        const filterRes = arr.filter( res => {
            return filterByKeys(res)
        })
        return filterRes;
    }

    const filterAction = (isOn) => {
        // reset filter action
        if (!isOn) {
            setFilters(emptyFilters)
            setFilteredInvoices(displayInvoices)
            return
        }
        // filter action
        setFilteredInvoices(filterArray(filters, displayInvoices))
    }

    const openInvoiceInfo = (index) => {
        if (!filteredInvoices || !filteredInvoices[index]) return
        // set state to show in popup
        setCurrentInvoice(filteredInvoices[index])
        // open popup
        handleOpenPopup(3)
    }

    // print and show invoice
    const [printData, setPrintData] = useState({})
    const [invoiceDownload, setInvoiceDownload] = useState(false)
    const [printStart, setPrintStart] = useState(false)
    const invoiceRef = useRef()

    const startPrint = useReactToPrint({
        content: () => invoiceRef.current,
    });

    const printInvoice = async(inv) => {
        if (printStart) return
        setInvoiceDownload(false)
        createNotification('info', 'Se generează factura')
        setPrintData(inv)
        setPrintStart(true)
        await new Promise(r => setTimeout(r, 500));
        startPrint()
        await new Promise(r => setTimeout(r, 500));
        setPrintStart(false)
    }

    const downloadInvoice = async (inv) => {
        createNotification('info', `Se descarcă documentul ${inv.seriesNumber}.`)
        setInvoiceDownload(true)
        setPrintData(inv)
        setPrintStart(true)
        await new Promise(r => setTimeout(r, 200));
        await new Promise(r => setTimeout(r, 200));
        setPrintStart(false)
        setInvoiceDownload(false)
    }

    const downloadAllInvoices = async () => {
        for (let inv of filteredInvoices) {
            await downloadInvoice(inv)
        }
    }

    const simulateUserPrompt = () => {
        const userInput = window.prompt('ID of user to simulate:');
        if (userInput !== null && userInput !== "") {
            adminImpersonate(userInput);
        }
        else createNotification("error", "Valoare invalidă")
    }

    if(!uData.admin) return (
        <></>
    )

    return (
        <div className='app-default-wrapper'>
            <div className='app-default-section'>
                <h2>Panou administrator</h2>
            </div>
            <div className='admin-info-section'>
                <div className='admin-info-element'>
                    <h3>Date utilizator</h3>
                </div>
                <div className='admin-info-element'>
                    <div>ID: </div>
                    <div>{currentUser.uid}</div>
                </div>
                <div className='admin-info-element'>
                    <div>Nume: </div>
                    <div>{currentUser.displayName}</div>
                </div>
                <div className='admin-info-element'>
                    <div>Email: </div>
                    <div>{currentUser.email}</div>
                </div>
                <div className='admin-info-element'>
                    <div>Nivel acces: </div>
                    <div>{uData.accessLevel}</div>
                </div>
            </div>

            <div className='admin-info-section'>
                <div className='admin-info-element'>
                    <h3>Acțiuni</h3>
                </div>
                <div className='admin-action-section'>
                    <h3>Abonamente: </h3>
                    <div className='button-secondary' 
                        onClick={() => handleOpenPopup(0)}>Adaugă</div>
                    {
                    parseInt(uData.accessLevel) > 1 &&
                    <div className='button-secondary'
                        onClick={() => handleOpenPopup(1)}>Stare</div>
                    }
                    {
                    parseInt(uData.accessLevel) > 1 &&
                    <div className='button-secondary danger'
                        onClick={() => handleOpenPopup(4)}>Anulează</div>
                    }
                </div>
                <div className='admin-action-section'>
                    <h3>Facturi: </h3>
                    {
                    parseInt(uData.accessLevel) > 1 &&
                    <div className='button-secondary'
                        onClick={() => handleOpenPopup(2)}>Stare</div>
                    }
                </div>
                <div className='admin-action-section'>
                    <h3>Factura (Serie și număr): </h3>
                    <input 
                    value={invoiceTextSearch} placeholder='ABC1234'
                    onChange={(e) => setInvoiceTextSearch(e.target.value)}
                    className='input-text' type='text' id='seriesNrSearch' />
                    {
                    loadingAction ?
                    <FontAwesomeIcon className='spinner' icon={faSpinner}/> :
                    <div className='button-secondary'
                        onClick={() => loadinvoiceWithSeriesNr(invoiceTextSearch)}>Afișează</div>
                    }
                </div>
                <div className='admin-action-section'>
                    <h3>Facturi între: </h3>
                    <input 
                    value={getInvoiceDate.min} 
                    onChange={(e) => setGetInvoiceDate(prev => ({...prev, min: e.target.value}))}
                    className='input-text' type='date' id='dateStart' />
                    <h3> și </h3>
                    <input 
                    value={getInvoiceDate.max} 
                    onChange={(e) => setGetInvoiceDate(prev => ({...prev, max: e.target.value}))}
                    className='input-text' type='date' id='dateEnd' />
                    {
                    loadingAction ?
                    <FontAwesomeIcon className='spinner' icon={faSpinner}/> :
                    <div className='button-secondary'
                        onClick={() => loadInvoicesFromDates()}>Afișează</div>
                    }
                </div>
                {
                displayInvoices.length > 0 &&
                <div className='admin-action-section col'>
                    <FontAwesomeIcon className='button-secondary selected-btn' title='FILTRU'
                    onClick={() => setShowFilters(!showFilters)} icon={faFilter}/>
                    {
                    showFilters &&
                    <div className='filters-wrapper'>
                        <div className='filter'>
                            <p>Serie</p>
                            <input value={filters.series}
                            onChange={(e) => setFilters(prev => ({...prev, series: e.target.value}))}
                            className='input-text' name='series' id='series' type='text'/>
                        </div>
                        <div className='filter'>
                            <p>Număr</p>
                            <input value={filters.number}
                            onChange={(e) => setFilters(prev => ({...prev, number: e.target.value}))}
                            className='input-text' name='number' id='number' type='number'/>
                        </div>
                        <div className='filter'>
                            <p>ID</p>
                            <input value={filters.id}
                            onChange={(e) => setFilters(prev => ({...prev, id: e.target.value}))}
                            className='input-text' name='id' id='id' type='text'/>
                        </div>
                        <div className='filter'>
                            <p>Stare</p>
                            <select value={filters.status}
                            onChange={(e) => setFilters(prev => ({...prev, status: e.target.value}))}
                            className='input-text' id='status' >
                                <option value={''}>Toate</option>
                                <option value={'0'}>Neachitată</option>
                                <option value={'1'}>Achitată</option>
                                <option value={'2'}>Anulată</option>
                            </select>
                        </div>
                        <div className='filter'>
                            <p>Tip</p>
                            <select value={filters.type}
                            onChange={(e) => setFilters(prev => ({...prev, type: e.target.value}))}
                            className='input-text' id='type' >
                                <option value={''}>Toate</option>
                                <option value={'0'}>Proformă</option>
                                <option value={'1'}>Factură</option>
                            </select>
                        </div>
                        <div className='filter'>
                            <p>Email utilizator</p>
                            <input value={filters.client.email}
                            onChange={(e) => setFilters(prev => ({...prev, client: {...prev.client, email: e.target.value }}))}
                            className='input-text' name='uName' id='uName' type='text'/>
                        </div>
                        <div className='filter'>
                            <p>ID utilizator</p>
                            <input value={filters.uid}
                            onChange={(e) => setFilters(prev => ({...prev, uid: e.target.value}))}
                            className='input-text' name='uId' id='uId' type='text'/>
                        </div>
                        <div className='filter'>
                            <p>Nume pachet</p>
                            <input value={filters.subscription.name}
                            onChange={(e) => setFilters(prev => ({...prev, subscription: {...prev.subscription, name: e.target.value }}))}
                            className='input-text' name='SubName' id='SubName' type='text'/>
                        </div>
                        <div className='filter'>
                            <p>ID pachet</p>
                            <input value={filters.subscription.id}
                            onChange={(e) => setFilters(prev => ({...prev, subscription: {...prev.subscription, id: e.target.value }}))}
                            className='input-text' name='SubId' id='SubId' type='text'/>
                        </div>
                        <div className='admin-action-section end'>
                            <div className='button-secondary'
                                onClick={() => filterAction(false)}>Resetează</div>
                            <div className='button-secondary selected-btn'
                                onClick={() => filterAction(true)}>Aplică</div>
                        </div>
                    </div>
                    }
                    <div className='invoice-scroll wide'>
                        <div className='invoice-tab simple stick-top'>
                            <p>Serie</p>
                            <p>Număr</p>
                            <p>Dată</p>
                            <p>Stare</p>
                            <p>Acțiuni</p>
                        </div>
                        {
                        filteredInvoices.length > 0 &&
                        filteredInvoices.map((inv, i) => {
                            return (
                                <div className='invoice-tab simple' key={`${i} - ${inv.id}`}>
                                    <p>{ inv.series }</p>
                                    <p>{ inv.number }</p>
                                    <p>{ toFormatDate(getDateFromTimestamp(inv.timestamp)) }</p>
                                    <p>{ getStatusByType(inv.status) }</p>
                                    <div className='actions-inside'>
                                        <FontAwesomeIcon title='VEZI'
                                        onClick={ () => printInvoice(inv) }
                                        className='button-secondary adm-btn no-margin' icon={faEye}/>

                                        <FontAwesomeIcon title='DESCARCĂ'
                                        onClick={ () => downloadInvoice(inv) }
                                        className='button-secondary selected-btn no-margin' icon={faDownload}/>

                                        <FontAwesomeIcon title='INFO'
                                        onClick={ () => openInvoiceInfo(i) }
                                        className='button-secondary adm-btn no-margin' icon={faInfo}/>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                    {
                    filteredInvoices.length > 0 &&
                    <>
                    <p>Nr facturi: { filteredInvoices.length }</p>
                    <p>Total valoare: { formatNr(getTotalFromInvoices(true)) } LEI (cu TVA) • { formatNr(getTotalFromInvoices(false)) } LEI (fără TVA)</p>
                    <p>Descarcă toate facturile: <FontAwesomeIcon title='DESCARCĂ TOT'
                        onClick={ () => downloadAllInvoices() }
                        className='button-secondary no-margin' icon={faDownload}/>
                    </p>
                    </>
                    }
                </div>
                }
                <h3>Mesaje de contact</h3>
                {
                    loadingAction ?
                    <FontAwesomeIcon className='spinner' icon={faSpinner}/> :
                    <div className='button-secondary' onClick={fetchUnrepliedContacts}>Afișează mesaje de contact necitite</div>
                }
                {
                contactEmails.length > 0 &&
                <div className='invoice-scroll wide'>
                    <div className='invoice-tab simple stick-top'>
                        <p>Dată</p>
                        <p>Nume</p>
                        <p>Email</p>
                        <p>Acțiune</p>
                    </div>
                    {
                        contactEmails.map((contactMail, i) => {
                            return(
                            <div onClick={() => handleOpenCurrentMessage(contactMail)} className='invoice-tab simple selector' key={i + contactMail.timeStamp.toString()}>
                                <p>
                                    {toFormatDate(getDateFromTimestamp(contactMail.timeStamp))} - {toFormatTime(getDateFromTimestamp(contactMail.timeStamp))}
                                </p>
                                <p>{contactMail.name}</p>
                                <p>{contactMail.fromMail}</p>
                            </div>
                            )
                        })
                    }
                </div>
                }
                {/* {
                    uData.admin && uData.accessLevel > 9 &&
                    <>
                    <h3>Administratori</h3>
                    <div className='admin-action-section col'>
                        <input className='input-text' type="text" placeholder='ID' title='ID' value={newAdminData.id} 
                            onChange={(e) => setNewAdminData(prev => ({...prev, id: e.target.value}))} />
                        <input className='input-text' type="number" placeholder='Nivel (1-10)' title='Nivel' value={newAdminData.lvl} 
                            onChange={(e) => setNewAdminData(prev => ({...prev, lvl: e.target.value}))} />
                        {
                            loadingAction ?
                            <FontAwesomeIcon className='spinner' icon={faSpinner}/> :
                            <div className='button-secondary' onClick={() => addNewAdmin(newAdminData.id, newAdminData.lvl)}>Adaugă</div>
                        }
                    </div>
                    </>
                } */}
            {
                uData.admin && parseInt(uData.accessLevel) > 9 &&
                (loadingAction ?
                <FontAwesomeIcon className='spinner' icon={faSpinner}/> :
                <div onClick={() => loadUsersAPI()} className='button-secondary'>Load all users</div>)
            }
            { userAccounts.length > 0 &&
            <div id="admin-table-scroll" className="admin-table-scroll">
                <table id="admin-main-table" className="admin-main-table">
                    <thead>
                        <tr className='admin-table-stick'>
                            <th scope="col">Email</th>
                            <th scope="col">uID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Ver. Email</th>
                            <th scope="col">Last sign in \/</th>
                            <th scope="col">Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            userAccounts.map((usr, i) => {
                                return(
                                    <tr className='admin-table-stick' key={usr.email + i}>
                                        <th>{usr.email} {usr.disabled ? "(disabled)" : ""}</th>
                                        <td>{usr.uid}</td>
                                        <td>{usr.displayName}</td>
                                        <td style={{color:`${usr.emailVerified ? "green" : "red"}`}}>{usr.emailVerified.toString()}</td>
                                        <td>{usr.metadata.lastSignInTime}</td>
                                        <td>{usr.metadata.creationTime}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            }
            {
                uData.admin && parseInt(uData.accessLevel) > 9 &&
                (loadingAction ?
                <FontAwesomeIcon className='spinner' icon={faSpinner}/> :
                <div onClick={() => simulateUserPrompt()} className='button-secondary'>Simulate user</div>)
            }
            </div>
            { openPopup && 
                <Popup data={popupData} close={() => setOpenPopup(false)}> 
                    {popupContent[popupData.content]}
                </Popup>
            }
            {
                printStart &&
                <section className='hidden'>
                    <InvoiceToPrint ref={invoiceRef} data={printData} toDownload = {invoiceDownload} /> 
                </section> 
            }
        </div>
    )
}

export default AdminPanel