///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, { useState, useEffect } from 'react'
import Layouts from './MainAppComponents/Layouts'
import Profile from './MainAppComponents/Profile'
import Subscriptions from './MainAppComponents/Subscriptions'
import { useAuth } from '../context/AuthContext';
import Companies from './MainAppComponents/Companies';
import { createNotification } from "../services/NotificationService"
import { checkCookie, EMAIL_VERIFY_COOKIE, setCookie } from '../services/CookieService';
import Invoices from './MainAppComponents/Invoices';
import ImportExcel from './MainAppComponents/ImportExcel';
import { isStillActive } from '../services/SubscriptionsService';
import ConfirmBox from './ConfirmBox';
import AdminPanel from './MainAppComponents/AdminComponents/AdminPanel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faMagnifyingGlass, faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip';
import ModalSetValue from './MainAppComponents/ModalSetValue';
import GeneratedHistory from './MainAppComponents/GeneratedHistory';
import { useNavigate } from "react-router-dom";

const MainApp = () => {
    const navigate = useNavigate();

    const goToPage = (page) => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        navigate('/' + page);
    };

    const [modal, setModal] = useState(false)
    const [page, setPage] = useState('')
    const [openConfirm, setOpenConfirm] = useState(false)
    const [confirmData, setConfirmData] = useState({})
    const [windowOpen, setWindowOpen] = useState(false)
    const {
        currentUser,
        userData,
        loading,
        verifyEmail
    } = useAuth()

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [loading, windowOpen])
    

    const notWithoutValid = new Set(['import', 'companies', 'layouts'])
    
    const handleOpen = async (window, amSure = false) => {
        if(loading) return
        if(!currentUser.emailVerified) {
            setConfirmData({
                content: (
                    <>
                    Verificați adresa de email {currentUser.email} pentru a vă activa contul.
                    <br />
                    Dacă nu ai primit email de confirmare poți da click pe <b>"Retrimite"</b> pentru a primi unul.
                    <p>
                        Dă click pe linkul primit pentru a valida adresa de email.
                        <br />
                        Nu uita să verifici folderul "SPAM".
                    </p>
                    </>
                ),
                title: 'Verificați adresa de email',
                func: () => checkEmail(),
                buttonConfirm: 'Retrimite', 
                buttonCancel: 'Închide',
            })
            setOpenConfirm(true)
            return createNotification(
                'info', 
                `Pentru a beneficia de program trebuie să vă activați adresa de email ${currentUser.email}`,
                'Verificați email')
        }

        let toOpen = window
        amSure && setOpenConfirm(false)

        if(page === 'import' && !amSure) {
            let messageToDisplay = "Sigur doriți să părăsiți pagina? Progresul va fi pierdut."
            if (toOpen === 'import') {
                messageToDisplay += " Nu uitați să salvați modificările făcute local în fișierul Excel înainte de a îl reîncărca pentru a fi vizibile în aplicație."
            }
            setConfirmData({
                content: messageToDisplay,
                title: 'Confirmați',
                buttonConfirm: 'Confirmă', 
                buttonCancel: 'Anulează',
                func: () => handleOpen(window, true)
            })
            setOpenConfirm(true)
            return
        }

        // check if user has any subscription
        const hasNoSub = userData.subscriptions.length === 0
        if (notWithoutValid.has(window) && hasNoSub) {
            createNotification('info', 'Nu aveți niciun abonament selectat.')
            return
        }

        if (notWithoutValid.has(window) && !userData.subscriptions[0].valid) {
            createNotification('error', 'Abonamentul actual este invalid. Efectuați plata pentru validare')
            return
        } 

        if(document.getElementById(toOpen).classList.contains('selected-btn')) {
            document.querySelectorAll('.button-secondary.w60')
            .forEach(btn => btn.classList.remove('selected-btn'))
            setWindowOpen(false);
            setPage('');
            return
        }

        if(toOpen !== 'subscriptions' 
            && toOpen !== 'invoices' 
            && toOpen !== 'admin-panel'
            && toOpen !== 'profile') {
            const goToSub = async () => {
                setWindowOpen(true);
                setPage('subscriptions');
                await new Promise(r => setTimeout(r, 100));
                return document.getElementById('subscriptions').classList.add('selected-btn')
            }
            if (hasNoSub) {
                await goToSub()
                return createNotification('error', 'Alegeți un abonament pentru a beneficia de program.')
            }
            if(!isStillActive(userData.subscriptions[0].date, 
                userData.subscriptions[0].duration,
                userData.subscriptions[0].manualDisable)) {
                    await goToSub()
                    return createNotification('error', `Abonamentul actual este expirat. 
                    Selectați unul nou pentru a beneficia de program.`)
            }
        }

        setWindowOpen(true);
        setPage(toOpen);

        // set navi buttons color
        await new Promise(r => setTimeout(r, 100));
        document.querySelectorAll('.button-secondary.w60')
            .forEach(btn => btn.classList.remove('selected-btn'))
        document.getElementById(toOpen).classList.add('selected-btn')

        ReactTooltip.rebuild();
    }

    const checkEmail = async () => {
        if(checkCookie(EMAIL_VERIFY_COOKIE)) {
            return createNotification('error', `Un email pentru activarea contului a fost deja trimis`)
        }
        else {
            await verifyEmail(currentUser.email)
            setCookie(EMAIL_VERIFY_COOKIE, true, 0.1, false)
        }
    }

    return (
        <div className='app-wrapper'>
            <h1 className={`main-title${windowOpen ? ' hide' : ''}`}>Program Intrastat</h1>
            <div className='app-section'>
                <div className= "app-row">
                    {
                    loading ?
                    <FontAwesomeIcon className='spinner' icon={faSpinner}/>
                    :
                    <>
                    <div className={`button-secondary w60 ${windowOpen ? 'top-row' : 'long-btn'}`} 
                    onClick={() => handleOpen('import')} id='import'>
                        {page === 'import' ? 'Reimportă Excel' : 'Importă Excel'}
                    </div>

                    <div className={`button-secondary w60 ${windowOpen ? 'top-row' : ''}`}  
                    onClick={() => handleOpen('generatedHistory')} id='generatedHistory'>Istoric declarații</div>

                    <div className={`button-secondary w60 ${windowOpen ? 'top-row' : ''}`}  
                    onClick={() => handleOpen('layouts')} id='layouts'>Machete</div>
                    
                    <div className={`button-secondary w60 ${windowOpen ? 'top-row' : ''}`} 
                    onClick={() => handleOpen('profile')} id='profile'>Profil</div>

                    {userData.data && userData.data.tert &&
                    <div className={`button-secondary w60 ${windowOpen ? 'top-row' : ''}`} 
                    onClick={() => handleOpen('companies')} id='companies'>Firme</div>
                    }

                    <div className={`button-secondary w60 ${windowOpen ? 'top-row' : ''}`} 
                    onClick={() => handleOpen('subscriptions')} id='subscriptions'>Abonamente</div>

                    <div className={`button-secondary w60 ${windowOpen ? 'top-row' : ''}`} 
                    onClick={() => handleOpen('invoices')} id='invoices'>Plăți</div>

                    {!windowOpen &&
                    <div className='buttons-2-wrapper'>
                        <div data-tip='Caută coduri vamale (HS)' 
                            data-for="searchTT" className='button-secondary'
                            onClick={() => setModal(true)}>
                            <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </div>
                        <div data-tip='Instrucțiuni' 
                            onClick={() => goToPage("docs")}
                            data-for="searchTT" className='button-secondary blue-btn'>
                            Documentație <FontAwesomeIcon icon={faCircleQuestion}/>
                        </div>
                    </div>
                    }
                    {
                        userData.data && userData.data.admin &&
                        <div className={`button-secondary adm-btn w60 ${windowOpen ? 'top-row' : ''}`} 
                        onClick={() => handleOpen('admin-panel')} id='admin-panel'>Panou admin</div>
                    }
                    </>
                    }
                </div>
                
                {page === 'profile' && <Profile />}
                {page === 'generatedHistory' && <GeneratedHistory />}
                {page === 'subscriptions' && <Subscriptions />}
                {page === 'layouts' && <Layouts />}
                {page === 'companies' && <Companies />}
                {page === 'invoices' && <Invoices />}
                {page === 'admin-panel' && userData.data.admin && <AdminPanel />}
            </div>
            {page === 'import' && <ImportExcel />}
            {openConfirm &&
            <ConfirmBox 
                onConfirm = {confirmData.func}
                onClose = {() => setOpenConfirm(false)}
                content = {confirmData.content}
                title = {confirmData.title}
                buttonConfirm = {confirmData.buttonConfirm}
                buttonCancel = {confirmData.buttonCancel}
            />}
            <ReactTooltip effect="solid" id='searchTT' type='info'
                getContent={(dataTip) => `${dataTip}`}/>

            {modal && <ModalSetValue 
                values = {{type: 0}}
                setValue = {false}
                close = {() => setModal(false)}
            />}
        </div>
    )
}

export default MainApp