///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------
import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { InvoiceToPrint } from '../../services/PdfService'
import { getDateFromTimestamp, toFormatDate } from '../../services/DateService'
import Popup from '../Popup';
import { createNotification } from '../../services/NotificationService'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faEye, faDotCircle } from '@fortawesome/free-solid-svg-icons'
import { useReactToPrint } from 'react-to-print';
import { formatNr } from '../../services/FormatService';
import { userSideId } from '../../services/IdService';

const Invoices = () => {
    const [loadingPrint, setLoadingPrint] = useState(false)
    const [printData, setPrintData] = useState({})
    const [sortedInvoices, setSortedInvoices] = useState([])
    const [invoiceDownload, setInvoiceDownload] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)
    const [popupData, setPopupData] = useState()
    // 0 - Bank transfer; 1 - Online
    const [paymentType, setPaymentType] = useState('')

    const { 
        currentUser,
        invoiceData,
        fetchInvoiceData,
        fetchTaxData,
        taxData,
        loadingAction
    } = useAuth()

    const invoiceRef = useRef()

    useEffect(() => {
        if (invoiceData.length === 0) {
            fetchInvoiceData(currentUser.uid)
        }
        fetchTaxData(true, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // sort array
        const sortedArray = invoiceData.sort((a, b) =>
            (getDateFromTimestamp(a.timestamp) < getDateFromTimestamp(b.timestamp)) ? 1 : 
            (getDateFromTimestamp(a.timestamp) > getDateFromTimestamp(b.timestamp)) ? -1 : 0
        )
        setSortedInvoices(sortedArray)
    }, [invoiceData])

    const getTotalInvPrice = (val, quant, rate) => {
        const tvaVal = 19/100
        const getTotalWithTva = (val) => {
            return (+getTva(val) + val).toFixed(2)
        }
        const getValFromEur = (val) => {
            return (+val * (+rate)).toFixed(2)
        }
        const getTva = (val) => {
            return (tvaVal * val).toFixed(2)
        }
        return getTotalWithTva(getValFromEur(val) * (+quant))
    }

    const getColorByType = (type) => {
        switch(type) {
            case '0': // proforma
                return 'orange'
            case '1': // achitata
                return 'green'
            case '2': // anulata
                return 'red'
            default: // unknown
                return 'white'
        }
    }

    const getStatusByType = (type) => {
        switch(type) {
            case '0': // proforma
                return 'PROFORMĂ'
            case '1': // achitata
                return 'ACHITATĂ'
            case '2': // anulata
                return 'ANULATĂ'
            default: // unknown
                return ''
        }
    }

    const startPrint = useReactToPrint({
        content: () => invoiceRef.current,
    });

    const printInvoice = async (data) => {
        if (loadingPrint) return
        createNotification('info', 'Se generează factura')
        setInvoiceDownload(false)
        setPrintData(data)
        setLoadingPrint(true)
        await new Promise(r => setTimeout(r, 1100));
        startPrint()
        await new Promise(r => setTimeout(r, 500));
        setLoadingPrint(false)
    }

    const downloadInvoice = async (data) => {
        if (loadingPrint) return
        createNotification('info', 'Se descarcă factura')
        setInvoiceDownload(true)
        setPrintData(data)
        setLoadingPrint(true)
        await new Promise(r => setTimeout(r, 500));
        setLoadingPrint(false)
        setInvoiceDownload(false)
    }

    const getInvoiceDate = (inv) => {
        const newDate = inv.status === '1' ? inv.paidDate : inv.timestamp
        return toFormatDate(getDateFromTimestamp(newDate))
    }

    // Payment
    const payWindow = (data) => { 
        return (
        <div className='login-wrapper popup'>
            <div className='login-form popup'>
                <p>Achitați factura '{data.seriesNumber}' pentru abonamentul '{data.subscription.name}'</p>
                <p>Achită prin:</p>
                <div className='admin-action-section'>
                    <div className='button-secondary' onClick={() => setPaymentType('0')}>Cont</div>
                    <div onClick={() => createNotification('info', 'Această metodă de plată nu este acceptată momentan')}
                    className='button-secondary disabled'>Plată online</div>
                </div>
                {
                    paymentType === '0' &&
                    <>
                    <p>Date pentru plata prin cont (transfer bancar)</p>
                    <div><b>IBAN: </b><span style={{userSelect:'all'}}>{taxData.iban}</span></div>
                    <div><b>Bancă: </b>{taxData.bank}</div>
                    <div><b>Nume beneficiar: </b><span style={{userSelect:'all'}}>{taxData.name}</span></div>
                    <div><b>Detalii plată: </b>
                    <span style={{userSelect:'all'}}>
                        { userSideId(data.subscription.id) }
                    </span>
                    </div>
                    <div><b>CUI beneficiar: </b>{taxData.cif}</div>
                    <div><b>Total de plată: </b>
                        <span style={{userSelect:'all'}}>
                        {formatNr(getTotalInvPrice(data.subscription.priceEur, data.subscription.quantity, data.currencyRate))} Lei
                        </span>
                    </div>
                    <br />
                    <small>Pentru o activare mai rapidă a pachetului ne puteți trimite dovada plății la adresa de email <span style={{userSelect:'all'}}>programintrastat@gmail.com</span>.</small>
                    <br />
                    </>
                }
            </div>
        </div>
    )}

    const openPopupWindow = (data) => {
        const dataToAdd = {
            type: 3,
            title: data.title,
            content: data.content
        }
        setPopupData(dataToAdd)
        setOpenPopup(true)
    }

    const payNow = (data) => {
        setPaymentType('')
        const dataForPopup = {
            title: `Achită factura ${data.seriesNumber} - ${getInvoiceDate(data)}`,
            content: data
        }
        openPopupWindow(dataForPopup)
    }

    return (
        <>
        <div className='app-default-wrapper'>
            <div className='app-default-section'>
                <h2>Istoric plăți</h2>
            </div>
            {
                loadingAction ?
                <FontAwesomeIcon className='spinner' icon={faSpinner}/> :

                <>
                <div className='invoice-scroll'>
                    { invoiceData.length === 0 && 'Nu aveți nici o factură' }
                    {
                    sortedInvoices.length > 0 && sortedInvoices.map((invoice, index) => {
                        if (invoice.status === '2') return null
                        return(
                            <div className='invoice-tab' key={`${index}-${invoice.id}`}>
                                <FontAwesomeIcon style={{color:getColorByType(invoice.type)}}
                                className='left-top-icon' icon={faDotCircle}/>
                                <table className='invoice-table'>
                                    <thead>
                                        <tr>
                                            <th>Serie și nr</th>
                                            <th>Dată</th>
                                            <th>Stare</th>
                                            <th>Factură</th>
                                            <th>Acțiuni</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='table-id'>{invoice.status !== '2' && `${invoice.series}${invoice.number}`}</td>
                                            <td>{ invoice.status !== '2' && getInvoiceDate(invoice) }</td>
                                            <td className='state-bubble' style={{backgroundColor:getColorByType(invoice.type)}}>{getStatusByType(invoice.status)}</td>
                                            <td>
                                                { invoice.status !== '2' && <FontAwesomeIcon onClick={() => printInvoice(invoice)} className='button-secondary adm-btn no-margin' icon={faEye}/> }
                                            </td>
                                            {
                                            invoice.status === '0' ?
                                            <td onClick={() => payNow(invoice)}
                                            className='button-secondary selected-btn bold no-padd no-margin'>Achită</td> :
                                            <td onClick={() => downloadInvoice(invoice)}
                                            className='button-secondary selected-btn bold no-padd no-margin'>Descarcă</td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )
                    })
                    }

                </div>
                <div className='history-row'>
                    <p>
                    <FontAwesomeIcon style={{color:'green'}} icon={faDotCircle}/> - Achitată 
                    </p>
                    <p>
                    <FontAwesomeIcon style={{color:'orange'}} icon={faDotCircle}/> - Neachitată 
                    </p>
                    {/* <p>
                    <FontAwesomeIcon style={{color:'red'}} icon={faDotCircle}/> - Anulată
                    </p> */}
                </div>
                </>
            }
        </div>
        { 
            openPopup &&
            <Popup data={popupData} close={() => setOpenPopup(false)}> 
                {payWindow(popupData.content)}
            </Popup>
        }
        {
            loadingPrint &&
            <section className='hidden'>
                <InvoiceToPrint ref={invoiceRef} data={printData} toDownload = {invoiceDownload} /> 
            </section> 
        }
        </>
    )
}

export default Invoices