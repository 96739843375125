///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, { useState } from 'react'

import { useAuth } from '../../context/AuthContext';
import { getExcelSheets, getSheetValues } from '../../services/ExcelService';
import LayoutForm from './LayoutForm';
import { getLayoutsLimit } from '../../services/SubscriptionsService';

import ReactTooltip from 'react-tooltip';
import { createNotification } from "../../services/NotificationService"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faPenToSquare, faX } from '@fortawesome/free-solid-svg-icons'


const Layouts = () => {

    const {  
        layouts,
        currentUser,
        userData,
        newLayout,
        updateLayout,
        deleteLayout,
        companies
    } = useAuth()

    const [forUpdate, setForUpdate] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const [updateData, setUpdateData] = useState({})
    const [type, setType] = useState('achizitii')
    const [name, setname] = useState('macheta mea #' + (layouts.length + 1))
    const [layoutScreen, setLayoutScreen] = useState(false)
    const [isUploaded, setIsUploaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [currentSheet, setCurrentSheet] = useState('')
    const [currentComp, setCurrentComp] = useState(companies[0])
    const [sheetValues, setSheetValues] = useState({
        sheetNames: [],
        values: []
    })

    const returnTypeString = (type) => {
        switch(type) {
            case 'achizitii':
                return 'Achiziții'
            case 'livrari':
                return 'Livrări'
            default:
                break;
        }
    }

    const handleExcelUpload = async (file) => {
        setIsLoading(true)
        const sheets = getExcelSheets(file[0])
        const sheetValues = getSheetValues(file[0])
        await new Promise(r => setTimeout(r, 200));
        if(sheets.length > 0) {
            setIsUploaded(true)
            createNotification('success', `Fișier importat cu succes.`)
            setIsLoading(false)
        }
        else {
            setIsUploaded(false)
            setIsLoading(false)
            return createNotification('error', `Fișierul are un format incorect.`)
        }
        setSheetValues(prev => ({...prev, sheetNames: sheets, values: sheetValues}))
        setCurrentSheet(0)
    }

    const newLayoutToggle = (update, id) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setOpenForm(!openForm)
        if(update) {
            setForUpdate(true)
            setIsUploaded(true)
            for(const layout of layouts) {
                if(layout.dataId === id) {
                    setUpdateData(layout)
                    setType(layout.type)
                    setname(layout.name)
                    setCurrentComp(layout.company)
                }
            }
            return setLayoutScreen(true)
        }
        setCurrentComp(companies[0].cif)
        setname('macheta mea #' + (layouts.length + 1))
        setIsUploaded(false)
        setForUpdate(false)
        if(layouts.length >= getLayoutsLimit(userData.subscriptions[0].name)) {
            setLayoutScreen(false)
            return createNotification('error', `Ați atins numărul maxim de machete disponibile pentrul abonamnetul actual.`)
        }
        setLayoutScreen(!layoutScreen)
    }

    const deleteCurrentLayout = async (id) => {
        await deleteLayout(id)
    }

    const handleSave = async (data, excelHeaders) => {
        if(forUpdate) {
            setIsLoading(true)
            setLayoutScreen(false)
            const toSave = {
                type: type,
                name: name,
                company: currentComp,
                data: data
            }
            await updateLayout(updateData.dataId, toSave)
            return setIsLoading(false)
        }

        // if(layouts.length >= getLayoutsLimit(userData.subscriptions[0].name)) {
        //     createNotification('error', `Ați atins numărul maxim de machete disponibile pentrul abonamnetul actual.`)
        //     return false
        // }

        if(layouts.length > 0) {
            for(const layout of layouts) {
                if(layout.name === name) {
                    createNotification('error', `Există deja o machetă cu acest nume.`)
                    return false
                }
            }
        }
        setLayoutScreen(false)
        setIsLoading(true)

        const toSave = {
            uid: currentUser.uid,
            type: type,
            name: name,
            sheet: currentSheet,
            company: currentComp,
            active: true,
            data: data,
            headers: excelHeaders
        }
        await newLayout(toSave)
        setIsLoading(false)
    }

    const handleDiscard = () => {
        setIsLoading(false)
        setLayoutScreen(false)
        setIsUploaded(false)
        setForUpdate(false)
    }

    const getCompName = (id) => {
        const toReturn = companies.filter(com => com.cif === id)
        if (toReturn.length > 0) return toReturn[0].name
        else return id
    }

    return (
        <div className='app-default-wrapper'>
            <div className='app-default-section'>
                <div className='app-default-header'>
                    <div className='button-secondary btn-green' onClick={() => newLayoutToggle(false)}>
                        { openForm ? 'Înapoi' : 'Machetă nouă' }
                    </div>
                </div>

            {layoutScreen &&
                <>
                <div className='new-layout-wrapper'>
                    <form className='layout-form'>
                        <div className='new-layout-header' style={{justifyContent:'center'}}>
                            <div className='form-section-label'>
                                <p>Nume</p>
                                <input type='text' value={name} onChange={e => setname(e.target.value)}
                                id='layout-name' className='input-text' required/>
                            </div>
                            <div className='form-section-label'>
                                <p>Tip</p>
                                <select name='tip' value={type} onChange={e => setType(e.target.value)}
                                id='tip' className='input-text' required>
                                    <option value='achizitii' defaultValue>Achiziții</option>
                                    <option value='livrari'>Livrări</option>
                                </select>
                            </div>
                            {
                                !forUpdate &&
                                <div className='form-section-label'>
                                    <p>Fișier Excel de referință</p>
                                    <input type='file' id='excel' className='input-text upload-file' 
                                    onChange={(e) => handleExcelUpload(e.target.files)}
                                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'/>
                                </div>
                            }
                            <div className='form-section-label'>
                                <p>Firmă</p>
                                <select onChange={(e) => setCurrentComp(e.target.value)} value={currentComp}
                                name='comp' id='comp' className='input-text' disabled={!userData.data.tert}>
                                    {companies.sort().map((comp, index) => {
                                        return(
                                            <option key={`${comp.dataId} - ${index}`} value={comp.cif}>
                                                {comp.name}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                            {
                                !forUpdate && isUploaded && sheetValues.sheetNames &&
                                <div className='form-section-label'>
                                    <p>Fișa de lucru</p>
                                    <select onChange={(e) => setCurrentSheet(e.target.value)} 
                                    name='tip' id='tip' className='input-text' required>
                                        {sheetValues.sheetNames.map((name, index) => {
                                            return(
                                                <option key={`${name} - ${index}`} value={index}>
                                                    {name}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            }
                        </div>
                    </form>
                </div>

                {isLoading && <FontAwesomeIcon className='spinner' icon={faSpinner}/>}

                {isUploaded && <LayoutForm 
                    fileData={sheetValues} 
                    currentIndex={currentSheet} 
                    save = {handleSave}
                    toUpdate = {forUpdate}
                    updateData = {updateData}
                    type = {type}
                    discard = {handleDiscard}
                />}
                </>
            }

            {!layoutScreen &&
                <>
                <table className='simple-table'>
                    <thead>
                        <tr>
                            <th>Nr</th>
                            <th>Denumire</th>
                            <th>Firmă</th>
                            <th>Tip</th>
                            <th>Acțiuni</th>
                        </tr>
                    </thead>
                    {layouts.length > 0 ? layouts.map((layout, index) => {
                        return (
                        <tbody key={`${layout.dataId} - ${layout.name}`}>
                            <tr>
                                <td>{index + 1}</td>
                                <td>{layout.name}</td>
                                <td>{`${getCompName(layout.company)}`}</td>
                                <td>{returnTypeString(layout.type)}</td>
                                <td>
                                    <div className='edit-buttons'>
                                        <span onClick={() => newLayoutToggle(true, layout.dataId)}
                                        data-tip data-for="edit-tt">
                                        <FontAwesomeIcon style={{color:"green"}} icon={faPenToSquare}/>
                                        </span>
                                        <span onClick={() => deleteCurrentLayout(layout.dataId)} 
                                        data-tip data-for="delete-tt">
                                        <FontAwesomeIcon style={{color:"red"}} icon={faX}/>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        )
                    }) :
                    <tbody>
                        <tr>
                            <td colSpan={5}>Nici o machetă disponibilă <b>
                                <span onClick={() => newLayoutToggle(false)} className='link'>creați una</span></b>
                            </td>
                        </tr>
                    </tbody>
                    }
                </table>
                <small style={{textAlign:'left'}}>Număr machete: <b>{layouts.length}</b> /
                {userData.subscriptions && parseInt(userData.subscriptions[0].layoutsAvailable)}</small>
                </>
            }
            </div>
            <ReactTooltip effect="solid" id='edit-tt' type='success'>Editează</ReactTooltip>
            <ReactTooltip effect="solid" id='delete-tt' type='error'>Sterge</ReactTooltip>
        </div>
    )
}

export default Layouts