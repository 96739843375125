///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React from 'react'

const Policy = () => {
  return (
    <div className='terms-wrapper'>
        <h1>Politică de confidențialitate</h1>
        <div className='terms-text'>
        <p>În acest document vă explicăm modul în care societatea INTRASON S.R.L. prelucrează datele dumneavoastră cu
        caracter personal și în care asigură protecția acestora, conform legislației aplicabile în materie, inclusiv
        Regulamentul (UE) 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu
        caracter personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46 / CE <strong>(GDPR)</strong>.
        </p>
        <p>Această notă se aplică în legătură cu prelucrările de date realizate de INTRASON S.R.L.. Acest document
            este relevant pentru dumneavoastră indiferent de poziția în care vă aflați: client, partener – persoană fizică,
            reprezentant al unui partener – persoană juridică, solicitant al unei oferte sau persoană care ne contactează în
            alte scopuri (eg, în legătură cu cereri sau reclamații), vizitator al unuia dintre sediile ori punctele noastre
            de lucru, intern, vizitator al site-ului nostru, etc.)</p>
        <p>Această notă tratează, în ordinea de mai jos, următoarele aspecte:</p>
        <ol type="a">
            <li><p>categoriile de date cu caracter personal pe care le prelucrăm;</p></li>
            <li><p>scopurile pentru care prelucrăm acele date cu caracter personal;</p></li>
            <li><p>temeiurile care justifică prelucrările noastre;</p></li>
            <li><p>persoanele către care divulgăm datele;</p></li>
            <li><p>perioada pentru care stocăm datele;</p></li>
            <li><p>consecințele refuzului de a ne furniza datele cu caracter personal privitoare la dumneavoastră;</p></li>
            <li><p>drepturile dumneavoastră și informații cu privire la exercitarea acestora;</p></li>
            <li><p>detaliile noastre de contact; și</p></li>
            <li><p>situațiile în care se aplică această notă informare și modificările sale.</p></li>
        </ol>
        <br />
        <ol>
            <li><p><strong>CATEGORII DE DATE. SCOP. TEMEI</strong></p>
                <br />
                <ol>
                    <li><p><strong><span>1.1.</span> Clienți actuali sau potențiali ai INTRASON S.R.L.</strong></p>
                        <br />
                        <p>Putem prelucra date cu caracter personal referitoare la reprezentanții clienților noștri
                            pentru: </p>
                        <ol type="i">
                            <li>
                                <p><strong>Furnizarea serviciilor noastre.</strong> Vom utiliza datele dumneavoastră pentru
                                    a vă transmite o ofertă (la cererea dumneavoastră), pentru a încheia și executa
                                    contractul cu dumneavoastră, și pentru a ne asigura că vă furnizăm serviciile
                                    solicitate. În acest caz, vom întemeia prelucrările pe necesitatea încheierii și/ sau
                                    executării contractului cu dumneavoastră și pe consimțământul dumneavoastră pentru
                                    transmiterea de oferte. Vom prelucra în principal datele dumneavoastră de contact (nume,
                                    prenume, email, telefon) și, dacă avem o relație contractuală cu dumneavoastră, datele
                                    incluse în contractul cu dumneavoastră.</p></li>
                            <li>
                                <p><strong>Funcționarea serviciilor noastre.</strong> Vom utiliza datele dumneavoastră
                                    pentru a asigura funcționarea serviciilor noastre, prin intermediul evaluării acestora
                                    și a transmiterii de mesaje/ alerte tranzacționale sau legate de funcționalități ale
                                    programelor noastre. De asemenea, folosim datele dumneavoastră pentru statistici interne
                                    privind funcționalitatea serviciilor.</p>
                                <p>Vom prelucra în principal datele dumneavoastră de identificare (nume, prenume, cod
                                    numeric personal, adresă de e-mail, număr de telefon), feedbackul dumneavoastra, adresă
                                    IP, date din loguri de acces și utilizare. </p>
                                <p>Temeiul acestor prelucrări va fi, după caz, executarea contractului cu dumneavoastră,
                                    interesul nostru legitim sau consimțământul.</p></li>
                            <li>
                                <p><strong>Soluționarea cererilor dumneavoastră.</strong> Vom utiliza datele dumneavoastră
                                    pentru a răspunde eventualelor solicitări, cerințe, reclamații, sau oricăror altor
                                    întrebări pe care ni le transmiteți în cadrul relației cu dumneavoastră.</p>
                                <p>În acest caz, temeiul prelucrărilor va fi executarea contractului cu dumneavoastră
                                    sau,după caz, consimțământul dumneavoastră.</p>
                                <p>Vom folosi în principal numele, prenumele dumneavoastră, datele dumneavoastră de contact
                                    (nume, prenume,adresă de e-mail, număr de telefon) și informații cuprinse în cererea pe
                                    care ne-o transmiteți. </p>
                            </li>
                            <li>
                                <p><strong>Comunicare în scop de marketing.</strong> Pentru a vă trimite comunicări și
                                    oferte cu privire la produsele, serviciile și promoțiile noastre (din inițiativa
                                    noastră), este necesar să prelucrăm datele dumneavoastră cu caracter personal. În acest
                                    caz, prelucrarea datelor va fi întemeiată pe consimțământul dumneavoastră.</p>
                                <p>Vom prelucra în principal următoarele date care se referă la dumneavoastră: nume,
                                    prenume, adresă de e-mail, număr de telefon, .</p>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li><p><strong><span>1.2.</span> Membri (angajați, colaboratori etc.) ai partenerilor noștri
                        contractuali – persoane juridice</strong></p>
                        <br />
                        <p>Putem prelucra datele dumneavoastră pentru:</p>
                        <ol type="i">
                            <li><p><strong>Menținerea relației contractuale cu companiile din care faceți parte.</strong>
                                Pentru a putea să colaborăm cu compania din care faceți parte (inclusiv pentru a comunica cu
                                dumneavoastră pentru soluționarea eventualelor probleme pe care compania dumneavoastră sau
                                INTRASON S.R.L. le întâmpină în legătură cu contractul dintre acestea), va trebui să
                                prelucrăm date cu caracter personal care se referă la dumneavoastră. În acest caz, ne vom
                                întemeia prelucrările pe interesele noastre legitime de a putea executa contractele pe care
                                le-am încheiat cu alte companii și de a ne asigura că celelalte companii execută, la rândul
                                lor, acele contracte. Vom prelucra în principal următoarele date care se referă la
                                dumneavoastră: nume, prenume, poziție, angajator, număr de telefon.
                                </p></li>
                            <li>
                                <p><strong>Comunicare în scop de marketing.</strong> Pentru a vă trimite comunicări și
                                    oferte cu privire la produsele, serviciile și promoțiile noastre, este necesar să
                                    prelucrăm datele dumneavoastră cu caracter personal. În acest caz, prelucrarea datelor
                                    dumneavoastră va fi întemeiată pe consimțământul dumneavoastră de a primi astfel de
                                    comunicări de la noi sau, după caz, pe interesele noastre legitime, acolo unde în urma
                                    analizei noastre am ajuns la concluzia că interesele noastre legitime prevalează.</p>
                                <p>Vom prelucra în principal următoarele date care se referă la dumneavoastră: nume,
                                    prenume, număr de telefon, adresă de e-mail.</p>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li><p><strong><span>1.3.</span> Parteneri contractuali ai noștri – persoane fizice (parteneri de
                        afaceri, nu clienți)</strong></p>
                        <br />
                        <p>Atunci, putem prelucra datele dumneavoastră cu caracter personal pentru următoarele scopuri:</p>
                        <ol type="i">
                            <li>
                                <p><strong>Pentru a putea desfășura relații de afaceri cu dumneavoastră.</strong> Este
                                    necesar să prelucrăm anumite informații care se referă la dumneavoastră pentru a putea
                                    începe și menține colaborarea cu dumneavoastră. </p>
                                <p>În acest caz, ne vom baza prelucrarea datelor care se referă la dumneavoastră pe
                                    necesitatea acesteia pentru încheierea / executarea contractului dintre dumneavoastră și
                                    noi.</p>
                                <p>Vom prelucra în principal următoarele date care se referă la dumneavoastră: nume,
                                    prenume, date din actul de identitate.</p>
                            </li>
                            <li>
                                <p><strong>Soluționarea cererilor dumneavoastră.</strong> Vom utiliza datele dumneavoastră
                                    pentru a răspunde eventualelor solicitări, cerințe, reclamații, sau oricăror altor
                                    întrebări pe care ni le transmiteți în cadrul relației cu dumneavoastră.</p>
                                <p>În acest caz, temeiul prelucrărilor va fi executarea contractului cu dumneavoastră sau,
                                    după caz, consimțământul dumneavoastră.</p>
                                <p>Vom folosi în principal numele, prenumele dumneavoastră, datele dumneavoastră de contact
                                    (adresă de e-mail, număr de telefon) și informații cuprinse în cererea pe care ne-o
                                    transmiteți.</p>
                            </li>
                            <li>
                                <p><strong>Comunicare în scop de marketing.</strong> Pentru a vă trimite comunicări și
                                    oferte cu privire la produsele, serviciile și promoțiile noastre, este necesar să
                                    prelucrăm datele dumneavoastră cu caracter personal. În acest caz, prelucrarea datelor
                                    dumneavoastră va fi întemeiată pe consimțământul dumneavoastră de a primi astfel de
                                    comunicări de la noi sau, după caz, pe interesele noastre legitime, acolo unde în urma
                                    analizei noastre am ajuns la concluzia că interesele noastre legitime prevalează. </p>
                                <p>Vom prelucra în principal următoarele date care se referă la dumneavoastră: nume,
                                    prenume, număr de telefon, adresă de e-mail.</p>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li><p><strong><span>1.4.</span> Membri ai autorităților publice</strong></p>
                        <br />
                        <p>Atunci, vom utiliza datele dumneavoastră cu caracter personal pentru <strong>îndeplinirea
                            obligațiilor noastre legale</strong>, cum ar fi răspunsul la solicitările unor autorități,
                            ținerea de registre prevăzute de lege și altele asemenea.</p>
                        <p>Spre exemplu, va trebui să păstrăm numele, prenumele, autoritatea din care faceți parte și
                            semnătura dumneavoastră în registrele de control pe care legea ne obligă să le ținem. De
                            asemenea, dacă ne transmiteți o solicitare de informații din partea autorității – angajatorul
                            dumneavoastră, vă vom prelucra datele (nume, prenume, poziție, angajator) pentru a răspunde.</p>
                    </li>
                    <br />
                    <li><p><strong><span>1.5.</span> Dacă sunteți un vizitator al sediului, al punctelor de lucru sau al
                        altor incinte ale noastre</strong></p>
                        <br />
                        <p>Atunci, e posibil să folosim datele dumneavoastră cu caracter personal pentru a asigura <strong>securitatea
                            persoanelor și a obiectelor</strong>.</p>
                        <p>La recepție vom prelucra datele dumneavoastră cu caracter personal incluse într-un act de
                            identitate valabil, precum și informații privind scopul vizitei, tot în vederea asigurării
                            securității persoanelor și a obiectelor din incintele noastre, în temeiul interesului nostru
                            legitim.</p>
                    </li>
                    <br />
                    <li><p><strong><span>1.6.</span> Dacă sunteți un vizitator al site-urilor noastre sau ați interacționat
                        cu vreuna dintre paginile noastre pe rețelele de socializare</strong></p>
                        <br />
                        <p>Atunci, e posibil să folosim datele dumneavoastră cu caracter personal pentru următoarele
                            scopuri:</p>
                        <ol type="i">
                            <li>
                                <p><strong>Îmbunătăţirea experienţei pe website-ul nostru.</strong> Pentru a putea ține cont
                                    de preferințele pe care le-ați exprimat în sesiuni de navigare anterioare, pentru a
                                    adapta website-ul nostru la dispozitivul pe care îl folosiți, pentru a soluționa
                                    problemele pe care le-ați putea întâmpina la accesare, prelucrăm date precum: adresa IP;
                                    identificatori cookies; alţi identificatori online; identificator unic de dispozitiv (ID
                                    universal unic – UUID); data şi ora accesării website-ului; istoricul vizitelor;
                                    solicitarea web; data şi ora solicitării/ data și ora accesării website-ului;
                                    dispozitivul de pe care accesați website-ul; tipul de browser de Internet şi limba
                                    browser-ului; informaţii despre evenimentele survenite pe dispozitivul dumneavoastră (de
                                    exemplu, erorile); informaţii cu privire la setările hardware ale dispozitivului
                                    dumneavoastră; informații despre locul în care vă aflați când accesați website-ul
                                    nostru.</p>

                                <p>Temeiul prelucrării va fi în cele mai multe cazuri consimțământul dumneavoastră sau
                                    interesul nostru legitim.</p>
                            </li>

                            <li>
                                <p><strong>Gestionarea sistemelor noastre de comunicații şi IT și protecția
                                    acestora.</strong> Putem prelucra datele dumneavoastră pentru: gestionarea sistemelor
                                    noastre de comunicații; gestionarea securităţii noastre IT; realizarea auditurilor de
                                    securitate asupra reţelelor noastre IT; protejarea datelor şi a sistemelor noastre
                                    împotriva atacurilor şi a altor acte similare în mediul virtual. </p>
                                <p>Vom prelucra în principal următoarele date care se referă la dumneavoastră: nume,
                                    prenume, adresă de e-mail, număr de telefon.</p>
                                <p>În acest caz, ne vom întemeia prelucrarea pe interesele noastre legitime sau, după caz,
                                    îndeplinirea obligațiilor noastre legale.</p>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li><p><strong><span>1.7.</span> Dacă ați trimis un review în legătură cu produsele noastre sau v-ați
                        înscris în campaniile noastre</strong></p>
                        <br />
                        <p>Atunci este posibil să folosim datele dumneavoastră în scopul promovării companiei și a
                            proiectelor acesteia, inclusiv prin intermediul folosirii testimonialelor furnizate.</p>
                        <p>În aceste cazuri, prelucrarea se face pe baza consimțământului acordat de dumneavoastră. Datele
                            ce vor fi prelucrate pot fi, după caz: nume, prenume, poză, video (în cazul testimonialelor
                            acordate pe această cale).</p>
                    </li>
                    <br />
                    <li><p><strong><span>1.8.</span> În general, în orice poziție vă aflați</strong></p>
                        <br />
                        <p>Putem, de asemenea, să prelucrăm datele dumneavoastră pentru următoarele scopuri:</p>
                        <ol type="i">
                            <li>
                                <p><strong>Soluționarea cererilor dumneavoastră.</strong> Vom utiliza datele dumneavoastră
                                    pentru a răspunde eventualelor solicitări, cerințe, reclamații, sau oricăror altor
                                    întrebări pe care ni le adresați.</p>
                                <p>Vom folosi în principal numele, prenumele dumneavoastră, datele dumneavoastră de contact
                                    (adresă de e-mail, număr de telefon) și informații cuprinse în cererea pe care ne-o
                                    transmiteți.</p>
                                <p>În acest caz, temeiul prelucrărilor va fi executarea contractului cu dumneavoastră (dacă
                                    există) sau, după caz, consimțământul dumneavoastră.</p>
                            </li>
                            <li><p><strong>Răspuns la solicitările autorităților sau prelucrarea datelor în alte cazuri în
                                care legea ne obligă.</strong> Este posibil ca uneori să avem o obligație legală de a
                                comunica datele dumneavoastră către anumite autorități, de a stoca datele dumneavoastră pe o
                                anumită perioadă sau de a vă prelucra într-un alt mod datele. În acest caz, temeiul
                                prelucrării va fi îndeplinirea unei obligații legale ale noastre.</p></li>
                            <li><p><strong>Realizarea unor tranzacții, restructurări sau a altor operațiuni.</strong> În
                                contextul unor tranzacții, este posibil să divulgăm datele dumneavoastră către potențiali
                                achizitori sau consultanți ai acestora ori autorități, deși vom încerca să reducem pe cât
                                posibil aceasta. În acest caz, prelucrarea va avea loc în temeiul intereselor noastre
                                legitime sau al îndeplinirii unor obligații legale.</p></li>
                            <li><p><strong>Apărarea drepturilor și a intereselor noastre sau ale altor persoane.</strong>
                                Este posibil să prelucrăm datele dumneavoastră pentru constatarea, exercitarea sau apărarea
                                drepturilor sau intereselor noastre sau ale altor persoane înaintea instanțelor de judecată,
                                a executorilor judecătorești, a notarilor publici, a altor autorități publice, a
                                tribunalelor arbitrale, a mediatorilor sau a altor organisme publice sau private care
                                soluționează dispute, a avocaților, consultanților noștri (cum ar fi auditori ori experți
                                sau specialiști) sau ale altor persoane fizice sau juridice, publice sau private, care sunt
                                implicate în acele dispute. În această situație, vom prelucra datele dumneavoastră cu
                                caracter personal, după caz, în temeiul îndeplinirii unor obligații legale care ne revin sau
                                al intereselor noastre legitime.</p></li>
                            <li><p><strong>Prevenirea fraudelor.</strong> Suntem interesați ca activitatea noastră să se
                                desfășoare în condiții de legalitate. De aceea, putem prelucra datele dumneavoastră (cum ar
                                fi prin transmiterea acestor date către consultanții noștri din diverse domenii – auditori,
                                avocați etc. ori prin consultarea acestor date). În aceste cazuri, prelucrarea va fi
                                justificată de interesele noastre legitime de a preveni fraudele și alte nelegalități în
                                activitatea noastră ori, după caz, de obligațiile noastre legale de a asigura legalitatea
                                operațiunilor noastre (cum ar fi obligații impuse prin legislația în domeniul prevenirii
                                spălării banilor).</p></li>
                        </ol>
                    </li>
                    <br />
                    <li><p><strong><span>1.9.</span> Despre datele terților</strong></p>
                        <br />
                        <p>Dacă ne transmiteți date cu caracter personal privitoare la alte persoane (de exemplu,
                            reprezentanții dumneavoastră, membrii dumneavoastră de familie, persoanele aflate în
                            întreținerea dumneavoastră etc.), trebuie să vă asigurați că i-ați informat despre aceasta și că
                            i-ați îndrumat spre această informare cu privire la modul în care INTRASON S.R.L.
                            prelucrează datele cu caracter personal. </p>
                        <p>Vom informa respectivele persoane în mod corespunzător despre modul în care le prelucrăm datele,
                            atunci când este cazul.</p>
                    </li>
                </ol>
            </li>
            <br />
            <li><p><strong>CUI VOM DIVULGA DATELE CU CARACTER PERSONAL REFERITOARE LA DUMNEAVOASTRĂ</strong></p>
                <br />
                <p>Ca regulă, nu vom divulga datele dumneavoastră către alte persoane fizice sau juridice. </p>
                <p>Limităm accesul la date pentru persoanele din afara companiei INTRASON S.R.L. dar în anumite
                    cazuri este posibil să fie nevoie să divulgăm datele dumneavoastră, potrivit celor de mai jos.</p>
                <p>Putem divulga datele dumneavoastră către alte companii sau persoane fizice, cum ar fi: persoane fizice
                    sau juridice care acționează ca persoane împuternicite pentru INTRASON S.R.L. în diverse domenii
                    (cum ar fi arhivarea de documente, distrugerea de documente, sau stocarea de date, servicii de plată,
                    diverse servicii pe care le putem externaliza, cum ar fi în domeniul resurselor umane), alte persoane,
                    instanțe, autorități. În aceste cazuri, vom divulga datele pentru motive legitime legate de activitatea
                    noastră, cum ar fi asigurarea posibilității noastre de a asigura siguranța documentelor, degrevarea
                    activității noastre, constatarea, apărarea și exercitarea drepturilor sau intereselor noastre sau ale
                    unei alte persoane. INTRASON S.R.L. folosește serviciile de hosting oferite de A2 Hosting, ce
                    găzduiește baza noastră de date.</p>
                <p>De asemenea, după cum am precizat mai sus, în anumite cazuri este posibil să avem o obligație legală de a
                    divulga datele către autorități publice sau alte persoane fizice sau juridice.</p>
                <p>În toate aceste cazuri ne vom asigura că destinatarii datelor cu caracter personal referitoare la
                    dumneavoastră le prelucrează în condiții de securitate și confidențialitate, în conformitate cu scopul
                    pentru care le-am transmis și cu respectarea drepturilor dumneavoastră.</p>
            </li>
            <br />
            <li><p><strong>ÎN CE CONDIȚII AM PUTEA TRANSFERA DATELE DUMNEAVOASTRĂ CĂTRE STATE TERȚE SAU ORGANIZAȚII
                INTERNAȚIONALE</strong></p>
                <br />
                <p>În acest moment nu transferăm și nu intenționăm să transferăm datele dumneavoastră cu caracter personal
                    sau o parte dintre acestea către alte companii, organizații sau persoane din state terțe sau către
                    organizații internaționale.</p>
                <p>Dacă va fi necesar să transferăm datele către vreuna dintre destinațiile de mai sus, vă vom informa în
                    avans despre decizia noastră, acordându-vă timpul necesar pentru a vă exercita drepturile în legătură cu
                    transferul datelor dumneavoastră.</p>
            </li>
            <br />
            <li><p><strong>CAT VOM PĂSTRA DATELE DUMNEAVOASTRĂ</strong></p>
                <br />
                <p>Vom stoca datele dumneavoastră în conformitate cu politica noastră de stocare a datelor cu caracter
                    personal, care atribuie o perioadă de stocare în funcție de scopul prelucrării și categoria de date
                    prelucrate.</p>
                <p>Perioadele respective sunt bazate pe prevederile legale (în special din domeniul protecției datelor cu
                    caracter personal), având în vedere, de asemenea, obligațiile de stocare a unor anumite date, termenele
                    de prescripție aplicabile, practicile recomandate în materie și scopurile activității noastre.</p>
            </li>
            <br />
            <li><p><strong>CE SE POATE ÎNTAMPLA DACĂ NU NE FURNIZAȚI DATELE</strong></p>
                <br />
                <p>În cele mai multe cazuri, nu aveți o obligație de a ne furniza datele dumneavoastră cu caracter
                    personal.</p>
                <p>Totuși, dacă nu ne furnizați datele solicitate, nu vom putea, de exemplu, să încheiem sau să negociem un
                    contract cu dumneavoastră, să vă vindem produsele sau să vă prestăm serviciile noastre, să vă permitem
                    acces la toate opțiunile de pe website-ul nostru, să vă răspundem la reclamanții sau solicitări ori să
                    vă trimitem comunicări cu privire la produsele, serviciile și promoțiile noastre care v-ar putea
                    interesa.</p>
            </li>
            <br />
            <li><p><strong>INEXISTENȚA UNUI PROCES DECIZIONAL AUTOMATIZAT</strong></p>
                <br />
                <p>Nu luăm decizii bazate exclusiv pe prelucrarea automată a datelor dumneavoastră (inclusiv crearea de
                    profiluri) care să producă efecte juridice cu privire la dumneavoastră sau care să vă afecteze într-un
                    mod similar într-o măsură semnificativă.</p>
            </li>
            <br />
            <li><p><strong>DREPTURILE DUMNEAVOASTRĂ ȘI MODUL DE EXERCITARE</strong></p>
                <br />
                <p><strong><u>Drepturile dumneavoastră</u></strong></p>
                <p>Aveți următoarele drepturi:</p>
                <ul>
                    <li><p><strong>Dreptul de acces la date.</strong> Aveți dreptul de a obţine accesul la datele privitoare
                        la dumneavoastră pe care le prelucrăm sau le controlăm sau la copii ale acestora; aveți, de
                        asemenea, dreptul de a obţine de la noi informaţii cu privire la natura, prelucrarea şi divulgarea
                        acestor date.</p></li>
                    <li><p><strong>Dreptul la rectificarea datelor.</strong> Aveți dreptul de a obţine rectificarea
                        inexactităţilor datelor privitoare la dumneavoastră pe care le prelucrăm sau le controlăm.</p></li>
                    <li><p><strong>Dreptul la ştergerea datelor („dreptul de a fi uitat”).</strong> Aveți dreptul de a
                        obţine de la noi ştergerea datelor privitoare la dumneavoastră pe care le prelucrăm sau le
                        controlăm.</p></li>
                    <li><p><strong>Dreptul la restricţionarea prelucrării datelor.</strong> Aveți dreptul de a restricţiona
                        prelucrarea datelor privitoare la dumneavoastră pe care le prelucrăm sau le controlăm.</p></li>
                    <li><p><strong>Dreptul de a obiecta.</strong> Aveți dreptul de a obiecta la prelucrarea datelor
                        privitoare la dumneavoastră de către noi sau în numele nostru.</p></li>
                    <li><p><strong>Dreptul la portabilitatea datelor.</strong> Aveți dreptul de a obţine transferul către un
                        alt operator al datelor privitoare la dumneavoastră pe care le prelucrăm sau le controlăm.</p></li>
                    <li><p><strong>Dreptul la retragerea consimţământului.</strong> În situaţiile în care prelucrăm datele
                        privitoare la dumneavoastră în temeiul consimţământului dumneavoastră, aveți dreptul de a vă retrage
                        consimţământul; puteți face aceasta în orice moment, cel puţin la fel de uşor cum ne-ați acordat
                        iniţial consimţământul; retragerea consimțământului nu va afecta legalitatea prelucrării datelor
                        privitoare la dumneavoastră pe care am realizat-o înainte de retragere.</p></li>
                    <li><p><strong>Dreptul de a depune o plângere la autoritatea de supraveghere.</strong> Aveți dreptul de
                        a depune o plângere la autoritatea de supraveghere a prelucrării datelor cu caracter personal cu
                        privire la prelucrarea datelor dumneavoastră de către noi sau în numele nostru. În România, această
                        autoritate este Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal
                        (ANSPDCP).</p></li>
                </ul>
                <br />
                <p><strong><u>Cum vă puteți exercita drepturile</u></strong></p>
                <p>Pentru a exercita unul sau mai multe dintre aceste drepturi sau pentru a adresa orice întrebare despre
                    oricare dintre aceste drepturi sau orice prevedere din această informare sau despre oricare alte aspecte
                    ale prelucrării datelor privitoare la dumneavoastră de către noi, vă rugăm să utilizați detaliile de
                    contact din secțiunea DATELE NOASTRE DE CONTACT, de mai jos.</p>
            </li>
            <br />
            <li><p><strong>DATELE NOASTRE DE CONTACT</strong></p>
                <br />
                <p>Ne puteți contacta la detaliile de mai jos.</p>
                <p><strong><u>Datele noastre de contact</u></strong></p>
                <p>Denumire completă: INTRASON SRL<br />
                    Adresă corespondență: Brașov, Bd. Saturn, nr. 23/B/13, jud Brașov<br />
                    Adresă de e-mail: office@intrastat-expert.ro // programintrastat@gmail.com<br />
                    Website: www.intrastat-expert.ro</p>


            </li>
            <br />
            <li><p><strong>CAND SE APLICĂ ACEASTĂ INFORMARE</strong></p>
                <br />
                <p>Această informare generală se aplică în legătură cu prelucrarea datelor referitoare la dumneavoastră
                    către compania INTRASON S.R.L.</p>
                <p>Această informare nu se aplică în legătură cu servicii sau produse oferite de alte companii sau persoane
                    fizice, inclusiv cele postate pe website-urile noastre sau despre care vă informăm în alt mod. De
                    asemenea, această informare nu acoperă activitatea altor companii sau persoane care fac publicitate
                    serviciilor sau produselor noastre ori care vă prelucrează datele în alt mod prin website-urile noastre
                    sau pe rețelele de socializare în legătură cu paginile noastre.</p>
            </li>
            <br />
            <li><p><strong>MODIFICĂRILE ACESTEI POLITICI</strong></p>
                <br />
                <p>Este posibil să modificăm această politică. În astfel de cazuri, vă vom informa în avans, prin postarea
                    acestei politici pe website cu 10 de zile înainte de intrarea sa în vigoare.</p>
                <p>Vom posta această notă de informare și pe website-ul nostru</p>
            </li>
            <br />
            <li><p><strong>CE SEMNIFICĂ TERMENII PE CARE I-AM FOLOSIT ÎN ACEASTĂ NOTĂ</strong></p>
                <ul>
                    <li><p><strong>Autoritatea de supraveghere a prelucrării datelor cu caracter personal</strong>: o
                        autoritate publică independentă care, potrivit legii, are atribuţii referitoare la supravegherea
                        respectării legislaţiei protecţiei datelor cu caracter personal. În România, această autoritatea de
                        supraveghere a prelucrării datelor cu caracter personal este Autoritatea Naţională de Supraveghere a
                        Prelucrării Datelor cu Caracter Personal (ANSPDCP).</p></li>
                    <li><p><strong>Categorii speciale de date cu caracter personal (date cu caracter personal sensibile/
                        date sensibile)</strong>: datele cu caracter personal care: dezvăluie originea rasială sau etnică,
                        opiniile politice, confesiunea religioasă sau convingerile filozofice sau apartenența la sindicate;
                        datele genetice; datele biometrice pentru identificarea unică a unei persoane fizice; datele privind
                        sănătatea, viața sexuală sau orientarea sexuală a unei persoane fizice.</p></li>
                    <li><p><strong>Date cu caracter personal</strong>: orice informații privind o persoană fizică
                        identificată sau identificabilă (denumită „persoană vizată”). O persoană fizică este identificabilă
                        dacă poate fi identificată, direct sau indirect, în special prin referire la un element de
                        identificare, de exemplu: nume, număr de identificare, date de localizare, identificator online,
                        unul/mai multe elemente specifice, proprii identității fizice, fiziologice, genetice, psihice,
                        economice, culturale sau sociale a respectivei persoane. Astfel, de exemplu, în noțiunea de date cu
                        caracter personal sunt incluse următoarele: numele și prenumele; adresa de domiciliu sau de
                        reședință; adresa de e-mail; numărul de telefon; codul numeric personal (CNP); serviciile medicale
                        accesate (sunt date sensibile); diagnosticul stabilit (sunt date sensibile); date genetice (sunt
                        date sensibile); date bimoetrice (sunt date sensibile); date de geolocație. Categoriile de date cu
                        caracter personal referitoare la dumneavoastră pe care le prelucrăm sunt enumerate mai sus.</p></li>
                    <li><p><strong>Operator</strong>: persoana fizică sau juridică care decide de ce (în ce scop) şi cum
                        (prin ce mijloace) sunt prelucrate datele cu caracter personal. Potrivit legii, răspunderea pentru
                        respectarea legislaţiei referitoare la datele cu caracter personal revine în primul rând
                        operatorului. În relația cu dumneavoastră, noi suntem operatorul, iar dumneavoastră sunteți persoana
                        vizată.</p></li>
                    <li><p><strong>Persoană împuternicită</strong>: orice persoană fizică sau juridică care prelucrează date
                        cu caracter personal în numele operatorului, alta decât angajaţii operatorului.</p></li>
                    <li><p><strong>Persoană vizată</strong>: persoana fizică la care se referă (căreia îi „aparțin”) anumite
                        date cu caracter personal. În relația cu noi (operatorul), dumneavoastră sunteți persoana vizată.
                    </p></li>
                    <li><p><strong>Prelucrare a datelor cu caracter personal</strong>: orice operațiune/set de operațiuni
                        efectuată/efectuate asupra datelor cu caracter personal sau asupra seturilor de date cu caracter
                        personal, cu sau fără utilizarea mijloacelor automatizate; de exemplu: colectarea, înregistrarea,
                        organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea, consultarea, utilizarea,
                        divulgarea prin transmitere, diseminarea sau punerea la dispoziție în orice alt mod, alinierea sau
                        combinarea, restricționarea, ștergerea sau distrugerea respectivelor date cu caracter
                        personal/seturi de date cu caracter personal. Acestea sunt doar exemple. Practic, prelucrarea
                        înseamnă orice operaţiune asupra datelor cu caracter personal, indiferent dacă prin mijloace
                        automate sau manuale.</p></li>
                    <li><p><strong>Stat terț</strong>: un stat din afara Uniunii Europene și a Spațiului Economic European.
                    </p></li>
                </ul>
            </li>
        </ol>
        </div>
    </div>
  )
}

export default Policy