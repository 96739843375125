///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, {useState} from 'react'

const Popup = ({data, close, children}) => {

    const {title, text, type, func} = data

    const [valStatArr, setValStatArr] = useState(type === 1 ? data.valStatArr : [])

    const handleValStatSave = async (action) => {
        action ? func(valStatArr) : setValStatArr(data.valStatArr)
        close()
    }

    const OUTSIDE_ID = 'modal-popup-wrapper-bg'

    const handleOutsideClick = (id) => {
        if(id === OUTSIDE_ID) close()
    }

    const [formData, setFormData] = useState(type === 2 ? {
        cName:  data.comp.cif,
        reprez: data.uData.name,
        pos:    'Reprezentant',
        mail:   data.uData.email,
        phone:  data.uData.phone,
        tName:  data.uData.cui,
        linesLeft: data.linesLeft
    } : {})

    const returnContent = () => {
        switch(type) {
            // sure exit?
            case 0: {
                return(
                    <>
                    <div className='modal-body-wrapper'>
                        {text}
                    </div>
                    <div className='modal-bottom'>
                        <div className='button-secondary' onClick={() => func(true)}>Da</div>
                        <div className='button-secondary selected-btn' onClick={() => func(false)}>Nu</div>
                    </div>
                    </>
                )
            } 
            // val stat
            case 1: {
                const tableHeaders = [
                    'Cond. livrare', 
                    'Mod transport', 
                    'Nat. tranzacție',
                    'Țara',
                    'Semn',
                    'Procent(%)'
                ]

                const handleChange = (val, bigI, smallI) => {
                    let toChange = valStatArr
                    toChange[bigI][smallI] = val.toString() === '' ? 0 : val.toString()
                    setValStatArr(toChange)
                }

                return(
                    <>
                    <div className='popup-table'>
                        <div className='res-table-section'>
                            <div className='res-table-row header-cell'>
                                {
                                    tableHeaders.map((value, index) => {
                                        return(
                                        <input type="text" key={`${value} - ${index}`}
                                            disabled={true} 
                                            readOnly={true}
                                            defaultValue = {value} 
                                            className = 'res-table-input'
                                            style={{width:`8rem`}}
                                        />
                                        )
                                    })
                                }
                            </div>
                            {
                            valStatArr.map((value, index) => {
                                return(
                                    <div className='res-table-row rows'
                                    key={`${value} - ${index}`}>
                                        {
                                            value.map((val, i) => {
                                                if(i < 4) {
                                                    return(
                                                    <input type='text'
                                                        key={`${val} - ${i} - ${index}`}
                                                        defaultValue={val}
                                                        disabled={true}
                                                        readOnly={true}
                                                        className={`res-table-input`}
                                                        style={{width: `8rem`}}
                                                    />
                                                    )
                                                }
                                                // sign
                                                else if(i === 4) {
                                                    return(
                                                    <select className='res-table-input'
                                                    key={`${val} - ${i} - ${index}`}
                                                    style={{width: `10.1rem`}} defaultValue={val}
                                                    onChange={e => handleChange(e.target.value, index, i)}>
                                                        <option value={'PLUS'}>{'PLUS'}</option>
                                                        <option value={'MINUS'}>{'MINUS'}</option>
                                                    </select>
                                                    )
                                                }
                                                // procent
                                                else {
                                                    return(
                                                    <input type='number' step={'0.0001'}
                                                        key={`${val} - ${i} - ${index}`}
                                                        defaultValue={parseFloat(val)}
                                                        onBlur={e => handleChange(e.target.value, index, i)}
                                                        className={`res-table-input`}
                                                        style={{width: `8rem`}}
                                                    />
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>

                    <div className='modal-bottom'>
                        <div className='button-secondary' 
                        onClick={() => handleValStatSave(false)}>Anulează</div>
                        <div className='button-secondary  selected-btn' 
                        onClick={() => handleValStatSave(true)}>Calculează</div>
                    </div>
                    </>
                )
            }
            // generate XML
            case 2: {
                const { tert, hasValStat, linesLeft, linesToGenerate, hasErr, exists } = data
                return(
                    <>
                    {!hasErr && 
                    <div className='modal-bottom'>
                        <div className='button-secondary' onClick={() => func(false)}>Anulare</div>
                        <div className='button-secondary selected-btn' 
                            onClick={() => func(1, formData, data.generatedArr)}>{exists ? 'Generează din nou' :'Generează'}
                        </div>
                    </div>
                    }
                    <div className='modal-body-wrapper'>
                        <div className='modal-form'>

                            <h3>Date pentru generare</h3>
                            {
                                !hasValStat &&
                                <div style={{color:'red'}}>Nu uitați să selectați "Calcul valoare statististică" în cazul în care aveți nevoie de acesta.</div>
                            }
                            <div className='modal-form-section'>
                                <p>Firmă</p>
                                <input className='input-text'  type='text' id='firmă'
                                disabled={true}
                                readOnly={true}
                                defaultValue={formData.cName} required/>
                            </div>
                            <div className='modal-form-section'>
                                <p>Persoană de contact</p>
                                <input className='input-text'  type='text' id='pers-contact'
                                value={formData.reprez} 
                                onChange={(e) => setFormData(prev => ({...prev, reprez:e.target.value}))} required/>
                            </div>
                            <div className='modal-form-section'>
                                <p>Poziție</p>
                                <input className='input-text'  type='text' id='poz'
                                value={formData.pos} 
                                onChange={(e) => setFormData(prev => ({...prev, pos:e.target.value}))} required/>
                            </div>
                            <div className='modal-form-section'>
                                <p>Email</p>
                                <input className='input-text'  type='email' id='email'
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                value={formData.mail} 
                                onChange={(e) => setFormData(prev => ({...prev, mail:e.target.value}))} required/>
                            </div>
                            <div className='modal-form-section'>
                                <p>Telefon</p>
                                <input className='input-text' type='tel' pattern="[0-9]{10,12}" 
                                id='phone'
                                value={formData.phone} 
                                onChange={(e) => setFormData(prev => ({...prev, phone:e.target.value}))} required/>
                            </div>
                            {tert && <div className='modal-form-section'>
                                <p>Declarant terț</p>
                                <input className='input-text' type='text' 
                                id='tert'
                                disabled={true}
                                readOnly={true}
                                defaultValue={formData.tName}  />
                            </div>}
                            {   !exists ? 
                                <>
                                    <div>Vor fi folosite <b>{linesToGenerate}</b> linii din abonament.</div>
                                    <div>Vă vor rămâne {linesLeft} linii în această lună pentru abonamentul actual.</div>
                                </> :
                                <>
                                    <div>Există deja un fișier generat. Îl puteți descărca aici:</div>
                                    <div className='button-secondary selected-btn' 
                                        onClick={() => func(2, formData, data.generatedArr)}>Descarcă
                                    </div>
                                </>
                            }
                            {hasErr && 
                                <>
                                <div style={{color:'red'}}>Fișierul nu poate fi generat cu erori!</div>
                                <div style={{color:'red'}}>
                                    Selectați 'Afișează doar erori' pentru a vedea erorile.
                                </div>
                                </>
                            }
                        </div>
                    </div>
                    {!hasErr && 
                    <div className='modal-bottom'>
                        <div className='button-secondary' onClick={() => func(false)}>Anulare</div>
                        <div className='button-secondary selected-btn' 
                            onClick={() => func(1, formData, data.generatedArr)}>{exists ? 'Generează din nou' :'Generează'}
                        </div>
                    </div>
                    }
                    </>
                )
            }
            default:
                break
        }
    }

    return (
        <div className='modal-wrapper' id={OUTSIDE_ID} onClick={(e) => handleOutsideClick(e.target.id)}>
            <div className='modal-content'>
                <div className='modal-header'>
                        <div>{title}</div>
                        <div className='button-secondary' onClick={() => close()}>Închide</div>
                </div>
                {type !== 3 ? returnContent() : children}
            </div>
        </div>
    )
}

export default Popup