import React from 'react'
import { useNavigate } from "react-router-dom";

const NotFountPage = () => {
    const navigate = useNavigate();

    return (
        <section className='not-found-container'>
            <div>
                <h2>Se pare că pagina pe care o căutați nu există..</h2>
                <div className="bear" />
                <div className='bear-space' />
                <h1 className='not-found-code'>4<span>0</span>4</h1>
                <div className='button-secondary' onClick={() => navigate('/')}>Înapoi la pagina principală</div>
            </div>
        </section>
    )
}

export default NotFountPage