///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

export const VAT_CHECK_COOKIE         = 'Npxm5QpQEzjvzh63sthi7JXDfH6ATLFp7hEazZdwCxYqCeVMQVLyHDf1LDG'
export const VAT_CHECK_COUNTER_COOKIE = 'neskwh9uXoQ8jtJBCXeCMCEBEZPyhZ4KGdzWoLbeKfMo0YhPbeu6xTQc9D4'
export const EXCHANGE_COOKIE          = 'QiWCi2RE5AdLWPNG6h1FnmtnQpRd82EgqLjuUfxstDdUJ9LcLg2bbnKMqqQ'
export const EXCHANGE_COUNTER_COOKIE  = 'imtAjD2wEPyYUxrbasR552QonziB30YEnwaon0Mjy6ptcyYxBGgPDHnmATj'
export const REGISTER_COOKIE          = 'xnqvCU6i3rUDj0vkK2gV8jbcqninMkNPZZKxuHDeNDUhGkoqgxfkEyQkawU'
export const BETA_TEST_COOKIE         = 'Ch7shdweifyAFme21V10YQFMKrB3sAdnFDpcFhHfcnbJqwj0u33N9NK2gNW'
export const EMAIL_VERIFY_COOKIE      = 'MNerYxA0HY9VM0sFgkUoCHp5X8pANQbDDZ6b886DTyL4nJFA0skVByk1x9h'
export const CONTACT_FORM_COOKIE      = 'kwQGtTnWNe8u5noicG4sZE9n9cjWQZ3ky8UcYZvVBeDGhq2k8L99c3Z1Bma'

export const setCookie = (cname, cvalue, exdays, days) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * (days ? 24 : 1) * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires;
};

export const setMinuteCookie = (cname, cvalue, min) => {
    const d = new Date();
    d.setTime(d.getTime() + (min * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires;
}

export const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
        }
    }
    return false;
};

export const checkCookie = (cname) => {
    let user = getCookie(cname);
    if (user !== false) {
        return true;
    } else {
        return false;
    }
};