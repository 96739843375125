///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import { getAnalytics } from "firebase/analytics";
import * as firebase from 'firebase/compat/app'
import "firebase/compat/firestore"
import "firebase/compat/auth"

const firebaseConfig = {
    apiKey:            "AIzaSyDXW51G-j1Zn2Cl3OCGkwpfuI_LYTCbzhI",
    authDomain:        "program-intrastat.firebaseapp.com",
    projectId:         "program-intrastat",
    storageBucket:     "program-intrastat.appspot.com",
    messagingSenderId: "269006460489",
    appId:             "1:269006460489:web:1fc780e04340616b979198",
    measurementId:     "G-YRWPRFR5K1"
};

// Initialize Firebase
const app = firebase.default.initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const auth = app.auth();
export const db = app.firestore();
export const signOutAccount = () => {
    auth.signOut();
}

export default app;