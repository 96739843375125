import React, { useEffect, useState } from "react";
import "./style.css";

import { useAuth } from "../../context/AuthContext";
import { createNotification } from "../../services/NotificationService";

const Tiriac = ({ goBack }) => {
  const [searchText, setSearchText] = useState("");
  const [carsData, setCarsData] = useState([]);
  const [filterCarsData, setFilterCarsData] = useState([]);
  const [newCarStep, setNewCarStep] = useState(0);
  const [newCar, setNewCar] = useState({});

  const { getCarsData, loadingAction, currentUser, newCarData } = useAuth();

  useEffect(() => {
    getCarsData().then((res) => {
      setCarsData(res);
      setFilterCarsData(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchBox = (text) => {
    if (text === "" || !text) return setFilterCarsData(carsData);
    setSearchText(text);
    text = text.trim().toLowerCase();
    const filterTextArray = text.split(" "); // Array with each search word

    setFilterCarsData(
      [...carsData].filter((car) =>
        filterTextArray.every((word) => car.name.toLowerCase().includes(word))
      )
    );
  };

  const calculateCnCode = (car) => {
    if (car.type === "Nou") {
      if (car.fuel === "Diesel") {
        if (car.engine_capacity === "0 - 1500") return "87033110";
        else if (car.engine_capacity === "1501 - 2500") return "87033219";
        else if (car.engine_capacity === "2500+") return "87033319";
      } else if (car.fuel === "Benzina") {
        if (car.engine_capacity === "0 - 1000") return "87032110";
        else if (car.engine_capacity === "1001 - 1500") return "87032210";
        else if (car.engine_capacity === "1501 - 3000") return "87032319";
        else if (car.engine_capacity === "3000+") return "87032410";
      } else if (car.fuel === "D + E") {
        if (car.engine_capacity === "Cu incarcare") return "87037000";
        else if (car.engine_capacity === "Fara incarcare") return "87035000";
      } else if (car.fuel === "B + E") {
        if (car.engine_capacity === "Cu incarcare") return "87036010";
        else if (car.engine_capacity === "Fara incarcare") return "87034010";
      } else if (car.fuel === "Electric") {
        return "87038010";
      }
    } else {
      if (car.fuel === "Diesel") {
        if (car.engine_capacity === "0 - 1500") return "87033190";
        else if (car.engine_capacity === "1501 - 2500") return "87033290";
        else if (car.engine_capacity === "2500+") return "87033390";
      } else if (car.fuel === "Benzina") {
        if (car.engine_capacity === "0 - 1000") return "87032190";
        else if (car.engine_capacity === "1001 - 1500") return "87032290";
        else if (car.engine_capacity === "1501 - 3000") return "87032390";
        else if (car.engine_capacity === "3000+") return "87032490";
      } else if (car.fuel === "D + E") {
        if (car.engine_capacity === "Cu incarcare") return "87037000";
        else if (car.engine_capacity === "Fara incarcare") return "87035000";
      } else if (car.fuel === "B + E") {
        if (car.engine_capacity === "Cu incarcare") return "87036090";
        else if (car.engine_capacity === "Fara incarcare") return "87034090";
      } else if (car.fuel === "Electric") {
        return "87038090";
      }
    }
  };

  const carStep = async (value) => {
    switch (newCarStep) {
      case 0:
        setNewCar({ type: value });
        setNewCarStep(newCarStep + 1);
        break;
      case 1:
        setNewCar((prev) => {
          const newCarObject = { ...prev };
          newCarObject.fuel = value;
          return newCarObject;
        });
        if (value === "Diesel") setNewCarStep(2);
        else if (value === "Benzina") setNewCarStep(3);
        else if (value === "D + E" || value === "B + E") setNewCarStep(4);
        else if (value === "Electric") {
          setNewCar((prev) => {
            const newCarObject = { ...prev };
            newCarObject.engine_capacity = null;
            newCarObject.added_by = currentUser.email;
            newCarObject.id = null;
            newCarObject.date =
              new Date().getFullYear() +
              "-" +
              (new Date().getMonth() + 1) +
              "-" +
              new Date().getDate();
            newCarObject.CN = calculateCnCode(newCarObject);
            return newCarObject;
          });
          setNewCarStep(6);
        }
        break;
      case 2:
      case 3:
      case 4:
        setNewCar((prev) => {
          const newCarObject = { ...prev };
          newCarObject.engine_capacity = value;
          newCarObject.added_by = currentUser.email;
          newCarObject.id = null;
          newCarObject.date =
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            new Date().getDate();
          newCarObject.CN = calculateCnCode(newCarObject);
          return newCarObject;
        });
        setNewCarStep(6); // Final step
        break;
      case 6: // Last step
        if (!newCar.weight)
          return createNotification("error", "Adăugați greutatea mașinii!");
        const newCarObject = { ...newCar };
        newCarObject.name = searchText;

        // Store in DB
        const response = await newCarData(newCarObject);
        if (!response) return;

        setSearchText("");
        const newCarsData = [...carsData, newCarObject];
        setCarsData(newCarsData);
        setFilterCarsData(newCarsData);
        setNewCarStep(0);
        break;
      default:
        break;
    }
  };

  const newCarSteps = [
    <>
      <div className="form-btns">
        <div onClick={() => carStep("Nou")}>Nou</div>
        <div onClick={() => carStep("Second")}>Second</div>
      </div>
    </>,
    <>
      <div className="form-btns">
        <div onClick={() => carStep("Diesel")}>Diesel</div>
        <div onClick={() => carStep("Benzina")}>Benzina</div>
        <div onClick={() => carStep("D + E")}>D + E</div>
        <div onClick={() => carStep("B + E")}>B + E</div>
        <div onClick={() => carStep("Electric")}>Electric</div>
      </div>
    </>,
    // Diesel - 2
    <>
      <div className="form-btns">
        <div onClick={() => carStep("0 - 1500")}>0 - 1500</div>
        <div onClick={() => carStep("1501 - 2500")}>1501 - 2500</div>
        <div onClick={() => carStep("2500+")}>2500+</div>
      </div>
    </>,
    // Benzina - 3
    <>
      <div className="form-btns">
        <div onClick={() => carStep("0 - 1000")}>0 - 1000</div>
        <div onClick={() => carStep("1001 - 1500")}>1001 - 1500</div>
        <div onClick={() => carStep("1501 - 3000")}>1501 - 3000</div>
        <div onClick={() => carStep("3000+")}>3000+</div>
      </div>
    </>,
    // Electric - 4
    <>
      <div className="form-btns">
        <div onClick={() => carStep("Cu incarcare")}>Cu incarcare</div>
        <div onClick={() => carStep("Fara incarcare")}>Fara incarcare</div>
      </div>
    </>,
    <></>, // 5 is empty!
    // End - 6
    <>
      <p>
        <b>Nume:</b> {searchText}
      </p>
      <p>
        <b>Tip:</b> {newCar.type}
      </p>
      <p>
        <b>Combustibil:</b> {newCar.fuel}
      </p>
      <p>
        <b>Motorizare:</b> {newCar.engine_capacity}
      </p>
      <p>
        <b>Cod CN:</b> {newCar.CN}
      </p>
      <p>
        <b>Greutate(kg):</b>{" "}
        <input
          type="number"
          onChange={(e) =>
            setNewCar((prev) => {
              const oldCar = { ...prev };
              oldCar.weight = e.target.value;
              return oldCar;
            })
          }
        />
      </p>
      <div className="button-secondary btn-green" onClick={carStep}>
        Adaugă
      </div>
      <div className="button-secondary" onClick={() => setNewCarStep(0)}>
        Anulează
      </div>
    </>,
  ];

  //   [
  //     {
  //         "id": 1,
  //         "name": "Skoda Octavia 2.0 TDI 150 CP DSG",
  //         "weight": 1277,
  //         "type": "Second",
  //         "CN": "87033290",
  //         "fuel": "Diesel",
  //         "engine_capacity": 1968,
  //         "date": "2023-12-13T00:00:00.000Z",
  //         "added_by": "g99.alex@yahoo.com"
  //     }
  // ]

  return (
    <div className="helper-wrapper">
      <div className="main-view">
        <div
          onClick={goBack}
          className="top-left-btn button-secondary btn-green"
        >
          Înapoi
        </div>
        <h2>Caută</h2>
        <input
          className="input-text wide-search"
          type="text"
          name="search"
          id="search"
          onChange={(e) => searchBox(e.target.value)}
        />
        <div></div>
        <div className="result-box-header">
          <div>Nume</div>
          <div>Comb.</div>
          <div>Motor (cc)</div>
          <div>KG</div>
          <div>Stare</div>
          <div>Cod CN</div>
          <div>Acțiuni</div>
        </div>
        <div className="result-box">
          {loadingAction ? ( // FETCHING CARS
            <h3>Se încarcă...</h3>
          ) : carsData.length === 0 ? ( // NO CARS
            <h3>Nu există înregistrări.</h3>
          ) : filterCarsData.length === 0 || newCarStep > 0 ? ( // NEW CAR FORM
            <div className="new-car-form">
              <h3>Intrare nouă</h3>
              {newCarSteps[newCarStep]}
            </div>
          ) : (
            // ALL THE CARS
            filterCarsData.map((car, i) => {
              return (
                <div key={i + car.id + car.name} className="result-item">
                  <div>{car.name}</div>
                  <div>{car.fuel}</div>
                  <div>{car.engine_capacity}</div>
                  <div>{car.weight}</div>
                  <div>{car.type}</div>
                  <div>{car.CN}</div>
                  <div></div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default Tiriac;
