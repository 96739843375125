///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React from 'react'
import packageJson from '../../package.json'

const Info = () => {
  return (
    <div className='terms-wrapper'>
        <h1>Informații</h1>
        <div className='info-wrapper'>
            <div className='info-pane'>
                <h3>Firmă</h3>
                <div>
                    <p>INTRASON SRL</p>
                    <a href='https://www.intrastat-expert.ro/' target='blank'>intrastat-expert.ro</a>
                    <p>J08/469/2002</p>
                    <p>RO14578650</p>
                    <p>Brașov, Bd. Saturn 23/B/13</p>
                </div>
            </div>

            <div className='info-pane'>
                <h3>Program</h3>
                <p>programintrastat.ro</p>
                <p>programintrastat@gmail.com</p>
                <p>Versiunea <b>{packageJson.version}</b></p>
            </div>
        </div>
    </div>
  )
}

export default Info