///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, {useState, useEffect, useRef} from 'react'
import { faSpinner, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { arrayFromDateDB, arrayFromDateString, toFormatDate } from '../../services/DateService';
import { splitVat, getXMLVat, vatUrlString } from '../../services/ValidationService';
import { downloadXmlFomString, getXMLGeneratedData } from '../../services/XMLService';
import { createNotification } from '../../services/NotificationService';
import { currentSubMonth } from '../../services/SubscriptionsService';
import { checkValueCodVamal } from '../../services/CodVamalService';
import { CoduriVamale, RefCodes } from '../../databases/CodVamal';
import { exportExcel } from '../../services/ExcelService';
import { useAuth } from '../../context/AuthContext';
// import { bigID } from '../../services/IdService';
import ModalSetValue from './ModalSetValue';
import Popup from '../Popup';
import { 
    VAT_CHECK_COUNTER_COOKIE, 
    VAT_CHECK_COOKIE, 
    setMinuteCookie, 
    checkCookie,
    getCookie 
} from '../../services/CookieService';
import { EuCountries, countryCodes } from '../../databases/CountryCodes';

const ExcelResultTable = ({data, actions}) => {
    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Limit of rows to be displayed per table page
    //-----------------------------------------------------------------------------------------
    const PER_PAGE = 50;

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Name like in database of header strings that are required
    //-----------------------------------------------------------------------------------------
    const REQUIRED_HEADERS = [
        'codVamal',          // 00
        'valoareFiscala',    // 01
        'greutateNeta',      // 02
        'conditieLivrare',   // 03
        'taraOrigine',       // 04
        'taraExpediere',     // 05
        'valoareStatistica', // 06
        'naturaTranzactiei', // 07
        'modTransport',      // 08
        'codUms',            // 09
        'valoareUms',        // 10
        // 'vat',            // 11
    ];

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Empty array used to fill the Ref to create multidimensional array
    //-----------------------------------------------------------------------------------------
    const emptyFillerArray = new Array(data.headers.length + REQUIRED_HEADERS.length + 1).fill([])

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Deconstruction of useAuth()
    //-----------------------------------------------------------------------------------------
    const {
        addInGeneratedHistory,
        newStatsGeneratedXml,
        getExchangeRates,
        updateUserLines,
        currentRates,
        currentUser,
        companies,
        userData,
        layouts,
    } = useAuth()

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? States for exchange action
    //-----------------------------------------------------------------------------------------
    const [checkedVats, setCheckedVats] = useState(new Set())
    const [errorVat, setErrorVat]       = useState(new Set())

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? States for loading to display for user
    //-----------------------------------------------------------------------------------------
    const [loadingAction, setLoadingAction] = useState(false)

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? States
    //-----------------------------------------------------------------------------------------
    const [hasErrors, setHasErrors]             = useState(false)
    const [errorCells, setErrorCells]           = useState([[]])
    const [errorIndexes, setErrorIndexes]       = useState(new Set())
    const [valStatArr, setValStatArr]           = useState([])
    const [checker, setChecker]                 = useState({...actions, valStat: false, showErr: false})
    const [modal, setModal]                     = useState(false);
    const [popupOn, setPopupOn]                 = useState(false);
    const [popupData, setPopupData]             = useState(false);
    const [modalValues, setModalValues]         = useState({});
    const [loading, setLoading]                 = useState(true);
    const [page, setPage]                       = useState(0);
    const [reqHeadersIndex, setReqHeadersIndex] = useState(new Set());
    const [verifyCell, setVerifyCell]           = useState([]);
    const [additionIndex, setAdditionIndex]     = useState({});
    const [headerStrings, setHeaderStrings]     = useState([]);
    const [footerStrings, setFooterStrings]     = useState([]);
    const [tableData, setTableData]             = useState({
        lines: 0,
        headers: data.headers,
        totalFisc: 0,
        totalStat: 0,
        totalNetW: 0,
        width: [],
        active: [],
        values: [],
        types: []
    });

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Refs used for changing values displayed for user in table
    //-----------------------------------------------------------------------------------------
    const SelectorRefs = useRef(
        emptyFillerArray.map(() => {
            let headerData = []
            data.sheetValues.forEach(val => {
                let toAdd = ''
                headerData.push(toAdd)
            })
            return headerData
        })
    )

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Populate the states with the required columns nr
    //-----------------------------------------------------------------------------------------
    const populateReqHeaders = async () => {
        let newReqHeaders = REQUIRED_HEADERS;
        layouts[data.layout].type === 'livrari' && newReqHeaders.push('vat') // 9
        let reqIndex = new Set()
        // Same indexes as newReqHeaders
        let verifyIndex = []
        let available = new Set()
        for (const [key, value] of Object.entries(layouts[data.layout].data)) {
            if(key.slice(-4) !== 'Impl') {
                if(value !== false && value !== 'false' && value !== '') {
                    if(newReqHeaders.includes(key.toString())) {
                        available.add(key.toString())
                        reqIndex.add(parseInt(value))
                        verifyIndex[newReqHeaders.indexOf(key.toString())] = parseInt(value)
                    }
                }
            }
        }
        const missing = newReqHeaders.filter(val => !available.has(val))
        setReqHeadersIndex(reqIndex)
        setVerifyCell(verifyIndex)
        await new Promise(r => setTimeout(r, 150));
        return {missing, verifyIndex, reqIndex}
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Checks if header is required by index
    //-----------------------------------------------------------------------------------------
    const isHeaderRequired = (index) => {
        return reqHeadersIndex.has(index)
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Returns string with default value from layout
    //-----------------------------------------------------------------------------------------
    const getLayoutDefaultData = (index, keyName = '') => {
        let dataToGet = ''
        let valueToReturn = ''
        const suffix = 'Impl'
        if(keyName === '') {
            for (const [key, value] of Object.entries(layouts[data.layout].data)) {
                if(value.toString() === index.toString()) {
                    dataToGet = key.toString() + suffix
                    break
                }
            }
        }
        else dataToGet = `${keyName}${suffix}`

        for (const [key, value] of Object.entries(layouts[data.layout].data)) {
            if(dataToGet === key.toString()) {
                valueToReturn = value.toString() !== 'false' ? value.toString() : ''
                break
            }
        }

        return valueToReturn.toString()
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Exchanges values to RON if needed
    //-----------------------------------------------------------------------------------------
    const checkExchange = async () => {
        if (loading) return
        await new Promise(r => setTimeout(r, 100));
        let exchIndex = layouts[data.layout].data['valoareFiscala']
        if(exchIndex === 'false' || exchIndex === '') exchIndex = verifyCell[1]
        const needsExchange = []
        let gotFromDb = true;

        if(!currentRates.dataId) gotFromDb = false

        // curs valutar
        let rateIndex = layouts[data.layout].data['cursDeSchimb']
        if(rateIndex === '' || rateIndex === 'false') rateIndex = false
        let rateIndexImpl = layouts[data.layout].data['cursDeSchimbImpl']

        // valoare valuta
        let currValueIndex = layouts[data.layout].data['valoareValuta']
        if(currValueIndex === '' || currValueIndex === 'false') currValueIndex = false
        let currValueIndexImpl = layouts[data.layout].data['valoareValutaImpl']

        // data tranzactie
        let dateIndex = layouts[data.layout].data['dataTranzactiei']
        if(dateIndex === '' || dateIndex === 'false') dateIndex = false
        let dateIndexImpl = layouts[data.layout].data['dataTranzactieiImpl']

        // moneda
        let currIndex = layouts[data.layout].data['moneda']
        if(currIndex === '' || currIndex === 'false') currIndex = false
        let currIndexImpl = layouts[data.layout].data['monedaImpl']

        tableData.values[exchIndex].forEach((value, index) => {
            // needs exchange
            if(value === 0 || value === '' || value === '0') {
                needsExchange.push(index)
            }
        })
        // no need for exchange
        if (needsExchange.length === 0) return
        // missing valoare valuta
        if (currValueIndex === false && currValueIndexImpl === '') {
            createNotification('error', `Nu se poate calcula valoarea fiscală din cauza lipsei câmpului "Valoare valută" din machetă`)
            return
        }

        let rest = new Array(...needsExchange)
        // exchange with rate given from user
        if (rateIndex !== false || rateIndexImpl !== '') {
            for (let i = 0; i < needsExchange.length; i++) {
                const rateNow = rateIndex === false ? rateIndexImpl : tableData.values[rateIndex][needsExchange[i]]
                if (rateNow !== '') {
                    const toMultiply = currValueIndex === false ? currValueIndexImpl : tableData.values[currValueIndex][needsExchange[i]]
                    if (toMultiply !== '') {
                        const retVal = (parseFloat(rateNow) * parseFloat(toMultiply)).toFixed(2)
                        SelectorRefs.current[exchIndex][needsExchange[i]].value = retVal
                        handleCellChange(exchIndex, needsExchange[i], retVal)
                        rest.splice(rest.indexOf(needsExchange[i]), 1)
                    }
                }
            }
        }
        // exchange with rates from database
        if (dateIndex === false && dateIndexImpl === '') {
            createNotification('error', `Nu se poate calcula valoarea fiscală din cauza lipsei datei tranzacției`)
            return
        }
        if (currIndex === false && currIndexImpl === '') {
            createNotification('error', `Nu se poate calcula valoarea fiscală din cauza lipsei monedei tranzacției`)
            return
        }

        let rates = {}
        // how many times the db is called
        let dbCallCounter = 0
        const DB_CALL_ALLOWED = 3
        if (dateIndex !== false || dateIndexImpl !== '') {
            if (currIndex !== false || currIndexImpl !== '') {
                for (let i = 0; i < rest.length; i++) {
                    const toMultiply = currValueIndex === false ? currValueIndexImpl : tableData.values[currValueIndex][rest[i]]
                    const dateNow = dateIndex === false ? dateIndexImpl : tableData.values[dateIndex][rest[i]]
                    if (dateNow !== '') {
                        const currNow = currIndex === false ? currIndexImpl : tableData.values[currIndex][rest[i]]
                        if (currNow !== '') {
                            await new Promise(r => setTimeout(r, 100));

                            // If it is RON/LEU, rate is x1
                            if(currNow.toString() === 'RON' || currNow.toString() === 'LEU') {
                                SelectorRefs.current[exchIndex][rest[i]].value = toMultiply
                                handleCellChange(exchIndex, rest[i], toMultiply)
                                return
                            }

                            const dateMY = arrayFromDateString(dateNow)
                            // // TODO timeout based on cookies
                            if (dateMY.length < 3) break;
                            if (!gotFromDb && dbCallCounter <= DB_CALL_ALLOWED) {
                                rates = await getExchangeRates(dateMY[1], dateMY[2])
                                gotFromDb = true
                                dbCallCounter++
                            }
                            else if (!rates.dataId) {
                                rates = currentRates
                            }
                            // first check dates
                            if(rates.data['month-year'] !== `${dateMY[1]}-${dateMY[2]}` && dbCallCounter <= DB_CALL_ALLOWED) {
                                rates = await getExchangeRates(dateMY[1], dateMY[2])
                                dbCallCounter++
                            }
                            // dates not available?
                            if(rates.data['month-year'] !== `${dateMY[1]}-${dateMY[2]}`) {
                                return;
                            }

                            // check if day is in weekend and change (no-weekend rates)
                            const dateToCheck = new Date(parseInt(dateMY[2]), parseInt(dateMY[1])-1, parseInt(dateMY[0]))
                            if (dateToCheck.getDay() === 6 || dateToCheck.getDay() === 0) {
                                const diff = dateToCheck.getDay() === 6 ? 1 : 2;
                                // End of month
                                if (dateToCheck.getDate() > 27) {
                                    dateMY[0] = (dateToCheck.getDay() - diff).toString();
                                }
                                // Start of month
                                else {
                                    dateMY[0] = (dateToCheck.getDay() + diff).toString();
                                }
                            }
                            
                            // calculate with rates from db
                            for (const [key, value] of Object.entries(rates.data)) {
                                const dataComp = arrayFromDateDB(key)
                                if ( dataComp[0] === dateMY[2]  && // year
                                     dataComp[1] === dateMY[1]  && // month
                                     dataComp[2] === dateMY[0] )   // day
                                {
                                    for(const [key, val] of Object.entries(value)) {
                                        if(key.toString() === currNow.toString()) {
                                            const retVal = (parseFloat(val.rate) * 
                                                parseFloat(toMultiply) * 
                                                parseFloat(val.multiplyer)).toFixed(2)
                                            SelectorRefs.current[exchIndex][rest[i]].value = retVal
                                            handleCellChange(exchIndex, rest[i], retVal)
                                            break;
                                        }
                                    }
                                    break;
                                }
                            }
                        }
                    } 
                }
            }
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Checks if UMS columns require a value or can be empty and populates
    //-----------------------------------------------------------------------------------------
    const checkEmptyUMS = () => {
        //  0 - cod vamal
        //  9 - cod ums
        // 10 - val ums
        const umsCodes = [tableData.values[verifyCell[9]], tableData.values[verifyCell[10]]]
        if (tableData.values[verifyCell[0]].length < 1) return
        tableData.values[verifyCell[0]].forEach((value, index) => {
            if (value === '') return
            const codeIdx = Array.from(CoduriVamale).indexOf(value.toString())

            if (codeIdx < 0 || codeIdx > RefCodes.length - 1) return
            // If cell is not empty and should be
            else if (RefCodes[codeIdx] === '' && umsCodes[0][index] !== '') {
                handleCellChange(verifyCell[9], index, '')
                handleCellChange(verifyCell[10], index, '')
                SelectorRefs.current[verifyCell[9]][index].value = ''
                SelectorRefs.current[verifyCell[10]][index].value = ''
            }
            // If cell is empty OR if cell is different value than the supposed one
            else if (
                    (RefCodes[codeIdx] !== '' && umsCodes[0][index] === '') ||
                    (RefCodes[codeIdx] !== umsCodes[0][index])
                ) 
            {
                let bucIdx = -1
                for (const [key, value] of Object.entries(layouts[data.layout].data)) {
                    if(key.toString() === 'cantitate') {
                        if(value !== '' && value !== 'false' && value !== false) {
                            bucIdx = parseInt(value)
                            break
                        }
                    }
                }
                handleCellChange(verifyCell[9], index, RefCodes[codeIdx])
                SelectorRefs.current[verifyCell[9]][index].value = RefCodes[codeIdx]
                const bucValue = bucIdx === -1 ? '' : tableData.values[bucIdx][index]
                handleCellChange(verifyCell[10], index, bucValue)
                SelectorRefs.current[verifyCell[10]][index].value = bucValue
            }
            else return
        })
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Returns cell value to display in table
    //-----------------------------------------------------------------------------------------
    const populateCellValue = (
        index, 
        value, 
        i, 
        changeState = true,
        tValues = tableData.values, 
        vCell = verifyCell
    ) => {
        if(value !== '') {
            return value
        }
        else {
            // check if currency needs to be converted
            if(index === vCell[1]) {
                const toReturn = 0
                changeState && handleCellChange(index, i, toReturn)
                return toReturn
            }
            // check cod ums and valoare ums
            else if(index === vCell[9] || index === vCell[10]) {
                let bucIdx = -1
                for (const [key, value] of Object.entries(layouts[data.layout].data)) {
                    if(key.toString() === 'cantitate') {
                        if(value !== '' && value !== 'false' && value !== false) {
                            bucIdx = parseInt(value)
                            break
                        }
                    }
                }
                // no bucati data in layout
                if(bucIdx === -1 && index === vCell[10]) return value

                if(!returnVerify(value, index, i, tValues, vCell)) {
                    if(index === vCell[10]) { // Val UMS
                        const retVal = tValues[bucIdx][i]
                        changeState && handleCellChange(index, i, retVal)
                        return retVal
                    } else if (index === vCell[9]) { // Cod UMS
                        const codeIdx = Array.from(CoduriVamale).indexOf(tValues[vCell[0]][i])
                        if(codeIdx > 0 && codeIdx < RefCodes.length) {
                            changeState && handleCellChange(index, i, RefCodes[codeIdx])
                            return RefCodes[codeIdx]
                        }
                    }
                }
                return value
            }
            else {
                const toReturn = getLayoutDefaultData(index)
                changeState && handleCellChange(index, i, toReturn)
                return toReturn
            }
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Returns the title of the header to be displayed
    //-----------------------------------------------------------------------------------------
    const getHeaderTitle = (index) => {
        if(!isHeaderRequired(index)) {
            return tableData.headers[index].toString()
        }
        let text = tableData.headers[index].toString()
        for (const [key, value] of Object.entries(layouts[data.layout].data)) {
            if(value.toString() === index.toString()) {
                text = key
                break
            }
        }
        const res = text.replace(/([A-Z])/g, " $1");
        const finalRes = res.charAt(0).toUpperCase() + res.slice(1)
        return finalRes.toString()
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Populates values for header and footer of columns
    //-----------------------------------------------------------------------------------------
    const populateHeaderFooterStrings = async () => {
        let arr = []
        let footerArr = []
        let addInd = {}
        for (let i = 0; i < tableData.headers.length; i++) {
            arr.push(getHeaderTitle(i))
            if(isHeaderRequired(i)) {
                for (const [key, value] of Object.entries(layouts[data.layout].data)) {
                    if(i.toString() === value.toString()) {
                        switch(key) {
                            case 'valoareFiscala': {
                                addInd.valoareFiscala = i
                                footerArr[i] = tableData.totalFisc
                                break;
                                }
                            case 'valoareStatistica': {
                                addInd.valoareStatistica = i
                                footerArr[i] = tableData.totalStat
                                break;
                                }
                            case 'greutateNeta': {
                                addInd.greutateNeta = i
                                footerArr[i] = tableData.totalNetW
                                break;
                                }
                            default:
                                footerArr.push('')
                                break;
                        }
                    }
                }
            }
            else footerArr.push('')
        }
        await new Promise(r => setTimeout(r, 150));
        setAdditionIndex(addInd)
        await new Promise(r => setTimeout(r, 150));
        setFooterStrings(footerArr)
        await new Promise(r => setTimeout(r, 150));
        JSON.stringify(arr) !== JSON.stringify(headerStrings) && setHeaderStrings(arr);
        await new Promise(r => setTimeout(r, 150));
        setTotalValues()
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Populates entire table
    //-----------------------------------------------------------------------------------------
    const populateTable = async() => {
        const response = await populateReqHeaders()
        const {missing, verifyIndex, reqIndex} = response
        let width =  []
        let active = []
        let values = []
        let types =  []

        // const dataSheets = data.detailed ? data.sheetValues : addDuplicates()
        const dataSheets = data.sheetValues
        await new Promise(r => setTimeout(r, 150));
        let lines = dataSheets.length
        let headers = data.headers;

        const headerLoop = data.headers
        headerLoop.forEach(header => {
            let headerData = []
            dataSheets.forEach(val => {
                let toAdd = ''
                for (const [key, value] of Object.entries(val)) {
                    if(key === header) {
                        toAdd = value.toString()
                        break
                    }
                }
                headerData.push(toAdd)
            })
            values.push(headerData)
            active.push(true) //// TODO based on layout
            width.push(7) //// TODO based on layout
            types.push('text') //// TODO based on layout
        })
        // check if there are missing required columns and fill them with default data
        if(missing.length > 0) {
            let newReqHeaders = REQUIRED_HEADERS;
            layouts[data.layout].type === 'livrari' && newReqHeaders.push('vat') // vat
            // let reqIndex = reqHeadersIndex
            // let verifyIndex = verifyCell
            missing.forEach((val) => {
                reqIndex.add(headers.length)
                verifyIndex[newReqHeaders.indexOf(val.toString())] = headers.length
                headers.push(val)
                let additional = []

                values[0].forEach(() => {
                    const textData = getLayoutDefaultData(-1, val.toString())
                    additional.push(textData)
                })
                values.push(additional)
                active.push(true) //// TODO based on layout
                width.push(7) //// TODO based on layout
                types.push('text') //// TODO based on layout
            })
            setReqHeadersIndex(reqIndex)
            setVerifyCell(verifyIndex)
        }

        // Checking for errors and adding missing values from layouts
        await new Promise(r => setTimeout(r, 150));
        // row
        for (let i = 0; i < values[0].length; i++) {
            // column
            for (let j = 0; j < values.length; j++) {
                const newVal = populateCellValue(j, values[j][i], i, false, values, verifyIndex)
                // if not UMS
                if (j !== verifyIndex[9] && j !== verifyIndex[10] && !hasErrors) {
                    // if is still empty -> error
                    if (newVal === '' && isHeaderRequired(j)) {
                        setHasErrors(true)
                    }
                }
                values[j][i] = newVal
            }
        }

        setTableData(prev => ({...prev, headers, lines, width, active, values}))

        // display size warnings for user
        await new Promise(r => setTimeout(r, 150));
        if (values[0].length > 999 && values[0].length < 4998) {
            createNotification('warning', 'Acesta este un fișier mare. Unele funcții vor necesita mai mult timp.')
        }
        else if (values[0].length > 4999) {
            createNotification('warning', 'Acesta este un foarte fișier mare. Unele funcții vor necesita mult timp.')
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Check if there are errors in file
    //-----------------------------------------------------------------------------------------
    const checkForErrors = (
        detailed = false,
        setState = true, 
        tValues = tableData.values, 
        vCell = verifyCell
    ) => {
        const returner = []
        let isErr = false;
        // row
        for (let i = 0; i < tValues[0].length; i++) {
            // column
            for (let j = 0; j < tValues.length; j++) {
                // shallow check
                // if not UMS
                if (j !== vCell[9] && j !== vCell[10] && !hasErrors) {
                    // if is still empty -> error
                    if (tValues[j][i] === '' && isHeaderRequired(j)) {
                        returner.push({col: j + 1, row: i + 1})
                        isErr = true
                        if (!detailed) return { val: true }
                    }
                }
            }
            // deep check
            const isRowErr = isRowError(i, true)
            if(isRowErr.val) { 
                if (!detailed) return { val: true }
                isErr = true
                returner.push({ cell: vCell[isRowErr.cells], row: i + 1 })
            }
        }
        setState && setHasErrors(isErr)
        return {errors: returner, val: isErr}
    }
    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Function to parse to float, but return 0 if values is not a number
    //-----------------------------------------------------------------------------------------
    const parseAddFloat = (nr) => {
        if (nr === '' || parseFloat(nr).toString() === 'NaN') return 0
        return parseFloat(nr)
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Sum values for columns that require it
    //-----------------------------------------------------------------------------------------
    const setTotalValues = () => {
        let valoareFiscalaSum = 0
        let valoareStatisticaSum = 0
        let greutateNetaSum = 0
        tableData.values.forEach((val, index) => {
            for (const [key, value] of Object.entries(additionIndex)) {
                if(index.toString() === value.toString()) {
                    if(key === 'valoareFiscala') {
                        valoareFiscalaSum = val.reduce(
                            (previousValue, currentValue) => currentValue !== '' ?
                            parseAddFloat(previousValue) + parseAddFloat(currentValue) :
                            getLayoutDefaultData(-1, key.toString()) !== '' ?
                            parseAddFloat(previousValue) + parseAddFloat(getLayoutDefaultData(-1, key.toString())) :
                            parseAddFloat(previousValue) + 0
                            ,
                            0
                        );
                    }
                    else if(key === 'valoareStatistica'){
                        valoareStatisticaSum = val.reduce(
                            (previousValue, currentValue) => currentValue !== '' ?
                            parseAddFloat(previousValue) + parseAddFloat(currentValue) :
                            getLayoutDefaultData(-1, key.toString()) !== '' ?
                            parseAddFloat(previousValue) + parseAddFloat(getLayoutDefaultData(-1, key.toString())) :
                            parseAddFloat(previousValue) + 0
                            ,
                            0
                        );
                    }
                    else if(key === 'greutateNeta') {
                        greutateNetaSum = val.reduce(
                            (previousValue, currentValue) => currentValue !== '' ?
                            parseAddFloat(previousValue) + parseAddFloat(currentValue) :
                            getLayoutDefaultData(-1, key.toString()) !== '' ?
                            parseAddFloat(previousValue) + parseAddFloat(getLayoutDefaultData(-1, key.toString())) :
                            parseAddFloat(previousValue) + 0
                            ,
                            0
                        );
                    }
                }
            }
        })

        if(valoareFiscalaSum === 0 && valoareStatisticaSum === 0 && greutateNetaSum === 0) return

        setTableData(prev => ({...prev, 
            totalFisc: valoareFiscalaSum.toLocaleString(), 
            totalStat: valoareStatisticaSum.toLocaleString(),
            totalNetW: greutateNetaSum.toLocaleString()}))
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Checks if column can be displayed
    //-----------------------------------------------------------------------------------------
    const checkToDisplayColumns = (index) => {
        if(data.onlyReq) {
            return isHeaderRequired(index)
        }
        return true
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Checks if there is error in row
    //-----------------------------------------------------------------------------------------
    const checkErrorsFilter = (rowIndex, quick = false) => {
        const errCells = []
        for(let i = 0; i < verifyCell.length; i++) {
            const index = verifyCell[i]
            if (!tableData.values[index]) continue; // Skip undefined indexes
            if (!tableData.values[index][rowIndex] && tableData.values[index][rowIndex] !== "") continue; // Only skip if value is undefined
            if(!returnVerify(tableData.values[index][rowIndex], index, rowIndex)) {
                if (quick) return { val: false }
                errCells.push(i)
            }
        }
        if(errCells.length > 0) return { val: false, cell: errCells }
        return { val: true, cell: [] }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Checks if row can be displayed if it has errors
    //-----------------------------------------------------------------------------------------
    const checkToDisplayRows = (index) => {
        if(data.showErr) {
            return !checkErrorsFilter(index, true).val
        }
        return true
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Checks if row has errors
    //-----------------------------------------------------------------------------------------
    const isRowError = (index, wantCell = false) => {
        const toReturn = checkErrorsFilter(index, false)
        if (!wantCell) return { val: !toReturn.val, cells: [] }
        return { val: !checkErrorsFilter(index).val, cells: toReturn.cell }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Changes the state that stores the table values
    //-----------------------------------------------------------------------------------------
    const handleCellChange = async (bigIndex, smallIndex, val) => {
        const newData = tableData.values;
        newData[bigIndex][smallIndex] = val;
        await new Promise(r => setTimeout(r, 150));
        setTableData(prev => ({...prev, data:newData}))
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Handles page change by number given
    //-----------------------------------------------------------------------------------------
    const handleInputPageChange = (e) => {
        const input = e - 1
        if(input < 0) return 0
        else if(input >= Math.ceil(tableData.lines/PER_PAGE)) return Math.ceil(tableData.lines/PER_PAGE)-1
        else return input
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Handle page change by button click
    //-----------------------------------------------------------------------------------------
    const pageChangeBtn = (input) => {
        const toSet = page
        if(input === -1 && page > 0) setPage(toSet - 1)
        if(input === 1 && page < Math.ceil(tableData.lines/PER_PAGE)-1) setPage(toSet + 1)
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Checks if value given is considered error. Returns false if there is error.
    //-----------------------------------------------------------------------------------------
    const returnVerify = (
        value, 
        index, 
        smallIndex = -1, 
        tValues = tableData.values, 
        vCell = verifyCell
    ) => {
        let toReturn = true

        //cod ums AND valoare ums CAN be empty
        if(index !== vCell[9] && index !== vCell[10]) {
            // check if empty
            if(vCell.includes(index) && value === '') toReturn = false
        }

        // if cell empty no need for more checks
        if(!toReturn) return toReturn

        //check Cod Vamal
        if(index === vCell[0]) {
            toReturn = checkValueCodVamal(value)
        }
        //check Valoare Fiscala
        else if(index === vCell[1]) {
            toReturn = Math.round(value) > 0
        }
        //check greutate neta
        else if(index === vCell[2]) {
            toReturn = Math.round(value) >= 0
        }
        //check Valoare Statistica
        else if(index === vCell[6]) {
            toReturn = data.valStat ? Math.round(value) > 0 : true
        }
        // check tara origine or tara expediere (only by length first)
        else if (index === vCell[4] || index === vCell[5]) {
            if (value.trim().length > 2 || value.trim().length <= 1) toReturn = false;
            if (toReturn) {
                // Tara origine
                if (index === vCell[4]) {
                    if (!new Set(countryCodes).has(value.trim())) {
                        toReturn = false;
                    }
                } else if (index === vCell[5]) { // Tara expediere
                    if (!new Set(EuCountries.Item.map(val => val.Code)).has(value.trim())) {
                        toReturn = false;
                    }
                }
            }
        }
        // check val ums / cod ums
        else if(index === vCell[9] || index === vCell[10]) {
            if(smallIndex === -1) return false
            const valToCheck = tValues[vCell[0]][smallIndex].toString()
            // check if the Cod Vamal requires cod ums
            if(CoduriVamale.has(valToCheck)) {
                const CodVamArray = Array.from(CoduriVamale)
                const checkIdx = CodVamArray.indexOf(valToCheck)
                if(RefCodes[checkIdx] !== '') {
                    // cod
                    if(index === vCell[9]) {
                        toReturn = value === RefCodes[checkIdx];
                    }
                    // val
                    else {
                        toReturn = value !== '' && value > 0;
                    }
                }
                else 
                    toReturn = true
            }
            else
                toReturn = false
        }
        // check vat
        else if(index === vCell[vCell.length - 1]) {
            toReturn = !errorVat.has(value)
        }
        return toReturn
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Check if cell value is of type select (fake select inout)
    //-----------------------------------------------------------------------------------------
    const isSelectStyle = (index) => {
        // check non-required
        //
        // currency
        if(layouts[data.layout].data.moneda === index) return true

        // check required
        //
        // Cod Vamal          [0]
        // Conditie Livrare   [3]
        // Tara Origine       [4]
        // Tara Expediere     [5]
        // Natura Tranzactiei [7]
        // Mod Transport      [8]
        if( index === verifyCell[0] ||
            index === verifyCell[3] ||
            index === verifyCell[4] ||
            index === verifyCell[5] ||
            index === verifyCell[7] ||
            index === verifyCell[8]
        ) return true
        else return false
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Handles open modal box
    //-----------------------------------------------------------------------------------------
    const openModal = (bigIndex, smallIndex) => {
        let type = 0
        // set type if not required
        // currency
        if(layouts[data.layout].data.moneda === bigIndex) {
            type = -1
        }
        else type = verifyCell.indexOf(bigIndex)

        setModal(true)
        const valuesForModal = {
            value: SelectorRefs.current[bigIndex][smallIndex].value,
            bigIndex,
            smallIndex,
            type
        }
        setModalValues(valuesForModal)
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Function passed to modal box child to pass values back to parent(this)
    //-----------------------------------------------------------------------------------------
    const setValueFromModal = (val, bigIndex, smallIndex, type) => {
        SelectorRefs.current[bigIndex][smallIndex].value = val
        handleCellChange(bigIndex, smallIndex, val)
        type === 0 && checkEmptyUMS()
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Calls function to export excel from Excel service
    //-----------------------------------------------------------------------------------------
    const exportExcelAction = () => {
        exportExcel(tableData.values, tableData.headers)
    }

    const getErrorRows = (rows = tableData.values) => {
        const errorRows = []
        rows.forEach((value, index) => {
            const errorRow = []
            rows[index].forEach((v, i) => {
                isRowError(i).val && errorRow.push(v)
            })
            errorRow.length > 0 && errorRows.push(errorRow)
        })
        return errorRows
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Exports excel with errors only
    //-----------------------------------------------------------------------------------------
    const exportExcelErrorsAction = () => {
        const errorRows = getErrorRows()
        tableData.values.forEach((v, index) => {
            const errorRow = []
            tableData.values[index].forEach((v, i) => {
                isRowError(i).val && errorRow.push(v)
            })
            errorRow.length > 0 && errorRows.push(errorRow)
        })
        exportExcel(errorRows, tableData.headers, 'Erori exportate din programintrastat.ro')
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Checks VAT from table values using API
    //-----------------------------------------------------------------------------------------
    const checkVatAction = async () => {
        if(loadingAction) return

        // cookie logic
        if(checkCookie(VAT_CHECK_COOKIE)) {
            return createNotification('info', `Așteptați puțin pentru a putea verifica codurile VAT din nou`)
        }
        const counter = checkCookie(VAT_CHECK_COUNTER_COOKIE) ?
            (parseInt(getCookie(VAT_CHECK_COUNTER_COOKIE)) + 1) : 0

        let timeout = 1
        if(counter < 2) timeout = 0
        else if(counter > 1 && counter <  6) timeout = 1
        else if(counter > 5 && counter < 12) timeout = 2
        else timeout = 5

        setMinuteCookie(VAT_CHECK_COOKIE, true, timeout)
        setMinuteCookie(VAT_CHECK_COUNTER_COOKIE, counter, 10)
        // end cookie logic

        setLoadingAction(true)
        createNotification('info', 'Se verifică valorile VAT. Așteptați până se finalizează animația de încărcare.')
        let vatIndex = layouts[data.layout].data.vat
        if(vatIndex === false || vatIndex === '' || vatIndex === 'false') {
            vatIndex = parseInt(
                prompt(`Numărul coloanei cu valorile VAT lipsește din machetă. Introduceți numărul coloanei [1 - ${tableData.headers.length}]:`, 1))-1
            if(vatIndex === null || 
                vatIndex === '' || 
                vatIndex.toString() === 'NaN' || 
                vatIndex === undefined ||
                vatIndex > tableData.headers.length-1)
                    return createNotification('error', 'Valoare invalidă')
        }
        const allVats = tableData.values[vatIndex]
        const errorVats = errorVat
        const checked = checkedVats
        allVats.forEach(_vat => {
            // TODO: CHECK WHY validateVATEu is sometimes false
            // if(!validateVATEu(vat, true)) errorVats.add(vat)
        })
        const vatsToCheck = Array.from(new Set(allVats.filter(vat => !checked.has(vat) && !errorVats.has(vat))))

        // Start checking vat with API
        let isError = false
        for(let i = 0; i < vatsToCheck.length; i++) {
            const result = splitVat(vatsToCheck[i])
            if(!result) return
            await new Promise(r => setTimeout(r, 300));
            await fetch(vatUrlString(result[0], result[1]))
            .then(response => response.json())
            .then(message => {
                if(!message.valid) errorVats.add(vatsToCheck[i])
            })
            .catch((_error) => {
                // TODO Find solution for error handling inside loop
                // isError = true
                // return error
            });
        }

        vatsToCheck.forEach(vat => checked.add(vat))
        setErrorVat(errorVats)
        setCheckedVats(checked)
        setLoadingAction(false)
        isError ? 
            createNotification('error', 'Au existat erori la verificarea valorilor VAT') : 
            createNotification('success', 'Valorile VAT au fost verificate cu succes!')
        
        errorVats.size > 0 && createNotification('error', `Există ${errorVats.size} valori VAT invalide.`)
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Sets a fake loading timer on first display of table
    //-----------------------------------------------------------------------------------------
    const loadingOnStart = async () => {
        const loadingTime = 3000;
        if(!loading) setLoading(true)
        await new Promise(r => setTimeout(r, loadingTime));
        setLoading(false)
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Calculates statistical value
    //-----------------------------------------------------------------------------------------
    const calcValStat = async () => {
        setLoadingAction(true)
        // semn +/- -> EXW, FCA = PLUS -> rest = MINUS
        const rows = []
        tableData.values[0].forEach((v, index) => {
            const row = []
            tableData.values.forEach((val) => {
                row.push(val[index])
            })
            rows.push(row)
        })
        const reqArray = []
        rows.forEach((row) => {
            let arr = []
            row.forEach((r, i) => {
                if(
                    i === verifyCell[3] || 
                    i === verifyCell[8] || 
                    i === verifyCell[7] || 
                    i === verifyCell[5] 
                ) {
                    if(i === verifyCell[3])      arr[0] = r
                    else if(i === verifyCell[8]) arr[1] = r
                    else if(i === verifyCell[7]) arr[2] = r
                    else if(i === verifyCell[5]) arr[3] = r
                }
            })
            if(arr[0] === 'EXW' || arr[0] === 'FCA') arr.push('PLUS')
            else arr.push('MINUS')
            arr.push(0)
            reqArray.push(arr)
        })
        // [0] cond liv, [1] mod trans, [2] nat tranz, [3] tara,
        const finalArr = Array.from(new Set(reqArray.map(JSON.stringify)), JSON.parse).sort()
        setValStatArr(finalArr)
        await new Promise(r => setTimeout(r, 400));
        setLoadingAction(false)
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Sets resulted values in table view
    //-----------------------------------------------------------------------------------------
    const setValStatVal = async (res) => {
        setValStatArr(res)
        // map val stat
        tableData.values[verifyCell[6]].forEach((value, index) => {
            res.forEach(r => {
                // can't calculate with 0
                if(r[5] === 0) return

                if(
                    r[0] === tableData.values[verifyCell[3]][index] &&
                    r[1] === tableData.values[verifyCell[8]][index] &&
                    r[2] === tableData.values[verifyCell[7]][index] &&
                    r[3] === tableData.values[verifyCell[5]][index] 
                ) {
                    const currVal = parseFloat(tableData.values[verifyCell[1]][index])
                    if(currVal === 0 || currVal === '') return
                    const percent = parseFloat(currVal * parseFloat(parseFloat(r[5])/100))
                    const retVal = r[4] === 'PLUS' ? parseFloat(currVal + percent).toFixed(2) : 
                    parseFloat(currVal - percent).toFixed(2)
                    handleCellChange(verifyCell[6], index, retVal.toString())
                    return
                }
            })
        })
        await new Promise(r => setTimeout(r, 400));
        setTotalValues()
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Open Popup with statistical value user interface
    //-----------------------------------------------------------------------------------------
    const openValStat = () => {
        if(valStatArr.length < 1) {
            createNotification('error', 'Datele nu au fost extrase încă din fișier. Încercați din nou')
            return
        }
        const dataForPopup = {
            valStatArr: valStatArr,
            title: 'Valoare statistică',
            type: 1,
            func: setValStatVal
        }
        setPopupData(dataForPopup)
        setPopupOn(true)
    }

    // 'codVamal',          // 00 *
    // 'valoareFiscala',    // 01
    // 'greutateNeta',      // 02
    // 'conditieLivrare',   // 03 *
    // 'taraOrigine',       // 04 *
    // 'taraExpediere',     // 05 *
    // 'valoareStatistica', // 06
    // 'naturaTranzactiei', // 07 *
    // 'modTransport',      // 08 *
    // 'codUms',            // 09
    // 'valoareUms',        // 10
    //! 'vat',              // 11 **
    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Generates an array only with the required values for final export
    //-----------------------------------------------------------------------------------------
    const getArrayWithRequired = (arr = tableData.values) => {
        const reqArray = []
        arr[0].forEach((val, index) => {
            let rowArr = []
            arr.forEach((v, i) => {
                let currentIndex = verifyCell.indexOf(i)
                if(currentIndex !== -1) rowArr[currentIndex] = v[index]
            })
            reqArray.push(rowArr)
        })
        return reqArray
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Sums duplicates from array based on required fields
    //-----------------------------------------------------------------------------------------
    const addArrayDuplicates = (arr) => {
        const finalArr = []
        const duplicates = new Set()
        arr.forEach((value, index) => {
            if(duplicates.has(index)) return

            let currentRow = value
            let totalValFisc = parseAddFloat(value[ 1])
            let totalNetW    = parseAddFloat(value[ 2])
            let totalValStat = parseAddFloat(value[ 6])
            let totalBuc     = parseAddFloat(value[10])
            for(let i = index + 1; i < arr.length; i++) {
                if(
                    value[0] === arr[i][0] &&
                    value[3] === arr[i][3] &&
                    value[4] === arr[i][4] &&
                    value[5] === arr[i][5] &&
                    value[7] === arr[i][7] &&
                    value[8] === arr[i][8] 
                ) {
                    if(layouts[data.layout].type === 'livrari') {
                        if(value[value.length - 1] === arr[i][value.length - 1]) {
                            totalValFisc += parseAddFloat(arr[i][ 1])
                            totalNetW    += parseAddFloat(arr[i][ 2])
                            totalValStat += parseAddFloat(arr[i][ 6])
                            totalBuc     += parseAddFloat(arr[i][10])
                            duplicates.add(i)
                        }
                    }
                    else {
                        totalValFisc += parseAddFloat(arr[i][ 1])
                        totalNetW    += parseAddFloat(arr[i][ 2])
                        totalValStat += parseAddFloat(arr[i][ 6])
                        totalBuc     += parseAddFloat(arr[i][10])
                        duplicates.add(i)
                    }
                }
            }
            currentRow[ 1] = Math.round(totalValFisc)
            currentRow[ 2] = Math.round(totalNetW)
            currentRow[ 6] = Math.round(totalValStat)
            currentRow[10] = Math.round(totalBuc)
            finalArr.push(currentRow)
        })
        // Sort all items based on criteria
        finalArr.sort((a, b) => 
            // Vamal Code            #1
            a[0].localeCompare(b[0]) || 
            // C of Origin           #2
            a[4].localeCompare(b[4]) ||
            // C of Exped            #3
            a[5].localeCompare(b[5]) ||
            // Terms of exped        #4
            a[3].localeCompare(b[3])
        )
        return finalArr
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Creates the XML file for the user to download
    //-----------------------------------------------------------------------------------------
    const generateXML = async (type, values, generatedArr) => {
        setPopupOn(false)
        // inchide fereastra
        if(!type) return

        let comp = type !== 2 ? { cif : values.cName } : {}
        if(data.tert) { comp.name = companies[data.compFor].name }
        else { comp.name = companies[0].name }

        const month = parseInt(data.month) < 10 ? `0${data.month}` : `${data.month}`
        const typeLetter = layouts[data.layout].type.toUpperCase().charAt(0)
        let fileName = `${data.month}_${data.year}_${typeLetter}_${getXMLVat(userData.data.cui)}`
        if (data.tert) fileName += `_${getXMLVat(comp.cif)}`

        if(data.reviz) fileName += `_R`
        if(hasErrors) fileName += `_ERR`

        // nu mai genera
        if(type === 2) {
            const oldData = data.oldData[0]
            downloadXmlFomString(oldData, fileName)
            return
        }
        setLoadingAction(true)

        const itemArray = !data.isOldData ? generatedArr : 
        data.detailed ? getArrayWithRequired() : addArrayDuplicates(getArrayWithRequired())

        const splitName = values.reprez ? values.reprez.trim().split(/\s+/) : ''
        const fName = splitName ? splitName.map((curr, index) => {
            return (index < splitName.length - 1) ? curr : ''
        }).join(' ').trim() : ''
        const lName = splitName ? splitName[splitName.length - 1] : ''

        const toPassToXML = {
            isValStat: data.valStat,
            type:      layouts[data.layout].type,
            tert:      data.tert,
            reviz:     data.reviz,
            compVat:   getXMLVat(comp.cif),
            compName:  comp.name,
            refPeriod: `${data.year}-${month}`,
            email:     values.mail,
            phone:     values.phone,
            position:  values.pos,
            tertVat:   getXMLVat(userData.data.cui),
            tertName:  userData.data.company,
            county:    userData.data.county,
            itemArray,
            fName,
            lName
        }
        const xmlRes = getXMLGeneratedData(toPassToXML)
        downloadXmlFomString(xmlRes, fileName)
        data.setOldData([xmlRes])
        if(!data.isOldData) {
            await updateUserLines(values.linesLeft)
        }
        // send statistics to database
        const today = new Date()
        const stat_data = {
            uid:         currentUser.uid,
            lines:       tableData.lines,
            linesUsedFromSub: data.reviz ? 0 : tableData.lines,
            generatedFileName: fileName,
            fileName:    data.file.name,
            type:        layouts[data.layout].type,
            reviz:       data.reviz,
            company:     comp.cif,
            tertCompany: comp.cif === userData.data.cui ? false : userData.data.cui,
            layoutId:    layouts[data.layout].dataId,
            hasErrors:   hasErrors,
            valStat:     data.valStat,
            name:        values.reprez,
            position:    values.pos,
            date:        toFormatDate(today),
            timeStamp:   today,
            generatedAgain: data.isOldData,
            generatedXML: xmlRes
        }
        
        // TODO Activate statistics in build (done)
        setLoadingAction(false)
        try {
            await newStatsGeneratedXml(stat_data)
            addInGeneratedHistory(stat_data);
            createNotification('success', 'Declarația generată a fost salvată în secțiunea "Istoric declarații". O puteți descărca și de acolo.')
        }
        catch (error) {
            // do nothing
        }
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Converts data to final array for XML export - Also checks if user has lines left in sub
    //-----------------------------------------------------------------------------------------
    const exportToXML = async () => {
        createNotification('info', 'Se generează. Vă rugăm așteptați..')
        setLoadingAction(true)
        await new Promise(r => setTimeout(r, 300));
        // Check for errors before generating
        let currentErrors = checkForErrors().val
        currentErrors && createNotification('error', 'Există erori în tabel')

        let arrToDisplay = []
        if(!data.isOldData) {
            arrToDisplay = data.detailed ? getArrayWithRequired() : addArrayDuplicates(getArrayWithRequired())
        }
        const sub = userData.subscriptions[0]
        // const linesToGenerate = data.isOldData ? tableData.values[0].length : arrToDisplay.length
        const linesToGenerate = data.reviz ? 0 : tableData.lines
        if(tableData.lines < 1) {
            createNotification('warning', 'Nu există linii pentru generat')
            setLoadingAction(false)
            return
        }
        // multiple exports/generates do not affect lines
        const linesLeft = data.isOldData ? 1 :parseInt(sub.lines[currentSubMonth(sub.date)]) - parseInt(linesToGenerate)
        if(linesLeft <= 0) {
            createNotification('error', 'Nu mai aveți linii pentru generat disponibile în această lună pentru abonamentul actual')
            setLoadingAction(false)
            return
        }
        // check if 'livrari' and vat was checked
        if (userData.subscriptions[0].valStatCalc && layouts[data.layout].type === 'livrari' && checkedVats.size === 0) {
            createNotification('warning', `Valorile VAT nu au fost verificate înainte de generare. Apăsați 'Verificare VAT' înainte de a genera fișierul XML pentru a valida valorile VAT din tabel`)
        }
        let comp = {}
        if(data.tert) {
            comp.cif = companies[data.compFor].cif
        }
        else {
            comp.cif = userData.data.cui
        }
        const dataForPopup = {
            title:        'Rezumat generare',
            type:         2,
            func:         generateXML,
            tert:         data.tert,
            hasValStat:   data.valStat,
            uData:        userData.data,
            generatedArr: arrToDisplay,
            hasErr:       currentErrors,
            exists:       data.isOldData,
            linesToGenerate,
            linesLeft,
            comp,
        }
        setPopupData(dataForPopup)
        setPopupOn(true)
        setLoadingAction(false)
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Populates error cells 
    //-----------------------------------------------------------------------------------------
    const populateErrorCells = async () => {
        setLoadingAction(true)
        await new Promise(r => setTimeout(r, 200));
        const errorRows = new Set([])
        let errorsArr = 
            new Array(tableData.values.length).fill(null).map(() => new Array(tableData.values[0].length).fill(''))
        tableData.values[0].forEach((val, i) => {
            if (checkToDisplayRows(i)) {
                tableData.values.forEach((value, idx) => {
                    errorsArr[idx][i] = value[i]
                })
                errorRows.add(i)
            }
        })
        setErrorCells(errorsArr)
        setErrorIndexes(errorRows)
        setLoadingAction(false)
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Calls functions only on startup 
    //-----------------------------------------------------------------------------------------
    useEffect(() => {
        populateTable()
        loadingOnStart()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Checks if parent buttons are pressed and starts required actions
    //-----------------------------------------------------------------------------------------
    useEffect(() => {
        if (loading || loadingAction) return
        // check vat action is starting
        if (checker.checkVat !== actions.checkVat) {
            setChecker(prev => ({...prev, checkVat: actions.checkVat}))
            checkVatAction()
        }
        if (checker.exportExcel !== actions.exportExcel) {
            setChecker(prev => ({...prev, exportExcel: actions.exportExcel}))
            exportExcelAction()
        }
        if (checker.exportExcelErr !== actions.exportExcelErr) {
            setChecker(prev => ({...prev, exportExcelErr: actions.exportExcelErr}))
            exportExcelErrorsAction()
        }
        if (checker.valStat !== data.valStat) {
            setChecker(prev => ({...prev, valStat: data.valStat}))
            data.valStat && calcValStat()
        }
        if (checker.calcValStat !== actions.calcValStat) {
            setChecker(prev => ({...prev, calcValStat: actions.calcValStat}))
            data.valStat && openValStat()
        }
        if (checker.generate !== actions.generate) {
            setChecker(prev => ({...prev, generate: actions.generate}))
            exportToXML()
        }
        if (checker.downloadOldXML !== actions.downloadOldXML) {
            setChecker(prev => ({...prev, downloadOldXML: actions.downloadOldXML}))
            generateXML(2)
        }
        if (checker.showErr !== data.showErr) {
            data.showErr && createNotification('info', 'Se filtrează erorile. Vă rugăm așteptați..')
            setChecker(prev => ({...prev, showErr: data.showErr}))
            data.showErr && populateErrorCells()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions, data.valStat, data.showErr, loading])

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Calls functions that update header and footer data of table
    //-----------------------------------------------------------------------------------------
    useEffect(() => {
        populateHeaderFooterStrings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ reqHeadersIndex, headerStrings ])

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Calls sum of values function when indexes for addtitons are found
    //-----------------------------------------------------------------------------------------
    useEffect(() => {
        setTotalValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ additionIndex ])

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Checks if it is needed to exchange currency after first load
    //-----------------------------------------------------------------------------------------
    useEffect(() => {
        checkExchange()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ loading ])

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Returns strings of errors for user to read
    //-----------------------------------------------------------------------------------------
    const errorStrings = (cellIdx) => {
        return `"${headerStrings[cellIdx]}" invalid`
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Opens Popup to display errors to user
    //-----------------------------------------------------------------------------------------
    const openShowErrors = (errArray, rowNr) => {
        const content = (
            <>
            <div className='popup-error-wrapper'>
                {
                errArray.map((val, index) => {
                    return (
                        <div className='popup-error-row' key={`${index} - ${val} - ${rowNr}`}>
                            {index + 1}. {errorStrings(verifyCell[val])}
                        </div>
                    )
                })
                }
            </div>
            </>
        )
        const dataForPopup = {
            title: `Erori (${errArray.length})`,
            type: 3,
            content
        }
        setPopupData(dataForPopup)
        setPopupOn(true)
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Checks if clicked button has error and IF SO opens error Popup
    //-----------------------------------------------------------------------------------------
    const handleCounterClick = (err, errArray) => {
        if (!err) return
        if (errArray.arr.length === 0) return
        openShowErrors(errArray.arr, errArray.rowNr)
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? Return error rows
    //-----------------------------------------------------------------------------------------
    const returnAllErrors = () => {
        if (errorCells.length > 0) return errorCells
        else return [[]]
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? main function that returns JSX element of rows and cells
    //-----------------------------------------------------------------------------------------
    const mapReturnRows = () => {
        let counter = 0
        let errorsPerRow = []
        const toDisplay = data.showErr ? returnAllErrors() : tableData.values
        return toDisplay[0].map((value, i) => {
            // if error filter is on and the row has error
            if (data.showErr && !errorIndexes.has(i)) return null
            // increase line counter if row is valid
            counter++
            // if row is on page
            if(Math.ceil((counter+1)/(PER_PAGE)) === (page+1)) {
                const isRowErr = isRowError(i, true)
                if (isRowErr.val) { 
                    errorsPerRow[i] = { arr: isRowErr.cells, rowNr: counter }
                }
                // don't display row if error filter is on and there is no error on it
                if (data.showErr && !isRowErr.val) return null

                return(
                    <div className='res-table-row rows' key={`${value} - ${i}`}>
                        <input
                            type='text'
                            onClick={() => handleCounterClick(isRowErr.val, errorsPerRow[i])}
                            readOnly={true}
                            defaultValue={`${counter}`}
                            className={`res-table-input${isRowErr.val ? '  error-cell nr-crt' : ''}`}
                            style={{width: data.fullWidth ? 'min-content' : `3rem`, textAlign:'right'}}
                        />
                        {
                        toDisplay && toDisplay.map((val, index) => {
                            const isTypeSelect = isSelectStyle(index)
                            const cellValue = val ? val[i] : '' // populateCellValue(index, val[i], i)
                            const verify = returnVerify(cellValue, index, i)
                            if(checkToDisplayColumns(index) && index !== verifyCell[6]) 
                            return(
                                <input
                                defaultValue   = { cellValue }
                                disabled       = { !tableData.active[index] } 
                                readOnly       = { !tableData.active[index] } 
                                onClick        = { () => isTypeSelect && openModal(index, i)}
                                onBlur         = { (e) => handleCellChange(index, i, e.target.value) }
                                type           = { tableData.types[index] }
                                ref            = { (element) => SelectorRefs.current[index][i] = element }
                                key            = { `${index} - ${i}` }
                                className      = {
                                    `res-table-input${isTypeSelect ? 
                                    ' select-type' : 
                                    ''}${verify ?
                                    '' : ' error-cell'}`
                                }
                                style          = {{ 
                                    width: data.fullWidth ? 'min-content' : 
                                    `${tableData.width[index]}rem`
                                }}
                                />
                            )
                            // val statistica
                            else if(checkToDisplayColumns(index) && index === verifyCell[6]) 
                            return(
                                <input
                                disabled    = {true} 
                                readOnly    = {true}
                                onBlur      = {(e) => handleCellChange(index, i, e.target.value)}
                                value       = {val[i] === '' ? cellValue : val[i]}
                                type        = {tableData.types[index]} 
                                ref         = {(element) => SelectorRefs.current[index][i] = element}
                                key         = {`${index} - ${i}`}
                                className   = {
                                    `res-table-input${verify ? '' : ' error-cell'}`
                                }
                                style       = {{
                                    width: data.fullWidth ? 'min-content' :
                                    `${tableData.width[index]}rem`
                                }}
                                />
                            )
                            else return null
                        })
                        }
                    </div>
                )
            }
            else {
                return null
            }
        })
    }

    //-----------------------------------------------------------------------------------------
    //* \brief 
    //? JSX Element returned
    //-----------------------------------------------------------------------------------------
    return (
        <>
        {loadingAction && 
        <div className='progress-bar-wrapper' style={{width:'100%', maxWidth:'100%'}}>
            <FontAwesomeIcon className='spinner' icon={faSpinner}/>
        </div>
        }
        <div className='res-table-wrapper'>
            <div className='res-table-section'>
                <div className='res-table-row header-cell'>
                    {/* TABLE HEADERS */}
                    <input 
                        id='Number00'
                        type="text" 
                        disabled     = {true} 
                        readOnly     = {true}
                        defaultValue = 'Nr.' 
                        className    = 'res-table-input'
                        style        = {{width: data.fullWidth ? 'min-content' : `3rem`}}
                    />
                    {
                    tableData.headers.map((header, index) => {
                        if(checkToDisplayColumns(index)) return(
                        <input 
                        id={`${header}${index}`}
                        defaultValue = {headerStrings[index]} 
                        className    = {`res-table-input ${isHeaderRequired(index) ? 'req': ''}`}
                        disabled     = {true} 
                        readOnly     = {true}
                        type         = "text" 
                        key          = {`${header} - ${index}`}
                        style        = {{
                            width:
                            data.fullWidth ? 'min-content' :
                            `${tableData.width[index]}rem`
                        }}
                        />
                        )
                        else return null
                    })
                    }
                </div>
                {/* TABLE CELLS */}
                {loading ? <FontAwesomeIcon className='spinner' icon={faSpinner}/> :
                tableData.values.length > 0 && mapReturnRows()
                }

                <div className='res-table-row footer-cell'> 
                    {/* TABLE FOOTERS */}
                    <input 
                        defaultValue = ''
                        className    = {`res-table-input`}
                        readOnly     = {true}
                        disabled     = {true} 
                        style        = {{width: data.fullWidth ? 'min-content' : `3rem`}}
                        type         = "text" 
                    />
                    {
                    tableData.headers && tableData.headers.map((header, index) => {
                        if(checkToDisplayColumns(index)) return(
                        <input 
                        defaultValue = {footerStrings[index]} 
                        className    = {`res-table-input ${isHeaderRequired(index) ? 'req': ''}`}
                        readOnly     = {true}
                        disabled     = {true} 
                        type         = "text"
                        key          = {`${[index]} - ${header}`}
                        style        = {{
                            width: data.fullWidth ? 'min-content' :
                            `${tableData.width[index]}rem`
                        }}
                        />
                        )
                        else return null
                    })

                    }
                </div>

            </div>
        </div>
        {Math.ceil(tableData.lines/PER_PAGE) > 1 && <><div className='page-btn-wrapper'>
            <div onClick={() => pageChangeBtn(-1)}>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </div>

            <input type='number' id='page-input' value={page + 1} step='0'
            onChange={(e) => setPage(handleInputPageChange(e.target.value))} required/>

            <div onClick={() => pageChangeBtn(1)}> 
                <FontAwesomeIcon icon={faChevronRight}/>
            </div>

        </div>
        <div>Pagina <b>{page+1}</b> din {Math.ceil(tableData.lines/PER_PAGE)}</div></>}

        <div>Linii: {tableData.lines}</div>

        { modal && <ModalSetValue values = {modalValues} 
        close={() => setModal(false)} 
        setValue = {setValueFromModal} /> 
        }
        { popupOn && 
            <Popup data={popupData} close={() => setPopupOn(false)}> 
                {popupData.content}
            </Popup>
        }
        </>
    )
}

export default ExcelResultTable