///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

export const bigID = () => {
    let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    const toReturn = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    return toReturn;
}

export const smallID = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

// transforms ID from firestore DB to id for user to see
// firestore id: 12345678 -> user side id: 8765-4321z
const final_id_letter = 'z'
export const userSideId = (id) => {
    return `${id.split("").reverse().join("").match(/.{1,4}/g).join('-')}${final_id_letter}`
}

export const decodeUserSideId = (id) => {
    return id.split("").reverse().join("").replace(/-/g, '').slice(1)
}

export const checkIfUserSideId = (id) => {
    if (id === '') return false
    return id.includes('-') && (id.slice(-1) === final_id_letter)
}