///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

const PRICES = [0, 6.99, 14.99, 23.99, 79.99, 'custom']
const SUBTIME = [6, 12, 12, 12, 12, 12]
const NAMES = ['Demo', 'Standard', 'Plus', 'Premium', 'Professional', 'Custom PRO']
const LINES = [1500, 600, 1300, 3000, 10000, 'custom']
const LAYOUTS = [2, 2, 3, 4, 10, 'custom']
const CIF_CHECKS = [true, false, true, true, true, true]
const CONVERT = [true, false, true, true, true, true]
const COMPANIES = [1, 1, 1, 1, 10, 'custom']
const VAL_STAT_CALC = [true, false, true, true, true, true]

const SUB_OPTIONS = [
    {}, // DEMO
    {exchange: false, vat: false, layouts: 2, companies: 1, valStat: false},
    {exchange: true, vat: true, layouts: 3, companies: 1, valStat: true},
    {exchange: true, vat: true, layouts: 4, companies: 1, valStat: true},
    {exchange: true, vat: true, layouts: 10, companies: 10, valStat: true},
    {} // custom
]
const DISPLAY_SUBSCRIPTION_LENGTH = 4

const addMonths = (num, date) => {
    date.setMonth(date.getMonth() + num)
    return date
}

const getMonthDifference = (startDate, endDate) => {
    const diff = endDate.getMonth() - startDate.getMonth()
    let monthDiff = diff
    if (diff > 0) {
        if (endDate.getDate() < startDate.getDate()) {
            monthDiff = diff - 1
        }
    }
    return (
      monthDiff + 12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

const dateToString = (date) => {
    return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
}

export const getValStatCalc = (id) => {
    return VAL_STAT_CALC[id]
}

export const getCompanyLimit = (id) => {
    return COMPANIES[id]
}

export const getConvert = (id) => {
    return CONVERT[id]
}

export const getCifCheck = (id) => {
    return CIF_CHECKS[id]
}

export const getLayoutsLimit = (id) => {
    return LAYOUTS[id]
}

export const subDisplayData = () => {
    let subArray = []
    for(let i = 0; i < DISPLAY_SUBSCRIPTION_LENGTH; i++) {
        const index = i + 1
        const objToPush = {
            id: index,
            name: NAMES[index],
            lines: LINES[index] === 9999 ? 'NELIMITAT' : LINES[index],
            time: SUBTIME[index],
            price: PRICES[index],
            options: SUB_OPTIONS[index]
        }
        subArray.push(objToPush)
    }
    return subArray;
}

export const subscriptionPrices = (sub) => {
    return PRICES[+sub]
}

export const subscriptionNames = (sub) => {
    return NAMES[+sub]
}

export const getAllSubscriptionNames = () => {
    return NAMES
}

export const subscriptionLines = (sub) => {
    return LINES[+sub]
}

export const subscriptionTime = (sub) => {
    return SUBTIME[+sub]
}

export const subscriptionExpireDate = (date, months) => {
    months++
    const returnDate = addMonths(months, new Date(date))
    return dateToString(returnDate)
}

export const setStartLines = (sub, months) => {
    const linesNr = subscriptionLines(sub)
    let returnArr = []
    for(let i = 0; i < months; i++) {
        returnArr.push(linesNr)
    }
    return returnArr
}

export const currentSubMonth = (start) => {
    const today = new Date();
    const nrMonths = Math.floor(getMonthDifference(new Date(start), today))
    return nrMonths
}

export const isStillActive = (date, months, disabled) => {
    if(disabled) return false;
    if(currentSubMonth(date) > months) return false;
    const expiryDate = addMonths(months, new Date(date));
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return expiryDate > today;
}