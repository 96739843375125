import React from 'react'
import packageJson from '../../package.json'

const ServiceInfoScroll = () => {
    const IS_ON = packageJson.beta
    const SERVICE_MSG = `Aceasta este o versiune beta.`

    if (!IS_ON) return null

    return (
        <section id='scroll-container'>
            <div id='scroll-text'>{SERVICE_MSG}</div>
        </section>
    )
}

export default ServiceInfoScroll