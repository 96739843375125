///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

export const DeliveryConditions = [
    {
        code: 'EXW',
        value: 'franco fabrica'
    },
    {
        code: 'FCA',
        value: 'franco transportator'
    },
    {
        code: 'FAS',
        value: 'franco de-a lungul vasului'
    },
    {
        code: 'FOB',
        value: 'franco la bord'
    },
    {
        code: 'CFR',
        value: 'cost si navlu'
    },
    {
        code: 'CIF',
        value: 'cost, asigurare si navlu'
    },
    {
        code: 'CPT',
        value: 'transport platit pana la'
    },
    {
        code: 'CIP',
        value: 'transport si asigurare platite pana la'
    },
    {
        code: 'DAP',
        value: 'livrat la locul convenit'
    },
    {
        code: 'DPU',
        value: 'livrat la locul de descarcare'
    },
    {
        code: 'DDP',
        value: 'livrat cu taxele vamale platite'
    },
    {
        code: 'XXX',
        value: 'alti termeni de livrare decat cei mai sus mentionati'
    },
]