///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useAuth } from '../context/AuthContext';
import graphLogoImg from '../images/pie-logo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faMoon, faSun, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip';
import ConfirmBox from './ConfirmBox';
import { subscriptionNames } from '../services/SubscriptionsService';

const Header = () => {
    const [type, setType] = useState('')
    const [isOpen, setIsOpen] = useState(false);
    const [isDark, setIsDark] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false)

    const { logout, currentUser, userData } = useAuth()
    
    useEffect(() => {
        let currentTheme = localStorage.getItem('display-theme')
        if(currentTheme === null) {
            localStorage.setItem('display-theme', 'dark')
            currentTheme = 'dark'
            setIsDark(true)
        }

        if(currentTheme === 'light') {
            document.documentElement.setAttribute('display-theme', 'light');
            localStorage.setItem('display-theme', 'light')
            setIsDark(false)
        }
        else if(currentTheme === 'dark') {
            document.documentElement.setAttribute('display-theme', 'dark');
            localStorage.setItem('display-theme', 'dark')
            setIsDark(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!userData || !userData.subscriptions || userData.subscriptions.length === 0) return
        const uSub = userData.subscriptions[0]
        if (Object.keys(uSub).length === 0) return
        if (!uSub.valid || uSub.manualDisable) return 
        setType(subscriptionNames(uSub.name))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData.subscriptions])

    const switchTheme = () => {
        if(isDark) {
            document.documentElement.setAttribute('display-theme', 'light');
            localStorage.setItem('display-theme', 'light')
        }
        else {
            document.documentElement.setAttribute('display-theme', 'dark');
            localStorage.setItem('display-theme', 'dark') 
        }
        setIsDark(!isDark)
    }
    
    const navigate = useNavigate();

    const goHomeToSection = async (section) => {
        navigate('/')
        await new Promise(r => setTimeout(r, 200));
        scrollToId(section)
    }


    const scrollToId = (idName) => {
        const elem = document.getElementById(idName);
        if(elem === null) return
        elem.scrollIntoView({behavior: 'smooth'}, true);
    }

    const switchPage = (link) => {
        if(currentUser) {
            switch(link) {
                case '/':
                    navigate(link)
                    break;
                case 'sign-in':
                    break;
                default:
                    break;
            }
        }
        else navigate(link)
    }

    // when logged in

    const handleLogOut = () => {
        setOpenConfirm(false)
        logout()
    }
    

    return (
    <>
    <FontAwesomeIcon icon={faBars} size="3x" className='menu-button' onClick={() => setIsOpen(!isOpen)}/>
    <div className='header-wrapper' style={{zIndex:isOpen && 2 }}>
        <div className={`header${ isOpen ? ' open' : '' }`}>
            <div className='logo-image' onClick={() => switchPage('/')}>
                <img src={graphLogoImg} placeholder='logo-img' alt='logo-img' />
                <div>programintrastat.ro</div>
                {type !== '' && <div id='type'>{type}</div>}
            </div>
            <div className='right-side'>
                {!currentUser && 
                <>
                <div onClick={() => switchPage('/')} className='link'>Meniu</div>
                <div className='link' onClick={() => goHomeToSection('pricing')}>Tarife</div>
                <div onClick={() => switchPage('/sign-in')} className='link account'>CONT</div>
                </>}

                {currentUser && 
                    <div className='user-top-info' onClick={() => switchPage('/')}>
                        <div className='top-user-name'>{currentUser.displayName}</div>
                        <div className='top-user-email' style={{userSelect:'all'}}>{currentUser.email}</div>
                    </div>
                }

                <div data-tip data-for="theme-hover">
                    <FontAwesomeIcon onClick={switchTheme} className='themeButton' icon={isDark ? faSun :faMoon} />    
                </div>
                <div data-tip data-for="logout-hover">
                    {currentUser && <FontAwesomeIcon onClick={() => setOpenConfirm(true)} className='log-out' icon={faRightFromBracket} />}
                </div>
            </div>
        </div>
    </div>
    <div className='after-header'></div>
    <ReactTooltip effect="solid" id='theme-hover' type={isDark ? 'light' : 'dark'}>Schimbă tema</ReactTooltip>
    <ReactTooltip effect="solid" id='logout-hover' type={isDark ? 'light' : 'dark'}>Ieșire din cont</ReactTooltip>
    {openConfirm &&
    <ConfirmBox 
        onConfirm = {() => handleLogOut()}
        onClose = {() => setOpenConfirm(false)}
        content = 'Sigur doriți să ieșiți din cont?'
        title = 'Confirmați delogarea'
    />
    }
    </>
    )
}

export default Header