import React, { useEffect, useState } from 'react'
import graphLogoImg from '../images/pie-logo.png'

const WelcomeScreen = () => {
    const [timer, setTimer] = useState(4)

    useEffect(() => {
        const timeout =
            timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
        return () => clearInterval(timeout);
    }, [timer]);

    if (timer > 0) {
        return (
            <div className='splash-wrapper'>
                <div className='splash-bg'>
                    <div className='splash-logo'>
                        <img src={graphLogoImg} placeholder='logo-img' alt='logo-img' />
                        <div>programintrastat.ro</div>
                    </div>
                </div>
            </div>
        )
    }
    else return <></>
}

export default WelcomeScreen