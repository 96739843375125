///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, November 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

const FormatLocation = {
    Ro: 'ro-RO',
    Us: 'en-US',
    Gb: 'en-GB',
    De: 'de-DE',
    Fr: 'fr-FR'
}

export const fixNrSize = (number) => parseFloat(number) % 1 !== 0 ? parseFloat(number).toFixed(2) : parseFloat(number)

export const formatNr = (number) => new Intl.NumberFormat(FormatLocation.Fr).format(+fixNrSize(+number))