///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, { useState, useEffect } from 'react'
import { countryCodes, countryNames, EuCountries } from '../../databases/CountryCodes';
import { DeliveryConditions } from '../../databases/DeliveryCondition';
import { TransactionNatures } from '../../databases/TransactionNature';
import { TransportModes } from '../../databases/TransportMode';

import { getHeaders, getHeaderIndex } from '../../services/ExcelService';
import { createNotification } from '../../services/NotificationService';
import { checkValueCodVamal } from '../../services/CodVamalService';
import { validateVATEu } from '../../services/ValidationService';

const LayoutForm = ({fileData, currentIndex, save, toUpdate, updateData, type, discard}) => {

    const [headers, setHeaders] = useState([])
    const [req, setReq] = useState(false)
    const [formData, setFormData] = useState({
        codVamal: '',
        codVamalImpl: '',
        valoareFiscala: '',
        valoareFiscalaImpl: 0,
        valoareStatistica: '',
        valoareStatisticaImpl: 0,
        taraOrigine: '',
        taraOrigineImpl: `${countryCodes[0]}`,
        taraExpediere: '',
        taraExpediereImpl: `${EuCountries.Item[0].Code}`,
        greutateNeta: '',
        greutateNetaImpl: 0,
        naturaTranzactiei: '',
        naturaTranzactieiImpl: `${TransactionNatures[0].categ}.${TransactionNatures[0].code}`,
        conditieLivrare: '',
        conditieLivrareImpl: `${DeliveryConditions[0].code}`,
        modTransport: '',
        modTransportImpl: `${TransportModes[2].code}`,
        // req achizitii
        vat: '',
        vatImpl: '',
        // req livrari
        dataFactura: '',
        dataFacturaImpl: '',
        um: '',
        umImpl: 'BUC',
        cantitate: '',
        cantitateImpl: 0,
        cursDeSchimb: '',
        cursDeSchimbImpl: 1,
        moneda: '',
        monedaImpl: 'RON',
        valoareValuta: '',
        valoareValutaImpl: 0,
        dataTranzactiei: '',
        dataTranzactieiImpl: '',
    })

    useEffect(() => {
        if(toUpdate) {
            setHeaders(updateData.headers);
            setFormData(updateData.data);
        }
        else {
            setHeaders(getHeaders(fileData.values[currentIndex], true))
            // checkHeaders()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileData, currentIndex, toUpdate])

    // useEffect(() => {
    //     // !toUpdate && checkHeaders()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [headers])

    useEffect(() => {
        if (type === 'livrari') {
            setFormData(prev => ({...prev}))
            setReq(true)
        }
        else {
            setFormData(prev => ({...prev}))
            setReq(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])

    // Check if there are headers with similar name and assign the values
    // const checkHeaders = async () => {
    //     await new Promise(r => setTimeout(r, 500));
    //     const obj = {}
    //     for (const [key] of Object.entries(formData)) {
    //         for(const element of headers) {
    //             if(element.replace(/\s/g, '').toUpperCase().includes(key.replace(/\s/g, '').toUpperCase())) {
    //                 obj[key] = getHeaderIndex(element)
    //             }
    //         }
    //     }
    //     setFormData(prev => ({...prev, ...obj}))
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
        save(formData, headers)
    }

    // Assign values to formData and do not allow same value twice
    const setUniqueFormData = (keyToUpdate, newValue) => {
        let isUnique = true;
        // Loop through formData to check for column to be unique. DO not check with implicit values
        for (const key in formData) {
            if (keyToUpdate.includes("Impl")) break;
            if (key.includes("Impl")) continue;
            if (newValue !== "false" && formData[key] === newValue && key !== keyToUpdate) {
                isUnique = false;
                break;
            }
        }

        if (isUnique) {
            if (!toUpdate && !keyToUpdate.includes("Impl") && newValue !== "false") {
                // Check validity of selected column cy verifying against the first value in the column
                const firstRowValues = fileData.values[0][0];
                if (firstRowValues) {
                    const rowKeys = Object.keys(firstRowValues);
                    const valueToCheck = firstRowValues[rowKeys[+newValue]];
                    // Start the check
                    let mightBrWrong = false;
                    switch (keyToUpdate) {
                        case 'codVamal':
                            mightBrWrong = !checkValueCodVamal(valueToCheck);
                            break;
                        case 'valoareFiscala':
                        case 'valoareStatistica':
                        case 'greutateNeta':
                            mightBrWrong = !Number.isSafeInteger(Math.floor(+valueToCheck))
                            break;
                        case 'taraOrigine':
                        case 'taraExpediere':
                            mightBrWrong = !new Set(countryCodes).has(valueToCheck);
                            break;
                        case 'vat':
                            mightBrWrong = !validateVATEu(valueToCheck.toString()) || checkValueCodVamal(valueToCheck); // Throws false positive if COD VAMAL is checked, so we check not to be one
                            break;
                        default:
                            break;
                    }
                    mightBrWrong && createNotification('warning', 'Este posibil ca coloana selectată să nu conțină valori valide, judecând după prima valoare din aceasta ('+valueToCheck+')', "Asigurați-vă", 5000);
                }
            }
            setFormData(prev => ({...prev, [keyToUpdate]: newValue}));
        }
        else createNotification('error', 'Nu puteți asigna aceeași coloană de mai multe ori.');
    }

    return (
        <form className='login-form' onSubmit={e => handleSubmit(e)}>
            <h2>Locație valori în fișierul Excel</h2>

            <div className='tripple-form-input req'>
                <div>Cod vamal (HS):</div>
                <select onChange={(e) => setUniqueFormData("codVamal", e.target.value)}
                    value={formData.codVamal}
                    name='codVamal' id='codVamal' className='input-text' required>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <input type='text' value={formData.codVamalImpl} onChange={(e) => setUniqueFormData("codVamalImpl", e.target.value)}
                className='input-text' title='codVamalImpl' id='codVamalImpl' />
            </div>

            <div className='tripple-form-input req'>
                <div>Valoare fiscală:</div>
                <select onChange={(e) => setUniqueFormData("valoareFiscala", e.target.value)}
                    value={formData.valoareFiscala}
                    name='valoareFiscala' id='valoareFiscala' className='input-text' required>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <input type='number' value={formData.valoareFiscalaImpl} onChange={(e) => setUniqueFormData("valoareFiscalaImpl", e.target.value)}
                className='input-text' title='valoareFiscalaImpl' 
                id='valoareFiscalaImpl' />
            </div>

            <div className='tripple-form-input req'>
                <div>Valoare statistica:</div>
                <select onChange={(e) => setUniqueFormData("valoareStatistica", e.target.value)}
                    value={formData.valoareStatistica}
                    name='valoareStatistica' id='valoareStatistica' className='input-text' required>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <input type='number' value={formData.valoareStatisticaImpl} onChange={(e) => setUniqueFormData("valoareStatisticaImpl", e.target.value)}
                className='input-text' title='valoareStatisticaImpl' 
                id='valoareStatisticaImpl' />
            </div>

            <div className='tripple-form-input req'>
                <div>Țară origine:</div>
                <select onChange={(e) => setUniqueFormData("taraOrigine", e.target.value)}
                    value={formData.taraOrigine}
                    name='taraOrigine' id='taraOrigine' className='input-text' required>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <select onChange={(e) => setUniqueFormData("taraOrigineImpl", e.target.value)}
                    value={formData.taraOrigineImpl}
                    name='taraOrigineImpl' id='taraOrigineImpl' className='input-text'>
                        <option value={false}>Nici o valoare</option>
                        {countryCodes.map((code, index) => {
                            return(
                                <option key={`${code} - ${index}`} 
                                value={`${code} - ${countryNames[index]}`}>
                                    {`${code} - ${countryNames[index]}`}
                                </option>
                            )
                        })}
                </select>
            </div>

            <div className='tripple-form-input req'>
                <div>Țară expediere:</div>
                <select onChange={(e) => setUniqueFormData("taraExpediere", e.target.value)}
                    value={formData.taraExpediere}
                    name='taraExpediere' id='taraExpediere' className='input-text' required>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <select onChange={(e) => setUniqueFormData("taraExpediereImpl", e.target.value)}
                    value={formData.taraExpediereImpl}
                    name='taraExpediereImpl' id='taraExpediereImpl' className='input-text'>
                        <option value={false}>Nici o valoare</option>
                        {EuCountries.Item.map((itm, index) => {
                            return(
                                <option key={`${itm.Code} - ${index}`} 
                                value={`${itm.Code} - ${itm.NameRo}`}>
                                    {`${itm.Code} - ${itm.NameRo}`}
                                </option>
                            )
                        })}
                </select>
            </div>

            <div className='tripple-form-input req'>
                <div>Greutate netă:</div>
                <select onChange={(e) => setUniqueFormData("greutateNeta", e.target.value)}
                    value={formData.greutateNeta}
                    name='greutateNeta' id='greutateNeta' className='input-text' required>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <input type='number' value={formData.greutateNetaImpl} onChange={(e) => setUniqueFormData("greutateNetaImpl", e.target.value)}
                className='input-text' title='greutateNetaImpl' id='greutateNetaImpl' />
            </div>

            <div className='tripple-form-input req'>
                <div>Natura tranzacției:</div>
                <select onChange={(e) => setUniqueFormData("naturaTranzactiei", e.target.value)}
                    value={formData.naturaTranzactiei}
                    name='naturaTranzactiei' id='naturaTranzactiei' className='input-text' required>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <select onChange={(e) => setUniqueFormData("naturaTranzactieiImpl", e.target.value)}
                    value={formData.naturaTranzactieiImpl}
                    name='naturaTranzactieiImpl' id='naturaTranzactieiImpl' className='input-text'>
                        <option value={false}>Nici o valoare</option>
                        {TransactionNatures.map((item, index) => {
                            return(
                                <option key={`${item.value} - ${index}`} 
                                value={`${item.categ}.${item.code}`}>
                                    {`${item.categ}.${item.code} - ${item.value}`}
                                </option>
                            )
                        })}
                </select>
            </div>

            <div className='tripple-form-input req'>
                <div>Condiție livrare:</div>
                <select onChange={(e) => setUniqueFormData("conditieLivrare", e.target.value)}
                    value={formData.conditieLivrare}
                    name='conditieLivrare' id='conditieLivrare' className='input-text' required>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <select onChange={(e) => setUniqueFormData("conditieLivrareImpl", e.target.value)}
                    value={formData.conditieLivrareImpl}
                    name='conditieLivrareImpl' id='conditieLivrareImpl' className='input-text'>
                        <option value={false}>Nici o valoare</option>
                        {DeliveryConditions.map((item, index) => {
                            return(
                                <option key={`${item.code} - ${index}`} 
                                value={`${item.code}`}>
                                    {`${item.code} - ${item.value}`}
                                </option>
                            )
                        })}
                </select>
            </div>

            <div className='tripple-form-input req'>
                <div>Mod transport:</div>
                <select onChange={(e) => setUniqueFormData("modTransport", e.target.value)}
                    value={formData.modTransport}
                    name='modTransport' id='modTransport' className='input-text' required>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <select onChange={(e) => setUniqueFormData("modTransportImpl", e.target.value)}
                    value={formData.modTransportImpl}
                    name='modTransportImpl' id='modTransportImpl' className='input-text'>
                        <option value={false}>Nici o valoare</option>
                        {TransportModes.map((item, index) => {
                            return(
                                <option key={`${item.code} - ${index}`} 
                                value={`${item.code}`}>
                                    {`${item.code} - ${item.value}`}
                                </option>
                            )
                        })}
                </select>
            </div>

            <div className={`tripple-form-input${req ? ' req' : ''}`}>
                <div>VAT (CIF):</div>
                <select onChange={(e) => setUniqueFormData("vat", e.target.value)}
                    value={formData.vat} required={req}
                    name='vat' id='vat' className='input-text'>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <input type='text' value={formData.vatImpl} onChange={(e) => setUniqueFormData("vatImpl", e.target.value)}
                className='input-text' title='vatImpl' id='vatImpl'/>
            </div>

            <div className='tripple-form-input'>
                <div>Dată factură:</div>
                <select onChange={(e) => setUniqueFormData("dataFactura", e.target.value)}
                    value={formData.dataFactura}
                    name='dataFactura' id='dataFactura' className='input-text'>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <input type='text' value={formData.dataFacturaImpl} onChange={(e) => setUniqueFormData("dataFacturaImpl", e.target.value)}
                className='input-text' title='dataFacturaImpl' id='dataFacturaImpl' placeholder='zz/ll/aaaa' />
            </div>

            <div className='tripple-form-input'>
                <div>UM:</div>
                <select onChange={(e) => setUniqueFormData("um", e.target.value)}
                    value={formData.um}
                    name='um' id='um' className='input-text'>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <input type='text' value={formData.umImpl} onChange={(e) => setUniqueFormData("umImpl", e.target.value)}
                className='input-text' title='umImpl' id='umImpl' />
            </div>

            <div className='tripple-form-input'>
                <div>Cantitate:</div>
                <select onChange={(e) => setUniqueFormData("cantitate", e.target.value)}
                    value={formData.cantitate}
                    name='cantitate' id='cantitate' className='input-text'>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <input type='number' value={formData.cantitateImpl} onChange={(e) => setUniqueFormData("cantitateImpl", e.target.value)}
                className='input-text' title='cantitateImpl' id='cantitateImpl'/>
            </div>

            <div className='tripple-form-input'>
                <div>Curs de schimb:</div>
                <select onChange={(e) => setUniqueFormData("cursDeSchimb", e.target.value)}
                    value={formData.cursDeSchimb}
                    name='cursDeSchimb' id='cursDeSchimb' className='input-text'>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <input type='number' value={formData.cursDeSchimbImpl} onChange={(e) => setUniqueFormData("cursDeSchimbImpl", e.target.value)}
                className='input-text' title='cursDeSchimbImpl' id='cursDeSchimbImpl' step="0.0001"/>
            </div>

            <div className='tripple-form-input'>
                <div>Moneda:</div>
                <select onChange={(e) => setUniqueFormData("moneda", e.target.value)}
                    value={formData.moneda}
                    name='moneda' id='moneda' className='input-text'>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <input type='text' value={formData.monedaImpl} onChange={(e) => setUniqueFormData("monedaImpl", e.target.value)}
                className='input-text' title='monedaImpl' id='monedaImpl'/>
            </div>

            <div className='tripple-form-input'>
                <div>Valoare valută:</div>
                <select onChange={(e) => setUniqueFormData("valoareValuta", e.target.value)}
                    value={formData.valoareValuta}
                    name='valoareValuta' id='valoareValuta' className='input-text'>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <input type='number' value={formData.valoareValutaImpl} onChange={(e) => setUniqueFormData("valoareValutaImpl", e.target.value)}
                className='input-text' title='valoareValutaImpl' id='valoareValutaImpl'/>
            </div>

            <div className='tripple-form-input'>
                <div>Data tranzacției:</div>
                <select onChange={(e) => setUniqueFormData("dataTranzactiei", e.target.value)}
                    value={formData.dataTranzactiei}
                    name='dataTranzactiei' id='dataTranzactiei' className='input-text'>
                        <option value={false}>Nici o coloană</option>
                        {headers && headers.length > 0 && headers.map((name, index) => {
                            return(
                                <option key={`${name} - ${index}`} value={getHeaderIndex(name)}>
                                    {name}
                                </option>
                            )
                        })}
                </select>
                <small>Val. implicită:</small>
                <input type='text' value={formData.dataTranzactieiImpl} onChange={(e) => setUniqueFormData("dataTranzactieiImpl", e.target.value)}
                className='input-text' title='dataTranzactieiImpl' id='dataTranzactieiImpl' placeholder='zz/ll/aaaa' />
            </div>

            <input type='submit' className='button-secondary btn-green' value='Salvează' />
            <div onClick={() => discard()} className='button-secondary'>Anulează</div>
        </form>
    )
}

export default LayoutForm