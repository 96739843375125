///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

export const allowedIPS = new Set([
    // DE #1
    '156.67.131.117',
    // DE #2
    '156.67.130.184',
    // DE #3
    '156.67.128.188',
    // RO #1 - m&t
    '86.123.79.8',
    // RO #2 - 9m
    '86.122.16.164',
])