import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { downloadXmlFomString } from '../../services/XMLService';
import { toFormatTime } from '../../services/DateService';

const GeneratedHistory = () => {

    const [statsOpen, setStatsOpen] = useState(false);
    const [stats, setStats] = useState();

    const {
        currentUser,
        userData,
        loadingAction,
        fetchGeneratedHistory,
        generatedHistory
    } = useAuth();

    useEffect(() => {
        fetchGeneratedHistory(currentUser.uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setStats({
            totalLines: generatedHistory.reduce((acc, curr) => acc + curr.lines, 0),
            totalTert: generatedHistory.reduce((acc, curr) => acc + (curr.tertCompany ? 1 : 0), 0),
            totalAchizitii: generatedHistory.reduce((acc, curr) => acc + (curr.type === "achizitii" ? 1 : 0), 0),
            totalLivrari: generatedHistory.reduce((acc, curr) => acc + (curr.type === "livrari" ? 1 : 0), 0),
            totalValStat: generatedHistory.reduce((acc, curr) => acc + (curr.valStat ? 1 : 0), 0),
            totalReviz: generatedHistory.reduce((acc, curr) => acc + (curr.reviz ? 1 : 0), 0),
            medLines: Math.ceil(generatedHistory.reduce((acc, curr) => acc + curr.lines, 0) / generatedHistory.length),
        })
    }, [generatedHistory])

    // Admin function to load all history
    const loadAllHistory = () => {
        const userInput = window.prompt('Nr of entries to load (default: 10):');
        if (userInput === null) return; // User hit "Cancel"
        let nrToLoad = 10;
        if (userInput !== null && userInput !== "") {
            nrToLoad = +userInput;
        }
        fetchGeneratedHistory("all", nrToLoad);
    }
    
    return (
        loadingAction ? 
        <FontAwesomeIcon className='spinner' icon={faSpinner}/> : 
        generatedHistory.length === 0 ? 
        <div>Nu eveți declarații generate în istoric</div> :
        <>
            <div>
            <h2>Istoric declarații generate</h2>
            <small>Ultimele 10 declarații generate în format XML</small>
            {generatedHistory.length > 0 && <div className='button-secondary' style={{width:"min-content"}} onClick={() => setStatsOpen(!statsOpen)}>Statistici</div>}
            </div>

            {
                statsOpen && generatedHistory.length > 0 && stats &&
                <div className='stats-view'>
                    <h3>Statistici ultimele 10 declarații</h3>
                    <small>Total linii: {stats.totalLines} (în medie {stats.medLines} linii / declarație)</small>
                    <small>Declarații achiziții: {stats.totalAchizitii}</small>
                    <small>Declarații livrări: {stats.totalLivrari}</small>
                    <small>Declarații cu valoare statistică: {stats.totalValStat}</small>
                    <small>Declarații revizuite: {stats.totalReviz}</small>
                    <small>Declarații terțe: {stats.totalTert}</small>
                </div>
            }
            {userData.data && userData.data.admin && (+userData.data.accessLevel > 9) && 
                <div className='button-secondary' style={{width:"fit-content"}} onClick={() => loadAllHistory()}>Istoric declarații platformă</div>
            }

            <div className='simple-row-data-view'>
                {
                    generatedHistory.length > 0 && generatedHistory.map((generated, id) => {
                        return(
                            <div key={id + generated.fileName} className='simple-data-row'>
                                <div className='simple-data-row-left'>
                                    <div><b>{generated.generatedFileName ? generated.generatedFileName : generated.fileName}</b></div>
                                    <div>{generated.date} {toFormatTime(generated.timeStamp.toDate())} - {generated.company}</div>
                                    <div>{generated.lines} linii</div>
                                    <div>
                                        {generated.tertCompany && <span>terță; </span>}
                                        {generated.reviz && <span>revizuită; </span>}
                                        {generated.hasErrors && <span>cu erori;</span>}
                                    </div>
                                    {userData.data && userData.data.admin && (+userData.data.accessLevel > 9) && 
                                        <small>{generated.uid} - {generated.name}</small>
                                    }
                                </div>
                                <div className='simple-data-row-right'>
                                    {
                                    !generated.generatedXML ?
                                    <span>Descărcare indisponibilă</span> :
                                    <>
                                    <div className='button-secondary selected-btn' 
                                    onClick={() => downloadXmlFomString(generated.generatedXML, generated.generatedFileName ? generated.generatedFileName : generated.fileName)}>
                                        Descarcă
                                    </div>
                                    {/* <div className='button-secondary'>Vizualizează</div> */}
                                    </>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default GeneratedHistory