///---------------------------------------------------------------------------
//! Copyright (C) INTRASON SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

const MONTHS = [
    'Ianuarie', 
    'Februarie',
    'Martie',
    'Aprilie',
    'Mai',
    'Iunie',
    'Iulie',
    'August',
    'Septembrie',
    'Octombrie',
    'Noiembrie',
    'Decembrie'
]

export const getMonthName = (nr) => {
    if(nr > 0 && nr < 13)
        return MONTHS[nr - 1]
    else
        return 'invalid'
}

export const getCurrentYear = () => {
    const d = new Date()
    return d.getFullYear()
}

export const getCurrentMonth = () => {
    const d = new Date()
    return d.getMonth() + 1
}

// dif = -1 or 1
// returns object 
export const getOneMonthDif = (dif) => {
    const d = new Date()
    if(d.getMonth() + 1 === 1 && dif === -1) {
        return { year: d.getFullYear() - 1, month: 12 }
    }
    else if(d.getMonth() + 1 === 12 && dif === 1) {
        return { year: d.getFullYear() + 1, month: 1 }
    }
    else {
        return { year: d.getFullYear(), month: (d.getMonth() + 1) + dif }
    }
}

export const excelFormatToDate = (serial) => {
    if(serial === '' || !serial) return 0
    const utc_days  = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;                                        
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;

    total_seconds -= seconds;

    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}

export const toFormatDate = (date) => {
    if(date === '' || !date) return
    const day = date.getMonth()+1 > 9 ? date.getMonth()+1 : `0${date.getMonth()+1}`
    return `${date.getDate()}/${day}/${date.getFullYear()}`
}

export const toFormatTime = (date) => {
    if (!date || date === '') return '';
  
    const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
    const minutes = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
    const seconds = date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`;
  
    return `${hours}:${minutes}:${seconds}`;
};

export const checkIfDate = (date) => {
    if(date === '' || !date) return
    if(typeof date.getMonth === 'function') return true
    else return false
}

export const arrayFromDateString = (date) => {
    if (date === '' || !date) return []
    if (!date.includes('/')) return []
    return date.split('/')
}

export const arrayFromDateDB = (date) => {
    if (date === '' || !date) return []
    if (!date.includes('-')) return []
    const arr = date.split('-')
    const toReturn = arr.map(val => parseInt(val).toString())
    return toReturn
}

export const getXMLTimestamp = () => {
    const x = new Date()
    const goodNr = (nr) => {
        return parseInt(nr) < 10 ? `0${nr}` : `${nr}`
    }
    const timeZ = (x.getTimezoneOffset() / 60) < 0 ? `+${goodNr((x.getTimezoneOffset() / 60)*(-1))}` :
    `-${goodNr((x.getTimezoneOffset() / 60))}`

    const m = (x.getMonth() + 1) < 10 ? `0${(x.getMonth() + 1)}` : (x.getMonth() + 1)
    const final = x.getFullYear() + "-" + 
        m + "-" + goodNr(x.getDate()) + "T" + 
        goodNr(x.getHours()) + ":" + goodNr(x.getMinutes()) + ":" + 
        goodNr(x.getSeconds()) + "." + goodNr(x.getMilliseconds()) + timeZ + ':00';
    return final
}

// from DB timestamp
export const getDateFromTimestamp = (timestamp) => {
    if (!timestamp || timestamp === '') return ''
    if (timestamp instanceof Date) return timestamp
    return new Date(`${timestamp.toDate().toDateString()} ${timestamp.toDate().toLocaleTimeString()}`)
}